import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from 'services/DashboardService'

class MyAccountDashboardStore {
  public options: { value: number; label: string }[]
  public loading = true
  public days: { value: number; label: string }
  public earnings: { [currency: string]: number }
  public numberOfSales: number
  public dashboardService: DashboardService
  public authStore: AuthStore
  public error: any

  constructor(options: { value: number; label: string }[], authStore: AuthStore) {
    this.options = options
    this.loading = true
    this.days = options[2]
    makeAutoObservable(this)
    this.dashboardService = new DashboardService()
    this.authStore = authStore
  }

  async getPhotographerInsights(days: { value: number }) {
    this.loading = true
    try {
      const data = await this.dashboardService.getPhotographerInsights(
        days.value,
        this.authStore.getToken(),
        this.authStore?.getLoggedUser().id
      )
      runInAction(() => {
        this.earnings = data.earningsByCurrency
        this.numberOfSales = data.salesCount
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
      })
      throw error
    }
  }

  async setDays(days: { value: number; label: string }) {
    this.days = days
  }
}

export default MyAccountDashboardStore
