import axios from './axios'
import * as paths from './Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import UpdateUserInterface from './Interfaces/User/UpdateUser.interface'
import { User } from 'shared/models/User'
import UploadProfilePictureResponse from './Interfaces/User/UploadProfilePictureResponse'
import UploadProfilePictureRequest from './Interfaces/User/UploadProfilePictureRequest'
import FileService from './FileService'
import { isNil } from 'lodash'

class UserService {
  private fileService: FileService
  constructor() {
    this.fileService = new FileService()
  }

  fetchUsers(token: string, page: number) {
    return axios
      .get(`${paths.USERS}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          users: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchLikedAlbums(userId: string, token: string) {
    return axios
      .get(paths.userLikedAlbumsById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => data)
      .catch(parseAxiosErrorResponse)
  }

  fetchPhotographersUsers(token: string) {
    return axios
      .get(paths.PHOTOGRAPHERS_USERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  updateUser(token: string, id: string, data: UpdateUserInterface): Promise<User | undefined> {
    return axios
      .put(paths.updateUserById(id), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return User.init(response.data)
      })
      .catch(parseAxiosErrorResponse)
  }

  suspendUser(token: string, userId: string) {
    return axios
      .post(paths.suspendUserById(userId), userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activateUser(token: string, userId: string) {
    return axios
      .post(paths.activateUserById(userId), userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteUser(token: string, userId: string) {
    return axios
      .delete(paths.deleteUserById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  fetchStarredAlbums(userId: string, token: string) {
    return axios
      .get(paths.userStarredAlbumsById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  async createProfilePicture(
    type: string,
    userId: string,
    token: string
  ): Promise<UploadProfilePictureResponse> {
    const photographPayload: UploadProfilePictureRequest = {
      type,
    }
    return axios
      .post(paths.createProfilePicture(userId), photographPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async createPermanentRecognitionPicture(
    type: string,
    userId: string,
    token: string
  ): Promise<UploadProfilePictureResponse> {
    const photographPayload: UploadProfilePictureRequest = {
      type,
    }
    return axios
      .post(paths.createPermanentRecognitionPicture(userId), photographPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async createTemporaryRecognitionPicture(
    type: string,
    randomUuid: string | null
  ): Promise<UploadProfilePictureResponse> {
    const uuid = !isNil(randomUuid) ? randomUuid : undefined
    const photographPayload: UploadProfilePictureRequest = {
      type,
      randomUuid: uuid,
    }
    return axios
      .post(paths.createTemporaryRecognitionPicture, photographPayload)
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default UserService
