import React, { memo, useEffect } from 'react'
import crossIcon from '../../../assets/icons/v2/crossIcon.svg'
import ReactModal from 'react-modal'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

ReactModal.setAppElement('#root')

export enum ModalSize {
  SMALL,
  MEDIUM_SMALL,
  MEDIUM,
  LARGE,
}

const baseClasses = [
  'm-auto',
  'modal-base',
  'outline-none',
  'bg-white',
  'rounded-lg',
  'shadow-xl',
  'w-full',
  'border-2',
  'border-lumepic-grey',
]

const ModalClasses = new Map<ModalSize, string[]>()
ModalClasses.set(ModalSize.SMALL, ['sm:max-w-sm sm:max-h-sm'])
ModalClasses.set(ModalSize.MEDIUM_SMALL, ['sm:max-w-2xl sm:max-h-xl'])
ModalClasses.set(ModalSize.MEDIUM, ['sm:max-w-6xl sm:max-h-xl'])
ModalClasses.set(ModalSize.LARGE, ['sm:w-11/12', 'h-600'])

export enum ModalButtonsDisplay {
  FULL,
  ONLY_OK_BUTTON,
  NO_BUTTONS,
}

type ModalProps = {
  opened: boolean
  children: React.ReactNode
  buttonsDisplay?: ModalButtonsDisplay
  modalSize?: ModalSize
  onCancel: () => void
  onSuccess?: () => void
  okMessage?: string | null
  cancelMessage?: string
  autoClose?: boolean
  displayCloseIcon?: boolean
  zIndex?: number
  isDeleteConfirmation?: boolean
  loadingOk?: boolean
  redCancel?: boolean
  noOverlay?: boolean
}

export const Modal = ({
  opened,
  children,
  buttonsDisplay = ModalButtonsDisplay.ONLY_OK_BUTTON,
  modalSize = ModalSize.MEDIUM,
  onSuccess = Function,
  onCancel = Function,
  autoClose = true,
  okMessage = 'Ok',
  cancelMessage,
  displayCloseIcon = true,
  zIndex = 100,
  isDeleteConfirmation = false,
  loadingOk = false,
}: ModalProps) => {
  const { t } = useTranslation()
  const okClick = () => {
    if (onSuccess) {
      onSuccess()
    }
  }

  const cancelClick = () => {
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    //When modal is opened hide WhatsappButton, else, show WhatsappButton
    const whatsappButtonElement = document.getElementById('WhatsappButton')
    if (opened && !isNil(whatsappButtonElement)) {
      whatsappButtonElement.hidden = true
    } else if (!opened && !isNil(whatsappButtonElement)) {
      whatsappButtonElement.hidden = false
    }
  }, [])

  let modalActions

  if (buttonsDisplay !== ModalButtonsDisplay.NO_BUTTONS) {
    if (buttonsDisplay === ModalButtonsDisplay.FULL) {
      modalActions = (
        <div className="flex flex-row justify-between p-4">
          <Button btnType="Warning" onClick={cancelClick}>
            {cancelMessage ? cancelMessage : t('Cancel')}
          </Button>
          <Button
            btnType={isDeleteConfirmation ? 'Warning' : 'PrimaryAction'}
            isLoading={loadingOk}
            onClick={okClick}
          >
            {okMessage}
          </Button>
        </div>
      )
    }
    if (buttonsDisplay === ModalButtonsDisplay.ONLY_OK_BUTTON) {
      modalActions = (
        <div className="flex justify-end pb-2 px-2">
          <Button
            btnType={isDeleteConfirmation ? 'Warning' : 'PrimaryAction'}
            isLoading={loadingOk}
            onClick={okClick}
          >
            {okMessage}
          </Button>
        </div>
      )
    }
  }

  const modalStyles = [...baseClasses, ...ModalClasses.get(modalSize)!].join(' ')
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      style={{
        content: {
          zIndex,
          overflowY: 'auto',
          maxHeight: '90vh',
        },
        overlay: {
          zIndex: zIndex - 1,
          backgroundColor: 'rgba(99, 99, 99, 0.30)',
          backdropFilter: 'blur(1px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      className={modalStyles}
      onRequestClose={() => {
        if (autoClose) {
          cancelClick()
        }
      }}
      closeTimeoutMS={300}
      isOpen={opened}
    >
      <div className="w-full h-full flex flex-col align-between">
        {displayCloseIcon && cancelClick && (
          <div className="w-full flex justify-end pt-1.5 pr-1.5" onClick={cancelClick}>
            <img src={crossIcon} className="w-5 h-5 text-gray-800 cursor-pointer" />
          </div>
        )}
        <div id="modal-content" className="w-full overflow-y-auto">
          {children}
        </div>
        {buttonsDisplay !== ModalButtonsDisplay.NO_BUTTONS && (
          <div className="relative bottom-0 left-0 w-full">{modalActions}</div>
        )}
      </div>
    </ReactModal>
  )
}

export default memo(Modal)
