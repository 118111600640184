import React from 'react'
import classes from './Footer.module.scss'
import lumepicLogo from '../../assets/icons/v2/lumepic-logo.svg'
import logoAnde from '../../assets/images/Ande.svg'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import { CONTACT_US, FAQ, PRIVACY_POLICIES, TERMS_AND_CONDITIONS } from 'routing/Paths'

const footer = () => {
  const { t } = useTranslation()

  const getCurrentYear = () => {
    const currentDate = new Date()
    return currentDate.getFullYear()
  }

  return (
    <footer>
      <div className="container mx-auto sm:px-8">
        <div className={classes.footerTopColumn}>
          <div className={classes.footerLogoImage + ' hidden md:block'}>
            <img loading="lazy" src={logoAnde} alt="Spotted Logo" />
          </div>
          <div className={classes.footerLogoImage}>
            <img loading="lazy" src={lumepicLogo} alt="Spotted Logo" />
          </div>
          <div className={classes.footerCopywrite}>
            <p>{`© ${getCurrentYear()} Lumepic`}</p>
          </div>
          <div className={classes.footerLogoImage + ' md:hidden mt-4 ml-1'}>
            <img loading="lazy" src={logoAnde} alt="Spotted Logo" />
          </div>
        </div>
        <div>
          <div className={classes.footerBottomColumn}>
            <div className={classes.footerSocialIcons}>
              <a
                href="https://www.facebook.com/profile.php?id=100089489783866"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faFacebookF} className="text-lumepic-dark_grey text-xl" />
              </a>
              <a
                href="https://www.instagram.com/spotted.uy"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 mx-1 hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faInstagram} className="text-lumepic-dark_grey text-2xl" />
              </a>
              <a
                href="https://www.tiktok.com/@spotted.uy"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faTiktok} className="text-lumepic-dark_grey text-xl" />
              </a>
            </div>

            <div className={classes.footerLinks + ' flex justify-between items-center'}>
              <NavLink
                className="my-0.5 flex justify-center text-center text-decoration-none"
                to={FAQ}
              >
                {t('Frequently Asked Questions')}
              </NavLink>
              <NavLink
                className="md:ml-5 my-0.5 flex justify-center text-center text-decoration-none"
                to={TERMS_AND_CONDITIONS}
              >
                {t('Terms and Conditions')}
              </NavLink>
              <NavLink
                className="md:ml-5 my-0.5 flex justify-center text-center text-decoration-none"
                to={PRIVACY_POLICIES}
              >
                {t('Privacy Policies')}
              </NavLink>
              <NavLink
                className="md:ml-5 my-0.5 flex justify-center text-center text-decoration-none"
                to={CONTACT_US}
              >
                {t('Contact us')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
