import React from 'react'
import AlbumDetailsStore from '../../AlbumDetails/AlbumDetailsStore'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import MultiSelect from '../Multiselect'
import { MultiSelectOption } from '../Multiselect/MultiSelect'
import { useHistory, useLocation } from 'react-router'
import { Skeleton } from '@mui/material'
import { TagKeys } from 'services/Interfaces/Tag/Tag.interface'

type AlbumSearchBarProps = {
  albumDetailsStore: AlbumDetailsStore
}

const AlbumSearchBar = ({ albumDetailsStore }: AlbumSearchBarProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const { albumTags, isLoadingAlbumTags } = albumDetailsStore

  function handleChangeTags(values: MultiSelectOption[]) {
    const selectedTagIds = values.map((tag) => tag.value)
    albumDetailsStore.setTagIds(selectedTagIds)
    updateURLWithFilters()
  }

  const tagMap = albumDetailsStore.createTagMap()

  const updateURLWithFilters = () => {
    const searchParams = new URLSearchParams()
    if (!isNil(albumDetailsStore.tagIds)) {
      albumDetailsStore.tagIds.forEach((tagId) => {
        searchParams.append('tags', tagId)
      })
    }
    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    history.replace(updatedURL)
  }

  const combinedTagsOptions: MultiSelectOption[] = Array.from(tagMap.values())
    .flatMap((tags) =>
      tags.map((tag) => ({
        value: tag.id,
        label: tag.value,
        key: tag.key,
      }))
    )
    .filter((tag) => tag.key === TagKeys.Number || tag.key === TagKeys.Text)
    .sort((a, b) => parseInt(a.label) - parseInt(b.label))

  const selectedTagOptions: MultiSelectOption[] = albumDetailsStore.tagIds
    .map((tagId) => combinedTagsOptions.find((option) => option.value === tagId))
    .filter((option): option is MultiSelectOption => !!option)

  return (
    <>
      {!isNil(albumTags) && albumTags?.length > 0 && (
        <>
          <div className="w-full text-sm text-lumepic-grey">{t('Filter')}</div>
          <MultiSelect
            label={t('Filter by BIB')}
            options={combinedTagsOptions}
            onChange={(selectedValues) => handleChangeTags(selectedValues)}
            value={selectedTagOptions}
          />
        </>
      )}
      {isLoadingAlbumTags && (
        <div className="flex flex-col gap-2 w-full">
          <Skeleton variant="rounded" width={60} height={15} />
          <div className="flex gap-1 w-full">
            <Skeleton variant="rounded" width={220} height={35} />
            <Skeleton variant="rounded" width={35} height={35} />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(AlbumSearchBar)
