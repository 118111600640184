import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import FileInput from '../FileInput'
import { FileTypes } from '../FileInput/FileInput'
import Dropzone from '../Dropzone'
import c from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faFileArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons'
import UploadImageToAlbumStore from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadImageToAlbum/UploadImageToAlbumStore'

const validImageTypes = [FileTypes.PNG, FileTypes.JPG]

type AlbumImageUploaderProps = {
  onUpload: (files: File[]) => void
  store: UploadImageToAlbumStore
}

const AlbumImageUploader = ({ onUpload, store }: AlbumImageUploaderProps) => {
  const handleFileInput = (files: File[]) => {
    onUpload([...files])
  }
  const { t } = useTranslation()

  // Create handler for dropzone's onFilesDrop:
  const onFilesDrop = React.useCallback((files: File[]) => {
    handleFileInput(files)
  }, [])

  return (
    <div id="image-uploader-container" className="flex flex-col h-full max-h-full p-0">
      <div
        className={c(
          'w-full h-full my-2 border-dashed border-2 border-lumepic-grey rounded-lg shadow-md'
        )}
      >
        <Dropzone onMultiFileDrop={onFilesDrop}>
          <div className="flex flex-col my-28 items-center gap-2">
            {!store.hasUploadedOversizedImages ? (
              <>
                <FontAwesomeIcon icon={faFileArrowUp} className="text-4xl mb-3" />
                <div className="mb-0">{t('Drop your files here')}</div>
                <div className="text-lumepic-grey">{'- ' + t('or') + ' -'}</div>
                <FileInput
                  handleMultipleFiles={handleFileInput}
                  label={t('Select Pictures').toString()}
                  acceptedFileTypes={validImageTypes}
                />
              </>
            ) : (
              <div className="px-2 py-3 rounded-md bg-bg_details mt-4 flex items-start justify-between mx-4 border">
                <div className="flex items-start gap-1.5 w-4/5">
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-warning text-sm mt-1"
                  />
                  <span className="text-lumepic-light_black font-medium text-sm w-full">
                    {t('Some images are larger than 15MB. Please upload smaller images.')}
                  </span>
                </div>
                <div
                  className="mx-2 mb-1 cursor-pointer"
                  onClick={() => store.changeHasUploadedOversizedImages(false)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            )}
          </div>
        </Dropzone>
      </div>
    </div>
  )
}

export default observer(AlbumImageUploader)
