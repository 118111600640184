import React, { MouseEventHandler } from 'react'
import NavigationItems from '../NavigationItems'
import Backdrop from '../../UI/Backdrop'
import Aux from '../../../hoc/AuxHocs/AuxHoc'
import { NavigationItemPosition } from '../NavigationItems/NavigationItems'
import lumepicWhiteLogo from '../../../assets/icons/v2/lumepic-logo-white.svg'

type SideDrawerProps = {
  open: boolean
  onClose: MouseEventHandler<HTMLDivElement>
}

const SideDrawer = ({ open, onClose }: SideDrawerProps) => {
  const attachedClasses = [
    'fixed',
    'w-3/4',
    'h-full',
    'left-0',
    'top-0',
    'bg-lumepic-black',
    'box-border',
    'transition-all',
    'transform',
    open ? 'translate-x-0' : '-translate-x-full',
    'ease-out',
    'sm:hidden',
    'flex',
    'flex-col',
  ].join(' ')

  const drawerStyle = {
    zIndex: 2000,
  }

  if (open) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <Aux>
      <Backdrop show={open} type="Dark" clicked={onClose} />
      <div className={attachedClasses} style={drawerStyle} onClick={onClose}>
        <div className="py-2.5 px-1 w-3/4">
          <img loading="lazy" src={lumepicWhiteLogo} alt="spotted" />
        </div>
        <NavigationItems position={NavigationItemPosition.DRAWER} />
      </div>
    </Aux>
  )
}

export default SideDrawer
