import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import Spinner from '../Spinner'
import MyAccountDashboardStore from 'containers/MyAccount/components/MyAccountDashboard/stores/MyAccountDashboardStore'
import { useTranslation } from 'react-i18next'
import AuthStore from 'stores/AuthStore'
import DaysDropdown from './DaysDropdown/DaysDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons'

type DashboardValueComponentProps = {
  authStore: AuthStore
}

const DashboardValueComponent = ({ authStore }: DashboardValueComponentProps) => {
  const { t } = useTranslation()
  const dashboardDaysOptions = [
    {
      value: 7,
      label: `${t('Last week')}`,
    },
    {
      value: 30,
      label: `${t('Last month')}`,
    },
    {
      value: 365,
      label: `${t('Last year')}`,
    },
  ]
  const [store] = useState(new MyAccountDashboardStore(dashboardDaysOptions, authStore))

  const { loading, days, earnings, numberOfSales } = store

  useEffect(() => {
    const fetchData = async () => {
      return await store.getPhotographerInsights(days)
    }

    fetchData()
  }, [days])

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="flex justify-between">
        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3 md:hidden">
          <i className="text-green-500">
            <FontAwesomeIcon icon={faCircleDollarToSlot} />
          </i>
          <span className="tracking-wide text-center">{t('Earnings')}</span>
        </div>
        <div className="md:hidden">
          <DaysDropdown store={store} />
        </div>
      </div>

      <div className="w-full md:flex md:w-full md:justify-start md:gap-96">
        <div>
          <div className="hidden md:flex md:items-center md:space-x-2 md:font-semibold md:text-gray-900 md:mb-3">
            <i className="text-green-500">
              <FontAwesomeIcon icon={faCircleDollarToSlot} />
            </i>
            <span className="tracking-wide text-center">{t('Earnings')}</span>
          </div>

          {loading ? (
            <div className="pt-4">
              <Spinner noStyles size={30} />
            </div>
          ) : (
            <div className="flex flex-col gap-2 justify-center">
              {Object.keys(earnings).length > 0 ? (
                Object.entries(earnings).map(([currency, earningsValue]) => (
                  <div key={currency} className="flex gap-2">
                    <b className="text-lg md:text-xl">{currency.toUpperCase()}</b>
                    <b className="text-lg md:text-xl">{earningsValue.toFixed(2)}</b>
                  </div>
                ))
              ) : (
                <div className="flex gap-2">
                  <b className="text-lg md:text-xl">0</b>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="md:flex md:justify-between">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 md:mb-3">
              <i className="text-green-500">
                <FontAwesomeIcon icon={faChartLine} />
              </i>
              <span className="tracking-wide text-center">{t('Sales')}</span>
            </div>
            <div className="hidden md:flex md:w-1/4 md:justify-end">
              <DaysDropdown store={store} />
            </div>
          </div>
          {loading ? (
            <div className="pt-4">
              <Spinner noStyles size={30} />
            </div>
          ) : (
            <div className="flex flex-col justify-center">
              <b className="text-lg md:text-xl">{numberOfSales}</b>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(DashboardValueComponent)
