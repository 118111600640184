import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { User } from '../../../shared/models/User'
import ProfileCard from './ProfileCard'
import Button from 'components/UI/Button'
import UpdateProfileModal from './UpdateUser/UpdateProfileModal'
import storesContext from 'providers/storesContext'
import ProfileStore from './UpdateUser/ProfileStore'
import { useQuery } from 'hooks/useQuery'
import { MenuItem, TextField } from '@mui/material'
import { Countries } from 'shared/util/countries'
import { Country } from 'shared/models/Country'

type AboutSectionProps = {
  user: User
  requestPhotographerRole: () => void
}

const AboutSection = ({ user, requestPhotographerRole }: AboutSectionProps) => {
  const { t } = useTranslation()
  const { authStore, locationStore } = useContext(storesContext)!
  const [profileStore] = useState(() => new ProfileStore(authStore, locationStore))
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const [country, setCountry] = useState<string>(
    user.countryCode ?? locationStore.userLocation.country.code
  )
  const query = useQuery()

  const removePhotographerQueryParam = () => {
    const editProfile = query.get('editProfile') === 'true'
    if (user.isPhotographer && editProfile) {
      setShowEditProfileModal(true)
      window.history.replaceState({}, '', window.location.pathname)
    } else {
      setShowEditProfileModal(false)
    }
  }

  useEffect(() => {
    removePhotographerQueryParam()
  }, [window.location.search])

  const countryOptions = Countries.map((country: Country) => {
    const flagClass = `ml-2 mx-2 fi fi-${country.code.toLowerCase()}`
    return (
      <MenuItem key={country.code} value={country.code}>
        <span className={flagClass} />
        {country.name.toUpperCase()}
      </MenuItem>
    )
  })

  const updateAthleteCountryCode = (e: React.ChangeEvent<any>) => {
    setCountry(e.target.value)
    profileStore.updateUser({ countryCode: e.target.value })
  }

  const {
    email,
    firstName,
    lastName,
    phonePrefix,
    phoneNumber,
    instagramUsername,
    facebookUsername,
    isPhotographer,
  } = user

  return (
    <>
      <div className="relative p-3 bg-white border-t-4 border-primary md:flex md:pb-2">
        <div className="pb-3 mb-3 md:mr-6">
          <div className="flex items-start justify-between space-x-2 font-semibold leading-8 text-gray-900 md:hidden">
            <div className="space-x-2">
              <span className="text-lumepic-light_black">
                <i>
                  <FontAwesomeIcon icon={faUser} />
                </i>
              </span>
              <span className="tracking-wide">{t('About')}</span>
            </div>
            {isPhotographer && (
              <Button btnType={'TertiaryAction'} onClick={() => setShowEditProfileModal(true)}>
                {t('Edit')}
              </Button>
            )}
          </div>
          <ProfileCard
            user={user}
            profileStore={profileStore}
            requestPhotographerRole={requestPhotographerRole}
          />
        </div>
        <div className="md:ml-8 md:pt-6">
          <div className="items-start hidden space-x-2 font-semibold leading-8 text-gray-900 md:flex">
            <span className="text-green-500">
              <i>
                <FontAwesomeIcon icon={faUser} />
              </i>
            </span>
            <span className="tracking-wide">{t('About')}</span>
          </div>
          <div className="text-gray-700">
            <div className="grid text-sm md:grid-cols-2 gap-x-16">
              <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                <div className="px-0 py-2 font-semibold">{t('Name')}:</div>
                <div className="col-span-2 px-0 py-2 md:col-span-1">{firstName}</div>
              </div>
              <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                <div className="px-0 py-2 font-semibold">{t('Last name')}:</div>
                <div className="col-span-2 px-0 py-2 md:col-span-1">{lastName}</div>
              </div>
              <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                <div className="px-0 py-2 font-semibold">{t('Email')}:</div>
                <div className="self-end col-span-2 px-0 py-2 md:col-span-1">
                  <span className="text-blue-800">{email}</span>
                </div>
              </div>
              {user.isPhotographer && (
                <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                  <div className="px-0 py-2 font-semibold">{t('Phone number')}:</div>
                  <div className="self-end col-span-2 px-0 py-2 md:col-span-1">
                    {phonePrefix ? `(${phonePrefix}) ` : ''}
                    {phoneNumber ? phoneNumber : '-'}
                  </div>
                </div>
              )}
              {user.isPhotographer && (
                <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                  <div className="px-0 py-2 font-semibold">{t('Instagram')}:</div>
                  <div className="col-span-2 px-0 py-2 md:col-span-1">
                    {' '}
                    {instagramUsername ? `@${instagramUsername}` : '-'}
                  </div>
                </div>
              )}
              {user.isPhotographer && (
                <div className="grid grid-cols-3 text-sm md:grid-cols-2">
                  <div className="px-0 py-2 font-semibold">{t('Facebook')}:</div>
                  <div className="col-span-2 px-0 py-2 md:col-span-1">
                    {facebookUsername ? `@${facebookUsername}` : '-'}
                  </div>
                </div>
              )}
            </div>
            {!isPhotographer && (
              <div className="mt-3">
                <TextField
                  required
                  style={{ width: '200px' }}
                  className="pb-1"
                  size="small"
                  variant="outlined"
                  name="countryCode"
                  id="countryCode"
                  select
                  defaultValue={locationStore.userLocation.country?.code}
                  label={t('Country')}
                  value={country}
                  onChange={updateAthleteCountryCode}
                >
                  {locationStore.isLoadingUserLocation ? (
                    <MenuItem value={''}>Loading...</MenuItem>
                  ) : (
                    countryOptions
                  )}
                </TextField>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-0 right-0 hidden pt-2 pr-4 mt-2 md:flex md:mt-0">
          {isPhotographer && (
            <Button btnType={'TertiaryAction'} onClick={() => setShowEditProfileModal(true)}>
              {t('Edit')}
            </Button>
          )}
        </div>
      </div>
      <UpdateProfileModal
        opened={showEditProfileModal}
        onCancel={() => setShowEditProfileModal(false)}
        profileStore={profileStore}
        requestPhotographerRole={requestPhotographerRole}
        user={user}
      />
    </>
  )
}

export default observer(AboutSection)
