import React from 'react'
import { useTranslation } from 'react-i18next'
import goldenWave from '../../../assets/icons/v2/Golden-Wave.svg'
import { AUTH } from 'routing/Paths'
import ConfigService, { AssetNames } from 'config'

type CallToActionProps = {
  goTo: (e: any, path: string) => void
}

const CallToAction = ({ goTo }: CallToActionProps) => {
  const { t } = useTranslation()
  return (
    <div className="py-10 sm:py-20 bg-white">
      <h2 className="font-semibold text-primary mb-0 text-3xl lg:text-4xl text-center mb-3">
        {t('Join us!')}
      </h2>
      <div className="sm:hidden h-full flex flex-col gap-2 justify-center items-center">
        <p className="mb-0.5 font-semibold text-xl opacity-90">
          {t('Are you a photographer?') + ' >'}
        </p>
        <div className="w-4/5 text-center">
          <span className="text-sm text-lumepic-light_black">
            {t(
              'Turn your hobby into your profession: publish your photos on Lumepic and leave your selling worries behind.'
            )}
          </span>
        </div>
        <img loading="lazy" src={goldenWave} className="w-5 h-5" alt="Golden Wave Icon" />
      </div>
      <div className="w-full relative mb-2 mt-3">
        <div className="hidden absolute z-20 w-full h-full sm:flex flex-col">
          <div className="w-full flex h-1/2">
            <div className="w-1/2 flex flex-col items-center gap-1.5 md:gap-2 lg:gap-3 justify-center pl-10 lg:pl-20">
              <img
                loading="lazy"
                src={goldenWave}
                className="w-5 h-5 lg:w-7 lg:h-7"
                alt="Golden Wave Icon"
              />
              <a
                href="https://photographers.spotted.uy"
                target="_blank"
                rel="noreferrer"
                className="mb-0.5 font-semibold text-xl lg:text-2xl opacity-90 cursor-pointer transition ease-in-out delay-50 hover:text-primary duration-200"
              >
                {t('Are you a photographer?') + ' >'}
              </a>
              <div className="w-1/2 text-center leading-tight">
                <span className="text-xs md:text-sm lg:text-base text-lumepic-light_black">
                  {t(
                    'Turn your hobby into your profession: publish your photos on Lumepic and leave your selling worries behind.'
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end h-1/2">
            <div className="w-1/2 flex flex-col items-center gap-1.5 md:gap-2 lg:gap-3 justify-center pr-10 lg:pr-20">
              <p
                className="mb-0.5 font-semibold text-xl lg:text-2xl opacity-90 cursor-pointer transition ease-in-out delay-50 hover:text-primary duration-200"
                onClick={(e: any) => goTo(e, AUTH)}
              >
                {t('Are you an athlete?') + ' >'}
              </p>
              <div className="w-1/2 text-center leading-tight">
                <span className="text-xs md:text-sm lg:text-base text-lumepic-light_black">
                  {t(
                    'Immortalize incredible moments: find your photos and easily buy them in high quality.'
                  )}
                </span>
              </div>
              <img
                loading="lazy"
                src={goldenWave}
                className="w-5 h-5 lg:w-7 lg:h-7"
                alt="Golden Wave Icon"
              />
            </div>
          </div>
        </div>
        <div className="hidden z-10 sm:flex w-full justify-center items-center sm:px-10 md:px-20 lg:px-40">
          <img
            src={ConfigService.getAsset(AssetNames.LANDING_C2A_DESKTOP)}
            className="object-cover object-center w-full"
            alt="Photographer and athlete"
          />
        </div>
        <div className="sm:hidden z-10 flex w-full justify-center items-center">
          <img
            src={ConfigService.getAsset(AssetNames.LANDING_C2A_MOBILE)}
            className="object-cover object-center w-full"
            alt="Photographer and athlete"
          />
        </div>
      </div>
      <div className="sm:hidden h-full flex flex-col gap-2 justify-center items-center">
        <p className="mb-0.5 font-semibold text-xl opacity-90">{t('Are you an athlete?') + ' >'}</p>
        <div className="w-4/5 text-center">
          <span className="text-sm text-lumepic-light_black">
            {t(
              'Immortalize incredible moments: find your photos and easily buy them in high quality.'
            )}
          </span>
        </div>
        <img loading="lazy" src={goldenWave} className="w-5 h-5" alt="Golden Wave Icon" />
      </div>
    </div>
  )
}

export default CallToAction
