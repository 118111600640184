import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import DiscountProgressBar from './components/DiscountProgressBar'
import Album from '../../../../shared/models/Album'
import Spinner from 'components/UI/Spinner'
import { Event } from 'shared/models/Event'

type QuantityDiscountProps = {
  album?: Album
  event?: Event
}

const QuantityDiscount = ({ album, event }: QuantityDiscountProps) => {
  const { cartStore } = useContext(StoresContext)!
  const { t } = useTranslation()

  const cart = cartStore.getCart()

  if (cartStore.isLoading) {
    return <Spinner />
  }

  const tiersEarned =
    event?.quantityDiscount!.getTiersEarned(cart.getPhotographCount(undefined, event.id)) ??
    album?.quantityDiscount!.getTiersEarned(cart.getPhotographCount(album.id, undefined))

  const quantityDiscount = event?.quantityDiscount ?? album?.quantityDiscount

  return (
    <div className="flex flex-col w-full">
      <div className="justify-center">
        <p className="text-center text-lumepic-black font-semibold mr-2 mb-1">
          {t('Quantity discounts')}
        </p>
        <p className="text-center text-lumepic-black mb-4">
          {t('More photographs, more discount')}
        </p>
      </div>
      <DiscountProgressBar
        tiersEarned={tiersEarned!}
        quantityDiscount={quantityDiscount!}
        album={album}
        event={event}
      />
    </div>
  )
}

export default observer(QuantityDiscount)
