import React, { useContext, useEffect, useRef, useState } from 'react'
import SearchBar from 'components/UI/SearchBar'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'services/axios'
import Album from 'shared/models/Album'
import AlbumCard from 'components/Album/AlbumCards/AlbumCard/AlbumCard'
import { Grid } from '@mui/material'
import Button from 'components/UI/Button'
import shareIcon from '../../assets/icons/v2/share.svg'
import ProfileShareModal from './components/ProfileShareModal'
import PhotographerProfileStore from './PhotographerProfileStore'
import { Redirect, useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import userAvatarIcon from '../../assets/icons/v2/user-avatar-filled.svg'
import { FeatureFlags } from '../../config/constants/featureFlags'
import Spinner from 'components/UI/Spinner'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import StoresContext from '../../providers/storesContext'
import Tracker from '../../shared/tracking'
import { useQuery } from '../../hooks/useQuery'
import { AlbumSearchUrlQueryParams, Filters } from '../../components/UI/SearchBar/SearchBar'
import { getFeatureFlagValue } from 'shared/utility'

const PhotographerProfileFilters = [Filters.Location, Filters.Activity, Filters.DateRange]

type PhotographerProfileParams = {
  alias: string
}

type PhotographerProfileProps = {
  simpleView?: boolean
  onSuccess: () => void
}

const PhotographerProfile = (props: PhotographerProfileProps) => {
  const { featureFlagsStore } = useContext(StoresContext)!

  const photographerProfileEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_PROFILE,
    true
  )

  if (!photographerProfileEnabled) {
    return <Redirect to="/" />
  }
  const { t } = useTranslation()
  const { alias } = useParams<PhotographerProfileParams>()
  const { locationStore, activityStore, eventStore } = useContext(StoresContext)!

  const query = useQuery()
  const countryCode = query.get(AlbumSearchUrlQueryParams.CountryCode) || undefined
  const locationId = query.get(AlbumSearchUrlQueryParams.LocationId) || undefined
  const activityId = query.get(AlbumSearchUrlQueryParams.ActivityId) || undefined

  const [searchBarStore] = useState(
    () =>
      new SearchBarStore(eventStore, activityStore, locationStore, {
        countryCode,
        locationId,
        activityId,
      })
  )
  const [store] = useState(() => new PhotographerProfileStore(searchBarStore, alias))

  const [shareModalOpened, setShareModalOpened] = useState(false)

  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current && store.albums.length === 0 && !store.isLoadingProfile) {
      store.fetchAlbums()
      Tracker.pageView('/feed/albums', 'Feed Albums')
      initialRender.current = false
    }
  }, [store.isLoadingProfile])

  useEffect(() => {
    if (!initialRender.current) {
      const atLeastOneQueryParam = !isNil(countryCode) || !isNil(locationId) || !isNil(activityId)
      // If there's at least one query param, we don't need to fetch albums. They will be fetched by the function onSearch passed to SearchBar
      if (!atLeastOneQueryParam) {
        store.fetchAlbums()
      }
    }
  }, [store.page])

  const fetchNextPage = () => {
    store.fetchNextPage()
  }

  const imageDisplayView = (
    <img
      src={!isNil(store.profilePictureUrl) ? store.profilePictureUrl : userAvatarIcon}
      alt="profile-picture"
      className="object-cover object-center w-28 h-28 mx-auto rounded-full md:w-36 md:h-36"
    />
  )

  if (store.isLoadingProfile) {
    return <Spinner />
  }

  return (
    <>
      <div className="container mx-auto">
        <section className="flex flex-col md:flex-row md:justify-between p-2 my-2 bg-white md:rounded-md">
          <div className="flex w-full md:gap-16">
            <div className="rounded-full w-28 h-28 md:w-36 md:h-36">{imageDisplayView}</div>
            <div className="flex flex-col justify-around ml-4 w-3/5 md:w-1/2">
              <div className="flex justify-between">
                <span className="font-semibold text-lumepic-grey">{t('Photographer')}</span>
                <span className="text-lumepic-grey pl-4 text-sm  md:p-0 md:text-base">
                  {store.firstName} {store.lastName}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-lumepic-grey ">{t('Alias')}</span>
                <span className="text-lumepic-grey text-sm  md:text-base">{alias}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-semibold text-lumepic-grey">{t('Published albums')}</span>
                <span className="text-lumepic-grey text-sm  md:text-base">
                  {store.publishedAlbums}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full md:w-1/3 md:h-1/3 mt-3 md:mt-0">
            <Button btnType={'PrimaryAction'} onClick={() => setShareModalOpened(true)}>
              <div className="flex gap-2 text-sm">
                <img src={shareIcon} width={20} height={20} alt="Share-icon" />
                <span>{t('Share profile')}</span>
              </div>
            </Button>
          </div>
        </section>

        <section className="p-2 my-2 bg-white md:rounded-md">
          <SearchBar
            searchBarStore={searchBarStore}
            onSearch={() => store.fetchAlbums(true)}
            filters={PhotographerProfileFilters}
          />
        </section>
        <section className="py-2 my-2 md:rounded-md">
          <InfiniteScroll
            dataLength={store.albums.length}
            next={fetchNextPage}
            hasMore={store.hasMorePages()}
            loader={<></>}
            scrollThreshold={0.8}
            className="overflow-hidden"
          >
            {store.albums.length > 0 ? (
              <Grid container className="pb-4 grow" spacing={1}>
                {store.albums.map((album: Album, index) => (
                  <Grid
                    xs={6}
                    sm={6}
                    md={6}
                    lg={props.simpleView ? 12 : 4}
                    xl={props.simpleView ? 12 : 4}
                    key={`${album.id}-${index}`}
                    item
                  >
                    <AlbumCard album={album} hidePhotographerName spotTitleExtraStyles={true} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              !store.isLoadingAlbums && <NoResultsFound />
            )}
            {store.isLoadingAlbums && <Spinner />}
          </InfiniteScroll>
        </section>
      </div>
      <ProfileShareModal
        open={shareModalOpened}
        handleClose={() => setShareModalOpened(false)}
        alias={alias}
      />
    </>
  )
}

export default withErrorHandler(observer(PhotographerProfile), axios)
