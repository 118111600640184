import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import EventsStore from 'containers/Events/EventsStore'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type EventsSwitcherProps = {
  searchBarStore: SearchBarStore
  eventsStore: EventsStore
}

const EventsSwitcher = ({ searchBarStore, eventsStore }: EventsSwitcherProps) => {
  const { t } = useTranslation()
  const [isFutureEventsActive, setIsFutureEventsActive] = useState(false)

  const today = new Date()
  return (
    <div className="flex items-center justify-center shadow-md rounded-3xl">
      <span
        className={`text-base md:text-lg rounded-l-3xl px-3 md:px-4 py-2 hover:cursor-pointer transition duration-300 ${
          !isFutureEventsActive
            ? 'text-lumepic-black bg-primary rounded-l-3xl'
            : 'text-lumepic-grey hover:bg-lumepic-light_black hover:text-white'
        } 
          ${
            isFutureEventsActive &&
            searchBarStore.dateRange &&
            !isNil(searchBarStore.dateRange) &&
            'hover: bg-white hover:text-lumepic-grey hover:cursor-default'
          }
          `}
        onClick={() => {
          if (
            searchBarStore.dateRange &&
            searchBarStore.dateRange[0] &&
            searchBarStore.dateRange[0] > today &&
            !isNil(searchBarStore.dateRange[1])
          ) {
            searchBarStore.reset()
            eventsStore.fetchEvents(true)
            setIsFutureEventsActive(false)
          } else {
            eventsStore.fetchEvents(true)
            setIsFutureEventsActive(false)
          }
        }}
      >
        {t('Past Events')}
      </span>
      <span
        className={`text-base md:text-lg rounded-r-3xl px-3 md:px-4 py-2 hover:cursor-pointer transition duration-300 ${
          isFutureEventsActive
            ? 'text-lumepic-black bg-primary rounded-r-3xl'
            : 'text-lumepic-grey hover:bg-lumepic-light_black hover:text-white'
        }  
           ${
             !isFutureEventsActive &&
             searchBarStore.dateRange &&
             !isNil(searchBarStore.dateRange) &&
             'hover: bg-white hover:text-lumepic-grey hover:cursor-default'
           }
          `}
        onClick={() => {
          if (
            searchBarStore.dateRange &&
            searchBarStore.dateRange[1] &&
            searchBarStore.dateRange[1] < today &&
            !isNil(searchBarStore.dateRange[0])
          ) {
            searchBarStore.reset()
            eventsStore.fetchEvents(true, true)
            setIsFutureEventsActive(true)
          } else {
            eventsStore.fetchEvents(true, true)
            setIsFutureEventsActive(true)
          }
        }}
      >
        {t('Future Events')}
      </span>
    </div>
  )
}

export default EventsSwitcher
