import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../../../providers/storesContext'
import PhotographerDashboard from './PhotographerDashboard'

const MyAccountDashboard = () => {
  const { authStore } = useContext(StoresContext)!
  const loggedUser = authStore.getLoggedUser()

  return <div className="my-3">{loggedUser.isPhotographer && <PhotographerDashboard />}</div>
}

export default observer(MyAccountDashboard)
