import axios from './axios'
import * as paths from './Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { NewCodeDiscountInterface } from './Interfaces/Discount/CodeDiscount.interface'
import { NewCodeDiscount } from 'shared/models/NewCodeDiscount'
import { CreateNewCodeDiscountDto } from 'containers/MyAccount/components/PhotographerDiscountSection/CreateNewCodeDiscountDto'

class CodeDiscountService {
  async fetchCodeDiscounts(token: string): Promise<NewCodeDiscount[]> {
    return axios
      .get<NewCodeDiscountInterface[]>(paths.CODE_DISCOUNTS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<NewCodeDiscountInterface[]>) => {
        return response.data.map((res) => NewCodeDiscount.init(res))
      })
      .catch(parseAxiosErrorResponse)
  }

  async createCodeDiscount(
    token: string,
    discount: CreateNewCodeDiscountDto
  ): Promise<NewCodeDiscount> {
    return axios
      .post<NewCodeDiscountInterface>(paths.CODE_DISCOUNTS, discount, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  async deactivateCodeDiscount(token: string, codeDiscountId: string): Promise<void> {
    return axios
      .post<NewCodeDiscountInterface>(
        paths.deactivateCodeDiscount(codeDiscountId),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default CodeDiscountService
