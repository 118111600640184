export enum FeatureFlags {
  PHOTOGRAPHER_PROFILE = 'photographer_profile',
  BUY_BURSTS_PACKAGE = 'buy_bursts_package',
  COUNTRY_FILTER = 'country_filter',
  CODE_DISCOUNTS_CREATION = 'code_discounts_creation',
  EVENT_PHOTOGRAPHS_REQUEST = 'event_photographs_request',
  ENABLE_STRIPE = 'enable_stripe', //Enables Stripe payments
  ENABLE_STRIPE_ONBOARDING = 'enable_stripe_onboarding', //Enables Stripe payments
  ENABLE_MERCADOPAGO_ONBOARDING = 'enable_mercadopago_onboarding', //Enables Mercadopago Onboarding //TODO: deprecated ???
  ENABLE_MERCADOPAGO = 'enable_mercadopago', //Enables Mercadopago payments //TODO: deprecated ???
  ALBUMS_PACKAGE_PURCHASE = 'albums_package_purchase', // Enables tag and face recogntition package purchases
  EVENTS_PACKAGE_PURCHASE = 'events_package_purchase',
  PRINTED_PHOTOGRAPHS = 'printed_photographs',
}
