import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UploadImageToAlbumStore, { ProgressInfo } from '../UploadImageToAlbumStore'
import ImageUploadProgress from './ImageUploadProgress'
import { formatBits } from '../../../../../../shared/utility'
import { faCircleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'

interface Column {
  id: 'name' | 'extension' | 'size' | 'status'
  label: string
  minWidth?: number
  format?: any
}

const columns: readonly Column[] = [
  { id: 'name', label: 'File Name', minWidth: 170 },
  { id: 'extension', label: 'Extension', minWidth: 100 },
  { id: 'size', label: 'Size', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
]

type ImageUploadTableProps = {
  store: UploadImageToAlbumStore
  isOnline: boolean
}

const ImageUploadTable = ({ store, isOnline }: ImageUploadTableProps) => {
  const { t } = useTranslation()
  const { progressInfos, images } = store
  const currentImageIndex = store.getCurrentUploadingImageIndex()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [photographLines, setPhotographLines] = useState<ProgressInfo[] | null>(null)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `${from}–${to} ${t('of')} ${count !== -1 ? count : `more than ${to}`}`
  }

  let totalUploadSize = 0
  let uploadedSuccessfullyCount = 0
  let uploadedWithErrorsCount = 0
  let totalProcessedCount = 0
  let uploadedSuccessfullySize = 0
  let uploadedWithErrorsSize = 0

  for (const progressInfo of progressInfos) {
    totalUploadSize += progressInfo.size
    if (progressInfo.percentage === 100 && !progressInfo.error) {
      uploadedSuccessfullyCount++
      uploadedSuccessfullySize += progressInfo.size
    }
    if (progressInfo.error) {
      uploadedWithErrorsCount++
      uploadedWithErrorsSize += progressInfo.size
    }
    if (progressInfo.percentage === 100 || progressInfo.error) {
      totalProcessedCount++
    }
  }

  const progressInfosDisplayed: ProgressInfo[] =
    !store.allImagesUploaded() || isNil(photographLines) ? progressInfos : photographLines

  const clearPhotographFilters = () => {
    if (store.allImagesUploaded()) {
      setPhotographLines(progressInfos)
    } else return
  }
  const showPhotographsWithErrors = () => {
    if (store.allImagesUploaded()) {
      const _progressInfos = progressInfos
      setPhotographLines(_progressInfos.filter((pi) => pi.error))
    } else return
  }
  const showPhotographsWithoutErrors = () => {
    if (store.allImagesUploaded()) {
      const _progressInfos = progressInfos
      setPhotographLines(_progressInfos.filter((pi) => !pi.error))
    } else return
  }

  return (
    <>
      {store.imagesSubmitted && (
        <div
          className="w-full flex border-lumepic-medium_grey rounded-md mb-2 bg-bg_details"
          style={{ borderWidth: '1px' }}
        >
          <div
            className={`w-1/3 px-2 sm:px-3 pt-1 ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={clearPhotographFilters}
          >
            <div className="flex gap-2 items-center">
              <p className="mb-1 font-medium text-lg sm:text-xl">{t('Summary')}</p>
              {totalProcessedCount < progressInfos.length && (
                <div className="flex justify-center items-center">
                  <CircularProgress color="primary" size="12px" thickness={5} />
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="flex gap-1">
                <p className="mb-0 text-lumepic-grey">{totalProcessedCount}</p>
                <p className="mb-0 text-lumepic-grey">{t('of')}</p>
                <p className="mb-0 text-lumepic-grey">
                  {progressInfos.length} {t('files')}
                </p>
              </div>
              <span className="hidden sm:block text-lumepic-grey font-light sm:ml-1">{'| '}</span>
              <p className="mb-0 sm:ml-1 text-lumepic-grey">{formatBits(totalUploadSize)}</p>
              <span className="hidden sm:block text-lumepic-grey font-light sm:ml-1">{'| '}</span>
              <p className="mb-0 sm:ml-1 text-lumepic-grey">
                {'('}
                {((totalProcessedCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </p>
            </div>
          </div>
          <div
            className={`w-1/3 px-3 py-2 border-lumepic-medium border-l border-r ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={showPhotographsWithoutErrors}
          >
            <p className="mb-1 font-medium text-lumepic-grey">{t('Uploaded successfully')}</p>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
              <FontAwesomeIcon icon={faCircleCheck} className="text-success hidden sm:block" />
              <span className="text-success text-sm">
                {uploadedSuccessfullyCount} {t('files')}
              </span>
              <span className="hidden sm:block text-success font-light">{'| '}</span>
              <span className="text-success text-sm">{formatBits(uploadedSuccessfullySize)}</span>
              <span className="text-success text-sm">
                {'('}
                {((uploadedSuccessfullyCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </span>
            </div>
          </div>
          <div
            className={`w-1/3 px-3 py-2 ${
              store.allImagesUploaded() &&
              'cursor-pointer hover:bg-lumepic-medium_grey transition-colors duration-200 active:bg-lumepic-semi_medium_grey'
            }`}
            onClick={showPhotographsWithErrors}
          >
            <p className="mb-1 font-medium text-lumepic-grey">{t('With errors')}</p>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
              <FontAwesomeIcon icon={faCircleXmark} className="text-warning hidden sm:block" />
              <span className="text-warning text-sm">
                {uploadedWithErrorsCount} {t('files')}
              </span>
              <span className="hidden sm:block text-warning font-light">{'| '}</span>
              <span className="text-warning text-sm">{formatBits(uploadedWithErrorsSize)}</span>
              <span className="text-warning text-sm">
                {'('}
                {((uploadedWithErrorsCount / progressInfos.length) * 100).toFixed(0)}
                {'%)'}
              </span>
            </div>
          </div>
        </div>
      )}
      {!isOnline && (
        <p className="text-center">{t('Connection interrupted. Waiting for connection...')}</p>
      )}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: '6px' }}>
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {progressInfosDisplayed
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((progressInfo, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        if (column.id === 'name') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {progressInfo.fileName}
                            </TableCell>
                          )
                        } else if (column.id === 'extension') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {progressInfo.type}
                            </TableCell>
                          )
                        } else if (column.id === 'size') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              {formatBits(progressInfo.size)}
                            </TableCell>
                          )
                        } else if (column.id === 'status') {
                          return (
                            <TableCell
                              style={{ minWidth: column.minWidth, padding: '6px' }}
                              key={column.id}
                            >
                              <ImageUploadProgress
                                key={column.id}
                                image={images[index]}
                                progressInfo={progressInfo}
                                index={currentImageIndex}
                              />
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={progressInfosDisplayed.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          page={page}
          labelDisplayedRows={defaultLabelDisplayedRows}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '2px',
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '2px',
            },
          }}
        />
      </Paper>
      {store.hasImagesWithoutMetadata && (
        <div
          className="p-2 rounded-md bg-bg_details mt-2 flex items-center justify-between"
          style={{ borderWidth: '1px' }}
        >
          <div className="flex flex-col w-4/5">
            <span className="text-lumepic-light_black font-medium text-sm">
              {t('There are images without metadata, which can cause them to appear out of order.')}
            </span>
            <span className="text-xs mt-1.5 font-light">
              {t(
                'Metadata: Additional information that accompanies the image and provides details on various aspects related to the capture and content of the photo, such as date and time.'
              )}
            </span>
          </div>
          <div
            className="mx-2 cursor-pointer"
            onClick={() => store.changeHasImagesWithoutMetadata(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      )}
      {store.hasUploadedOversizedImages && (
        <div className="p-2 rounded-md bg-bg_details mt-4 flex items-center justify-between border">
          <div className="flex items-start gap-1.5 w-4/5">
            <FontAwesomeIcon icon={faCircleExclamation} className="text-warning text-sm mt-1" />
            <span className="text-lumepic-light_black font-medium text-sm w-full">
              {t('Some images are larger than 15MB and couldn’t be uploaded. Try smaller files.')}
            </span>
          </div>
          <div
            className="mx-2 mb-1 cursor-pointer"
            onClick={() => store.changeHasUploadedOversizedImages(false)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ImageUploadTable)
