import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Album from '../../../../../shared/models/Album'
import 'moment/locale/es'
import moment from 'moment'
import FacebookShareButton from 'components/UI/ShareButtons/FacebookShareButton'
import WhatsappShareButton from 'components/UI/ShareButtons/WhatsappShareButton'
import TwitterShareButton from 'components/UI/ShareButtons/TwitterShareButton'
import shareIcon from 'assets/icons/v2/shareChain.svg'
import { WhatsappIcon } from 'react-share'
import ConfigService from 'config'
import { Tooltip } from '@mui/material'
import Breadcrumb from 'components/UI/Breadcrumb'
import { isNil } from 'lodash'

type PublishAlbumModalProps = {
  album: Album
}

const PublishAlbum = ({ album }: PublishAlbumModalProps) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  const shareUrl = ConfigService.buildAlbumUrl(album.id)
  const shareTitle = t('Check out this album on Lumepic!')

  const handleCopyUrlClick = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(shareUrl)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  const tooltipText = isCopied ? t('Copied to clipboard') : t('Copy to clipboard')

  return (
    <div className="flex flex-col w-full items-center justify-center my-3 gap-4">
      <div className="mt-2 flex flex-col items-center gap-2.5">
        <div className="mb-3">
          <Breadcrumb
            completedSteps={!isNil(album.event?.quantityDiscountId) ? 4 : 5}
            totalSteps={!isNil(album.event?.quantityDiscountId) ? 4 : 5}
          />
        </div>
        <div className="text-2xl font-semibold text-center">{t('New Album Created!')}</div>
      </div>
      <div className="w-2/3 text-lumepic-grey text-center font-normal">
        {t('The album') +
          " '" +
          album.location.spotName +
          ' ' +
          moment.utc(album.takenDate).format('dddd DD/MM/YYYY') +
          "' " +
          t('was created successfully.')}
      </div>
      <div className="mb-2 text-lumepic-light_black w-full text-center text-xl font-medium">
        {t('Share for more visibility!')}
      </div>
      <div
        className="flex justify-center gap-4 mt-2.5 items-center bg-bg_details px-3 py-2 rounded-lg"
        style={{ borderWidth: '1px' }}
      >
        <Tooltip title={tooltipText} arrow>
          <div
            onClick={() => handleCopyUrlClick()}
            className="w-10 h-10 flex justify-center items-center rounded-full bg-lumepic-medium_grey cursor-pointer hover:opacity-90 transition duration-200"
          >
            <img src={shareIcon} alt="CopyUrl" className="p-1.5" />
          </div>
        </Tooltip>
        <FacebookShareButton url={shareUrl} iconSize={40} />
        <WhatsappShareButton url={shareUrl} title={shareTitle}>
          <WhatsappIcon size={40} round={true} enableBackground="true" />
        </WhatsappShareButton>
        <TwitterShareButton url={shareUrl} iconSize={40} />
      </div>
    </div>
  )
}

export default observer(PublishAlbum)
