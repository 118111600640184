import axios from './axios'
import * as paths from './Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { CodeDiscount } from '../shared/models/CodeDiscount'
import { ValidateCodeDiscountInterface } from './Interfaces/Discount/CodeDiscount.interface'

class DiscountService {
  async findForPurchase(
    code: string,
    photographIds: string[],
    token: string
  ): Promise<[CodeDiscount, string[]]> {
    return axios
      .get<ValidateCodeDiscountInterface>(paths.discountByCode(code), {
        params: {
          photographIds,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<ValidateCodeDiscountInterface>): [CodeDiscount, string[]] => {
        const codeDiscount = CodeDiscount.init(response.data.discount)
        const applicablePhotographs = response.data.photographIds
        return [codeDiscount, applicablePhotographs]
      })
      .catch(parseAxiosErrorResponse)
  }

  createCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .post(paths.DISCOUNT, discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  createPersonalCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .post(paths.PERSONAL_CODE_DISCOUNT, discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .put(paths.updateDiscountByCode(discountData.id), discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteDiscount(discountId: string, token: string) {
    return axios
      .delete(paths.deleteDiscount(discountId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendDiscount(token: string, discountId: string) {
    return axios
      .post(paths.suspendDiscount(discountId), discountId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activateDiscount(token: string, discountId: string) {
    return axios
      .post(paths.activateDiscount(discountId), discountId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default DiscountService
