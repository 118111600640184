import React from 'react'
import { TwitterShareButton as ReactShareTwitterButton, TwitterIcon } from 'react-share'

type TwitterShareButtonProps = {
  url: string
  iconSize: number
}

const TwitterShareButton = (props: TwitterShareButtonProps) => (
  <ReactShareTwitterButton url={props.url}>
    <TwitterIcon size={props.iconSize} round={true} enableBackground="true" />
  </ReactShareTwitterButton>
)

export default TwitterShareButton
