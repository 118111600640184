import React from 'react'
import { useTranslation } from 'react-i18next'
import { Purchase } from 'shared/models/Purchase'
import { isNil } from 'lodash'
import SaleRowItem from './SaleRowItem'
import { observer } from 'mobx-react'
import { User } from 'shared/models/User'
import { getFeesAmountGenerator } from 'shared/utility'

type SaleDetailsProps = {
  sale: Purchase
  loggedUser: User
  isDetailsOpened?: boolean
  saleDesktopDetailsOpened?: Purchase | null
  index: number
}

const SaleItemDetails = ({
  sale,
  loggedUser,
  isDetailsOpened,
  saleDesktopDetailsOpened,
  index,
}: SaleDetailsProps) => {
  const { t } = useTranslation()

  if (!isNil(isDetailsOpened) && !isDetailsOpened) {
    return null
  }
  if (!isNil(saleDesktopDetailsOpened) && !saleDesktopDetailsOpened) {
    return null
  }

  const discounts =
    !isNil(sale.totalPrice) &&
    sale.status === 'approved' &&
    (sale.lineItems.reduce((acc, lineItem) => acc + lineItem.discountTotal, 0) || 0)

  const subTotal = sale.lineItems.reduce((acc, lineItem) => acc + lineItem.subtotalPrice, 0)

  const totalEarnings = subTotal - (discounts || 0) - getFeesAmountGenerator(loggedUser.id, sale)

  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-col w-full gap-1 md:flex md:mt-4 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <div className="hidden md:flex md:items-center md:gap-1 md:pb-3">
          <span className="md:flex font-semibold text-xl text-lumepic-light_black">
            {t('Sale')} #{index}
          </span>
          <span className="text-lumepic-dark_grey">({sale.photographs.length})</span>
        </div>

        {sale.lineItems.map((lineItem, index) => (
          <SaleRowItem key={index} lineItem={lineItem} />
        ))}
      </div>

      <div className="flex flex-col md:my-6 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <span className="font-semibold text-xl text-lumepic-light_black pt-2 pb-3">
          {t('Sale details')}
        </span>
        <span className="flex justify-between">
          <p className="text-base font-semibold">{t('Subtotal')}</p>
          <p className="text-base font-semibold">${subTotal}</p>
        </span>

        <div>
          <div className="flex justify-between">
            <p className="text-lumepic-grey font-semibold text-sm">{t('Discounts')} </p>

            <p className="text-lumepic-grey font-semibold text-sm">
              {discounts && discounts.toFixed(2) !== '0.00' ? `- $${discounts.toFixed(2)}` : '-'}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-lumepic-grey font-semibold text-sm">{t('Service')} </p>

            <p className="text-lumepic-grey font-semibold text-sm">
              {getFeesAmountGenerator(loggedUser.id, sale).toFixed(2) !== '0.00'
                ? `- $${getFeesAmountGenerator(loggedUser.id, sale).toFixed(2)}`
                : '-'}
            </p>
          </div>
          <div className="border-t my-2" />
          <div className="flex justify-between pb-3 ">
            <p className="font-extrabold text-lg">{t('Total')} </p>
            <p className="font-extrabold text-lg">
              {sale.currency.toUpperCase()} ${totalEarnings.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(SaleItemDetails)
