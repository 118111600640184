import React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import Button from 'components/UI/Button'
import { AUTH } from '../../../../../../routing/Paths'
import Cart from '../../../../../../shared/models/Cart'
import { useTranslation } from 'react-i18next'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'

type PayButtonProps = {
  cart: Cart
  onPurchase: (cart: Cart) => void
  isLoggedIn: boolean
  isLoading: boolean
  loginRegisterStore: LoginRegisterStore
}

const PayButton = ({
  cart,
  onPurchase,
  isLoggedIn,
  isLoading,
  loginRegisterStore,
}: PayButtonProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  const goToAuth = () => {
    history.push(AUTH)
  }

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  return (
    <>
      {isLoggedIn ? (
        <div id="mercadoPagoContainer">
          <Button
            btnType="PrimaryAction"
            onClick={() => onPurchase(cart)}
            extraStyle="w-full"
            isLoading={isLoading}
          >
            {t('Pay')}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col text-center">
          <p>{t('You must log in to continue with the purchase')}</p>
          <Button btnType="SecondaryAction" onClick={goToAuth} extraStyle="w-full">
            {t('Login')} | {t('Register')}
          </Button>
          <div className="mx-auto mt-2">
            <GoogleLogin
              size="large"
              width="150px"
              useOneTap
              onSuccess={googleLoginSuccess}
              onError={googleLoginError}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(PayButton)
