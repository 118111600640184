import { makeAutoObservable, runInAction } from 'mobx'
import DiscountService from 'services/DiscountService'
import { CodeDiscount } from 'shared/models/CodeDiscount'
import InputStore from 'shared/store/InputStore'
import AuthStore from 'stores/AuthStore'
import * as yup from 'yup'
import { CodeDiscountUnit } from '../../../../services/Interfaces/Discount/CodeDiscount.interface'

class DiscountCodeFormModalStore {
  private discountService
  private id: string | null
  private discount: CodeDiscount
  public discountCode: InputStore<string>
  public discountAmount: InputStore<number>
  public isLoading: boolean
  public error: any
  private serverError: string
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.discountService = new DiscountService()
  }

  get purchaseCodeDiscountUnit() {
    return [{ label: 'Percentage', value: CodeDiscountUnit.PERCENTAGE }]
  }

  reset() {
    this.id = null
    this.discountCode = new InputStore(
      yup.string().required('El codigo del descuento es requerido')
    )
    const user = this.authStore.getLoggedUser()
    this.discountCode.setValue(
      `${user.lastName.toLowerCase().replace(/\s+/g, '')}-${Math.floor(Math.random() * 1000)}`
    )
    this.discountAmount = new InputStore(
      yup
        .number()
        .moreThan(0, 'El descuento tiene que ser mayor a 0')
        .lessThan(100, 'El descuento tiene que ser menor a 100')
        .positive('El descuento tiene que ser positivo')
        .required('El descuento es requerido')
    )
    this.discountAmount.setValue(10)
    this.isLoading = false
    this.error = null
  }

  changeDiscountCode(val: string) {
    this.discountCode.setValue(val.toLowerCase().replace(/ /g, ''))
  }

  changeDiscountAmount(val: number) {
    this.discountAmount.setValue(val)
  }

  clearErrors() {
    this.discountCode.clearError()
    this.discountAmount.clearError()
    this.error = null
  }

  async validate(): Promise<boolean> {
    this.clearErrors()

    const codeIsValid = await this.discountCode.validate()
    const amountIsValid = await this.discountAmount.validate()

    return codeIsValid && amountIsValid
  }

  async fetchDiscounts() {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const { discount } = await this.discountService.createCodeDiscount(this.authStore.getToken())
      runInAction(() => {
        this.isLoading = false
        this.discount = discount
        this.error = null
      })
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async createPersonalDiscount() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const discount = new CodeDiscount()
        discount.code = this.discountCode.value
        discount.amount = this.discountAmount.value
        const newDiscount = await this.discountService.createPersonalCodeDiscount(
          this.authStore.getToken(),
          discount
        )

        runInAction(() => {
          this.isLoading = false
        })

        return newDiscount
      } catch (e: any) {
        this.error = e
        this.isLoading = false
      }
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'discountCode':
          this.discountCode.setError(true, error)
          displayedError = true
          break

        case 'discountAmount':
          this.discountAmount.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default DiscountCodeFormModalStore
