import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Purchase } from 'shared/models/Purchase'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import PurchaseRowItem from './PurchaseRowItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/UI/Button'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import PurchaseStore from '../PurchaseStore'
import { PhotographUtils } from 'shared/util/photographs.utils'

type PurchaseItemDetailsProps = {
  purchase: Purchase
  isDetailsOpened?: boolean
  purchaseDesktopDetailsOpened?: Purchase | null
  index: number
  store: PurchaseStore
}

const PurchaseItemDetails = ({
  purchase,
  isDetailsOpened,
  purchaseDesktopDetailsOpened,
  index,
  store,
}: PurchaseItemDetailsProps) => {
  const { t } = useTranslation()
  const [isLoadingMultipleDownloads, setIsLoadingMultipleDownloads] = useState(false)

  if (!isNil(isDetailsOpened) && !isDetailsOpened) {
    return null
  }
  if (!isNil(purchaseDesktopDetailsOpened) && !purchaseDesktopDetailsOpened) {
    return null
  }

  const discounts =
    !isNil(purchase.totalPrice) && purchase.status === 'approved' && purchase.discountTotal

  const subTotal = purchase.value

  const total = subTotal - (discounts || 0)

  const photographsFileNames = purchase.photographs.map(
    (photograph) => `${photograph.id}.${photograph.extension}`
  )

  const handleClickDownloadPhotographs = async () => {
    setIsLoadingMultipleDownloads(true)
    await PhotographUtils.downloadPurchasedImages(purchase.id, photographsFileNames, store, t).then(
      () => {
        setIsLoadingMultipleDownloads(false)
      }
    )
  }

  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-col w-full gap-1 md:flex md:mt-4 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <div className="w-full flex justify-between items-center pt-2 md:pt-0">
          <div className="hidden md:w-full md:flex md:items-center md:gap-1">
            <span className="font-semibold text-2xl text-lumepic-light_black">
              {t('Purchase')} #{index}
            </span>
            <span className="text-lumepic-dark_grey text-xl">({purchase.photographs.length})</span>
          </div>
          <div className="w-full flex justify-center md:justify-end">
            {photographsFileNames.length > 1 && (
              <Button
                btnType={'PrimaryAction'}
                onClick={() => {
                  isLoadingMultipleDownloads ? null : handleClickDownloadPhotographs()
                }}
                isLoading={isLoadingMultipleDownloads}
                loadingText={t('Downloading') + '...'.toString()}
                extraStyle="text-sm md:text-sm"
              >
                <div className="flex gap-1.5 items-center justify-center">
                  <FontAwesomeIcon icon={faDownload} /> {t('Download all')}
                </div>
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          {purchase.lineItems.map((lineItem, index) => (
            <PurchaseRowItem
              purchase={purchase}
              key={index}
              lineItem={lineItem}
              store={store}
              isLoadingMultipleDownloads={isLoadingMultipleDownloads}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col md:my-6 md:py-2 md:px-3 md:rounded-lg md:bg-white">
        <span className="font-semibold text-xl text-lumepic-light_black pt-2 pb-3">
          {t('Purchase details')}
        </span>
        <span className="flex justify-between">
          <p className="text-base font-semibold">{t('Subtotal')}</p>
          <p className="text-base font-semibold">${subTotal.toFixed(2)}</p>
        </span>

        <div>
          <div className="flex justify-between">
            <p className="text-lumepic-grey font-semibold text-sm">{t('Discounts')} </p>

            <p className="text-lumepic-grey font-semibold text-sm">
              {discounts && discounts.toFixed(2) !== '0.00' ? `- $${discounts.toFixed(2)}` : '-'}
            </p>
          </div>

          <div className="border-t my-2" />
          <div className="flex justify-between pb-3 ">
            <p className="font-extrabold text-lg">{t('Total')} </p>
            <p className="font-extrabold text-lg">
              {purchase.currency.toUpperCase()} ${total.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PurchaseItemDetails)
