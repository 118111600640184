import React from 'react'
import EventDetailsFirstRenderMobile from './EventDetailsFirstRenderMobile'
import { observer } from 'mobx-react'
import EventDetailsFirstRenderDesktop from './EventDetailsFirstRenderDesktop'
import EventDetailsStore from '../../EventDetailsStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

type EventDetailsFirstRenderProps = {
  handleOpenUploadSelfieModal: () => void
  filterByFaceRecognition: () => void
  handleOpenFilterByTagModal: () => void
  filterByTagValue: () => void
  handleClearFilters: () => void
  store: EventDetailsStore
}

const EventDetailsFirstRender = ({
  handleOpenFilterByTagModal,
  handleOpenUploadSelfieModal,
  filterByFaceRecognition,
  filterByTagValue,
  handleClearFilters,
  store,
}: EventDetailsFirstRenderProps) => {
  const { t } = useTranslation()
  return (
    <div className="mt-4">
      <div className="border-b-4 border-primary_dark bg-white flex items-center gap-1.5 py-1.5 px-1.5 lg:px-2.5">
        <FontAwesomeIcon icon={faFilter} className="text-lumepic-grey text-lg ml-1" />
        <header className="mt-px text-lg">{t('Search')}</header>
      </div>
      <div className="lg:hidden">
        <EventDetailsFirstRenderMobile
          handleOpenFilterByTagModal={handleOpenFilterByTagModal}
          handleOpenUploadSelfieModal={handleOpenUploadSelfieModal}
          handleClearFilters={handleClearFilters}
          store={store}
        />
      </div>
      <div className="hidden lg:block">
        <EventDetailsFirstRenderDesktop
          filterByFaceRecognition={filterByFaceRecognition}
          filterByTagValue={filterByTagValue}
          handleClearFilters={handleClearFilters}
          store={store}
        />
      </div>
    </div>
  )
}

export default observer(EventDetailsFirstRender)
