import axios from './axios'
import * as paths from './Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import PurchaseRequestInterface from './Interfaces/Purchase/PurchaseRequest.interface'
import { AxiosResponse } from 'axios'
import { Purchase, PurchaseStatus } from '../shared/models/Purchase'
import { Paginated } from './Interfaces/Paginated'

type FetchPurchasesOptions = {
  pagination: {
    limit: number
    skip: number
  }
  sellerId?: string
  status?: PurchaseStatus
}

class PurchaseService {
  purchase(purchaseData: PurchaseRequestInterface, token: string): Promise<Purchase> {
    return axios
      .post(paths.MULTI_ALBUM_PURCHASE, purchaseData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data as Purchase)
      .catch(parseAxiosErrorResponse)
  }

  fetchPurchase(purchaseId: string, token: string): Promise<Purchase> {
    return axios
      .get(paths.fetchPurchase(purchaseId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  fetchPurchaseByStripeSessionId(sessionId: string, token: string): Promise<Purchase> {
    return axios
      .get(paths.fetchPurchaseByStripeSessionId(sessionId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  purchases(
    token: string,
    limit: number,
    skip: number,
    status?: PurchaseStatus,
    sellerId?: string
  ): Promise<Paginated<Purchase>> {
    const params: FetchPurchasesOptions = {
      pagination: {
        limit: Number(limit),
        skip: Number(skip),
      },
    }
    if (status) {
      params.status = status
    }
    if (sellerId) {
      params.sellerId = sellerId
    }
    return axios
      .get(paths.PURCHASE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((response: AxiosResponse<Paginated<Purchase>>) => {
        const result = response.data
        return {
          items: result.items,
          count: result.count,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  deletePendingPurchase(purchaseId: string, token: string) {
    return axios
      .post(
        paths.deletePendingPurchase(purchaseId),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  //TODO: Add type
  fetchAdminPurchases(token: string, page: number) {
    return axios
      .get(`${paths.ADMIN_PURCHASES}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          purchases: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  deletePurchase(purchaseId: string, token: string) {
    return axios
      .delete(paths.deletePurchaseById(purchaseId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  approvePurchase(token: string, purchaseId: string) {
    return axios
      .post(paths.approvePurchaseById(purchaseId), purchaseId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  rejectPurchase(token: string, purchaseId: string) {
    return axios
      .post(paths.rejectPurchaseById(purchaseId), purchaseId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default PurchaseService
