import ConfigInterface, { LandingConfigInterface } from './interfaces/config.interface'

const API_URL = 'https://api.staging.spotted.com.uy'
// const API_URL = 'http://localhost:8080'

const WEB_URL = 'https://www.staging.spotted.com.uy'

const USER_CARTS_DATABASE_URL = 'https://spotted-staging-default-rtdb.firebaseio.com/'

const GOOGLE_CLIENT_ID = '141526348408-8b9u5ovgflrr2d6rdnm9jhr39240imdj.apps.googleusercontent.com'
const GOOGLE_MAPS_API_KEY = 'AIzaSyAVX0Zit5-IYlpAWB31nUrZK3V8PpykTqM'

const LAUNCHDARKLY_CLIENT_SIDE_ID = '65181825ffe5a212c190a228'

const PRINT_PHOTOGRAPH_REQUEST_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeJiG21xP4DhMUn77lHRPDnkn9vBFYcNGmH5YnPHvhqJu16Yw/viewform'

const USER_EVENT_PHOTOGRAPHS__REQUEST_FORM = 'https://forms.gle/SEvXiYRVGsMjswD56'

const EVENT_REQUEST_FORM = 'https://forms.gle/eYJVip5Ru7UFSs4V9'

const STRIPE_PAYMENT_URL = 'https://buy.stripe.com/00geWNcJm90F02Q001'

const DATE_LOCALE = 'es'

const PERMANENT_USER_UPLOADS_BUCKET = 'https://staging-user-uploads-perm.s3.amazonaws.com'

const MERCADOPAGO_CLIENT_ID = 'unknown'
const MERCADOPAGO_RESPONSE_TYPE = 'code'
const MERCADOPAGO_PLATFORM_ID = 'mp'
const MERCADOPAGO_REDIRECT_URI = 'https://localhost:8080/my-account'
const WHATSAPP_NUMBER = '59894634746'
const LANDING_CONFIG: LandingConfigInterface = {
  FEATURED_ACTIVITY_1_EN: {
    activityId: 'f5cbe827-f1a4-49da-8479-8dbbd10a3f14',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-AthleticsDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-AthleticsMobile.png',
  },
  FEATURED_ACTIVITY_1_ES: {
    activityId: 'f5cbe827-f1a4-49da-8479-8dbbd10a3f14',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-AtletismosDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-AtletismosMobile.png',
  },
  FEATURED_ACTIVITY_2: {
    activityId: '8e5d586f-3492-45a7-a9fa-de9e3eaf79c4',
    desktopAssetName: 'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-SurfDesktop.png',
    mobileAssetName: 'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-SurfMobile.png',
  },
  FEATURED_ACTIVITY_3: {
    activityId: '08cfa315-1ba4-493f-928b-37364790e144',
    desktopAssetName: 'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-KiteDesktop.png',
    mobileAssetName: 'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-KiteMobile.png',
  },
  FEATURED_ACTIVITY_4: {
    activityId: '2e07899b-2a88-45d5-ac38-badd07540bfb',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-RugbyDesktop.png',
    mobileAssetName: 'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-RugbyMobile.png',
  },
  FEATURED_ACTIVITY_5: {
    activityId: '',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-MotocrossDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/staging/Landing-MotocrossMobile.png',
  },
  OTHER_FEATURED_ACTIVITIES: [
    {
      activityId: 'f34e738e-5d30-40b4-871d-d9bd9c86f63b',
      name: 'Cycling',
    },
    {
      activityId: '27137e88-a8e6-490f-b275-9e3b9efbe798ci',
      name: 'Football',
    },
    {
      activityId: '',
      name: 'Motoring',
    },
    {
      activityId: '',
      name: 'Crossfit',
    },
    {
      activityId: '5e01c904-c27b-4fc0-a2d5-26ed8657d8fc',
      name: 'Basketball',
    },
    {
      activityId: 'ae14f175-e557-4015-8deb-4aadcb809a2d',
      name: 'Swimming',
    },
  ],
}

const local: ConfigInterface = {
  API_URL,
  WEB_URL,
  DATE_LOCALE,
  GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_API_KEY,
  WHATSAPP_CONTACT_US_NUMBER: WHATSAPP_NUMBER,
  MERCADOPAGO_CLIENT_ID,
  MERCADOPAGO_RESPONSE_TYPE,
  MERCADOPAGO_PLATFORM_ID,
  MERCADOPAGO_REDIRECT_URI,
  LANDING_CONFIG,
  USER_CARTS_DATABASE_URL,
  LAUNCHDARKLY_CLIENT_SIDE_ID,
  PRINT_PHOTOGRAPH_REQUEST_FORM_URL,
  USER_EVENT_PHOTOGRAPHS__REQUEST_FORM,
  EVENT_REQUEST_FORM,
  STRIPE_PAYMENT_URL,
  PERMANENT_USER_UPLOADS_BUCKET,
}

export default local
