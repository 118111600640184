import { makeAutoObservable, runInAction } from 'mobx'
import AuthService from '../services/AuthService'
import { User } from '../shared/models/User'
import { isNil } from 'lodash'
import * as paths from '../routing/Paths'
import { CredentialResponse } from '@react-oauth/google'
import LoginResponse from '../services/Interfaces/Auth/LoginResponse'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import UserInterface from 'services/Interfaces/User/User.interface'

const DefaultAlbumsPerPage = 9

const LocalStorageKeys = {
  Token: 'token',
  LoggedUser: 'loggedUser',
}

class AuthStore {
  private token: string | null
  public error: string | null
  private loggedUser: User | null
  public redirectPath = paths.FEED_ALBUMS
  public isLoading: boolean
  public isLoadingRequestPhotographerRole: boolean
  public isLoadingOnboardingRedirection: boolean
  public page: number
  public albumsPerPage: number
  public pageCount: number
  public randomUuid: string | null
  public recognitionImageUrl: string | undefined
  constructor(private readonly authService: AuthService) {
    this.token = null
    this.error = null
    this.loggedUser = null
    this.randomUuid = null
    this.recognitionImageUrl = undefined
    this.page = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.pageCount = 1
    makeAutoObservable(this)
    this.checkAuthState()
  }

  get isMercadoPagoAccountSync() {
    return this.loggedUser?.mercadoPagoVerified
  }

  get isVerifiedUser() {
    return this.loggedUser?.isVerified
  }

  getLoggedUser(): User {
    if (!isNil(this.loggedUser)) {
      return this.loggedUser
    } else {
      throw new Error('you must login to perform this action')
    }
  }

  getToken(): string {
    if (this.isAuthenticated()) {
      return <string>this.token
    } else {
      throw new Error('Debes iniciar sesión.')
    }
  }

  generateRandomUuid() {
    this.randomUuid = uuidv4()
  }

  changeRecognitionImage(url: string) {
    this.recognitionImageUrl = url
  }

  clearErrors() {
    this.error = null
  }

  logout() {
    this.isLoading = false
    this.loggedUser = null
    this.token = null
    this.randomUuid = null
    this.recognitionImageUrl = undefined
    localStorage.removeItem(LocalStorageKeys.Token)
    localStorage.removeItem(LocalStorageKeys.LoggedUser)
  }

  isAuthenticated() {
    return this.token != null
  }

  checkAuthState() {
    const token = localStorage.getItem(LocalStorageKeys.Token)
    const loggedUserString = localStorage.getItem(LocalStorageKeys.LoggedUser)
    if (isNil(token) || isNil(loggedUserString)) {
      this.logout()
    } else {
      const loggedUser = User.init(JSON.parse(loggedUserString))
      runInAction(() => {
        this.token = token
        this.loggedUser = loggedUser
      })
      this.getUser()
    }
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }

  startLoadingRequestPhotographerRole() {
    this.isLoadingRequestPhotographerRole = true
  }

  stopLoadingRequestPhotographerRole() {
    this.isLoadingRequestPhotographerRole = false
  }

  startLoadingOnboardingRedirection() {
    this.isLoadingOnboardingRedirection = true
  }

  stopLoadingOnboardingRedirection() {
    this.isLoadingOnboardingRedirection = false
  }

  //ASYNC METHODS

  async login(email: string, password: string) {
    try {
      this.startLoading()
      const loginPayload = await this.authService.login(email.toLowerCase(), password)

      this.handleLogin(loginPayload)
    } catch (e: any) {
      runInAction(() => {
        this.logout()
        this.stopLoading()
      })
    }
  }
  async loginOnBehalf(adminEmail: string, password: string, clientEmail: string) {
    try {
      this.startLoading()
      const loginPayload = await this.authService.loginOnBehalf(
        adminEmail.toLowerCase(),
        password,
        clientEmail.toLowerCase()
      )

      this.handleLogin(loginPayload)
    } catch (e: any) {
      runInAction(() => {
        this.logout()
        this.stopLoading()
      })
    }
  }

  async register(user: User) {
    this.startLoading()
    try {
      const loginPayload = await this.authService.register(user)
      this.handleLogin(loginPayload)
    } catch (e: any) {
      runInAction(() => {
        this.stopLoading()
      })
      throw e
    }
  }

  async googleAuth(credentialResponse: CredentialResponse, countryCode?: string): Promise<void> {
    try {
      this.startLoading()
      const loginPayload = await this.authService.googleAuth(credentialResponse, countryCode)
      this.handleLogin(loginPayload)
    } catch (e: any) {
      runInAction(() => {
        this.logout()
        this.stopLoading()
      })
    }
  }

  async getUser() {
    this.startLoading()
    try {
      if (!isNil(this.token)) {
        const user = await this.authService.fetchProfile(this.token)

        localStorage.setItem('loggedUser', JSON.stringify(user))

        runInAction(() => {
          this.loggedUser = User.init(user)
          this.stopLoading()
        })
      }
    } catch (e: any) {
      this.error = e
      this.stopLoading()
    }
  }

  async requestEmailVerification(): Promise<void> {
    this.startLoading()
    try {
      if (this.isAuthenticated()) {
        await this.authService.requestEmailVerification(this.token!, this.loggedUser!.id)
      }
      this.stopLoading()
    } catch (e: any) {
      this.error = e
      this.stopLoading()
    }
  }

  async requestPhotographerRole(): Promise<UserInterface | undefined> {
    this.startLoadingRequestPhotographerRole()
    try {
      if (this.isAuthenticated()) {
        const updatedUser = await this.authService.requestPhotographerRole(
          this.token!,
          this.loggedUser!.id
        )

        runInAction(() => {
          if (!this.loggedUser?.isPhotographer) {
            this.loggedUser = User.init(updatedUser)
            localStorage.setItem('loggedUser', JSON.stringify(updatedUser))
          }
        })
        this.stopLoadingRequestPhotographerRole()
        return updatedUser
      }
    } catch (e: any) {
      this.error = e
      this.stopLoading()
    }
  }

  async getOnboardingUrl(): Promise<string | undefined> {
    this.startLoadingOnboardingRedirection()
    try {
      if (this.isAuthenticated()) {
        const url = await this.authService.getStripeOnboardingUrl(this.token!, this.loggedUser!.id)

        this.stopLoadingOnboardingRedirection()
        return url
      }
    } catch (e: any) {
      this.error = e
      this.stopLoadingOnboardingRedirection()
    }
  }

  async getLoginUrl(): Promise<string | undefined> {
    this.startLoading()
    try {
      if (this.isAuthenticated()) {
        const url = await this.authService.getStripeLoginUrl(this.token!, this.loggedUser!.id)

        this.stopLoading()
        return url
      }
    } catch (e: any) {
      this.error = e
      this.stopLoading()
    }
  }

  async requestMercadopagoCredentials(code: string): Promise<boolean> {
    this.startLoading()
    this.error = null

    try {
      if (this.isAuthenticated()) {
        const user = await this.authService.requestMercadoPagoCredentials(this.token!, code)

        runInAction(() => {
          this.loggedUser = User.init(user)
          localStorage.setItem('loggedUser', JSON.stringify(user))
          this.stopLoading()
        })
        return true
      } else {
        return false
      }
    } catch (e: any) {
      this.error = e
      this.stopLoading()
      return false
    }
  }

  private handleLogin(payload: LoginResponse) {
    // Set the token and logged user in local storage
    const { accessToken, loggedUser } = payload
    localStorage.setItem('token', accessToken)
    localStorage.setItem('loggedUser', JSON.stringify(loggedUser))

    runInAction(() => {
      this.randomUuid = null
      this.recognitionImageUrl = undefined
      this.loggedUser = User.init(loggedUser)
      this.token = accessToken
      this.stopLoading()
    })
  }

  async verifyUser(token: string) {
    this.startLoading()
    try {
      const verifyUserResponse = await this.authService.verifyUser(token)

      runInAction(() => {
        this.stopLoading()
      })

      if (verifyUserResponse.success) {
        toast.success('User verified successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        return true
      } else {
        toast.error('Failed to verify user. Try again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        return false
      }
    } catch (e: any) {
      this.error = e
      this.stopLoading()
      return false
    }
  }
}

export default AuthStore
