import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import ContactUsRequest from './Interfaces/ContactUs/ConctactUsRequest'

class ContactUsService {
  sendForm(formData: ContactUsRequest) {
    return axios
      .post(paths.CONTACT_US, formData)
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default ContactUsService
