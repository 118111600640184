import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EventDetailsStore from '../EventDetailsStore'

import { isNil } from 'lodash'
import SearchBarDropdown, {
  DropdownOption,
} from 'components/UI/SearchBar/components/SearchBarDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import bibIcon from '../../../../assets/icons/v2/bibIcon.svg'
import selfieIcon from '../../../../assets/icons/v2/selfieIcon.svg'
import { faCheck, faStar } from '@fortawesome/free-solid-svg-icons'

type EventDetailSearchBarProps = {
  eventDetailsStore: EventDetailsStore
  handleOpenUploadSelfieModal: () => void
  handleOpenFilterByTagModal: () => void
  handleSearchByActivity: () => void
  searchInProgress: boolean
}

const EventDetailSearchBar = ({
  eventDetailsStore,
  handleOpenUploadSelfieModal,
  handleOpenFilterByTagModal,
  handleSearchByActivity,
  searchInProgress,
}: EventDetailSearchBarProps) => {
  const { t } = useTranslation()
  const { tagValue, isFilteringByFaceRecognition, event } = eventDetailsStore

  const landingPathsWithoutTagSearchBar = [
    'lanzamiento-renault-kardigan-uy-2024',
    'lego-fun-fest-uy-05-06',
    'nacional-liverpool-intermedio-2024',
    'nacional-liverpool-campenato-intermedio-2024',
  ]

  const eventDate = new Date(eventDetailsStore.event.date)
  const recognitionDateStart = new Date(2023, 10, 1)

  const showFaceRecognitionButton = eventDate > recognitionDateStart //TODO: add validation for processed album to show face rec button

  const handleChangeActivity = (option?: DropdownOption | null) => {
    eventDetailsStore.setActivity(option?.label)
    handleSearchByActivity()
  }

  const availableActivities = eventDetailsStore.isLoadingEventActivities
    ? []
    : eventDetailsStore.activities.map((activity) => ({
        value: activity.id,
        key: activity.id,
        label: activity.name,
      }))

  return (
    <div className="flex justify-between gap-3 lg:pt-2">
      {isNil(event.activityId) && (
        <div className="flex flex-col md:w-1/6">
          <span className="mb-1 font-medium md:mt-2">{t('Sport')}</span>
          <SearchBarDropdown
            options={availableActivities}
            selectedValue={
              !isNil(eventDetailsStore.activity)
                ? {
                    value: eventDetailsStore.activity?.id,
                    label: eventDetailsStore.activity.name,
                  }
                : null
            }
            onChange={handleChangeActivity}
            dropdownLabel={t('Sport')}
            isLoading={eventDetailsStore.isLoadingEventActivities}
          />
        </div>
      )}
      {showFaceRecognitionButton && event.faceRecognitionFilter && (
        <div
          className={`w-full rounded-md border border-lumepic-dark_grey flex flex-col lg:flex-row gap-0 lg:gap-3 items-center justify-start px-2 py-2 cursor-pointer relative ${
            isFilteringByFaceRecognition
              ? 'border-lumepic-light_black bg-lumepic-medium_grey'
              : 'border-lumepic-dark_grey hover:lg:bg-lumepic-semi_medium_grey transition duration-200 bg-bg_details'
          }`}
          onClick={() => handleOpenUploadSelfieModal()}
        >
          {isFilteringByFaceRecognition && (
            <div className="absolute right-1 lg:right-3 top-1 lg:top-3 bg-lumepic-light_black lg:bg-primary_dark flex items-center justify-center rounded-full p-1 border border-black">
              <FontAwesomeIcon
                icon={faCheck}
                className="text-xs lg:text-base text-white lg:text-black"
              />
            </div>
          )}
          <div
            className={`rounded-full flex items-center justify-center p-4 border mb-2 lg:ml-2 bg-primary_dark border-lumepic-light_black`}
          >
            <img src={selfieIcon} alt="selfie" className="w-10" />
          </div>
          <div className="flex flex-col items-start">
            <div className="flex items-center justify-center gap-1">
              <p className={`font-medium text-lumepicSm lg:text-lg mb-0 mt-0.5 text-center`}>
                <FontAwesomeIcon icon={faStar} className="text-primary_dark mr-1" />
                {t('Face recognition')}
              </p>
            </div>
            <p className={`text-sm text-center text-lumepic-grey hidden lg:block`}>
              {t('Upload a selfie or a photo with your face')}
            </p>
          </div>
        </div>
      )}
      {!landingPathsWithoutTagSearchBar.includes(eventDetailsStore.event.landingPath) &&
        event.textFilter && (
          <div
            onClick={() => handleOpenFilterByTagModal()}
            className={`w-full rounded-md border border-lumepic-dark_grey flex flex-col lg:flex-row gap-0 lg:gap-3 items-center justify-start px-2 py-2 cursor-pointer relative ${
              tagValue.value?.length > 0
                ? 'border-lumepic-light_black bg-lumepic-medium_grey'
                : 'border-lumepic-dark_grey hover:lg:bg-lumepic-semi_medium_grey transition duration-200 bg-bg_details'
            }`}
          >
            {tagValue.value?.length > 0 && searchInProgress && (
              <div className="absolute right-1 lg:right-3 top-1 lg:top-3 bg-lumepic-light_black lg:bg-primary_dark flex items-center justify-center rounded-full p-1 border border-black">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-xs lg:text-base text-white lg:text-black"
                />
              </div>
            )}

            <div
              className={`rounded-full flex items-center justify-center p-4 border mb-2 lg:ml-2 bg-primary_dark border-lumepic-light_black`}
            >
              <img src={bibIcon} alt="bib" className="w-10" />
            </div>
            <div className="flex flex-col items-start">
              <p className={`font-medium text-center text-lumepicSm lg:text-lg mb-0 mt-0.5`}>
                {t('Text recognition')}
              </p>
              <p className={`text-sm text-center text-lumepic-grey hidden lg:block`}>
                {t('Upload your BIB or jersey number')}
              </p>
            </div>
          </div>
        )}
    </div>
  )
}

export default observer(EventDetailSearchBar)
