import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import Button from 'components/UI/Button'
import ContactUsForm from './components/ContactUsForm'
import ContactUsStore from './ContactUsStore'
import axios from '../../services/axios'
import Tracker from '../../shared/tracking'
import Spinner from 'components/UI/Spinner'
import StoresContext from '../../providers/storesContext'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import { SetPageTitle } from '../../shared/utility'

const ContactUs = () => {
  SetPageTitle('Contact us')
  const { t } = useTranslation()
  const [contactUsStore] = useState(() => new ContactUsStore())
  const { isLoading } = contactUsStore

  const { authStore, cartStore, locationStore } = useContext(StoresContext)!

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const handleForm = async () => {
    await contactUsStore.send().then((result) => {
      if (result) {
        Tracker.contactUs()
        toast.success(t('Your form has been successfully submitted!'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })

        contactUsStore.reset()
      }
    })
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="w-full overflow-hidden bg-gray-100 ">
      <div className="bg-gradient-to-b from-primary to-primary_light h-96"></div>
      <div className="w-10/12 mx-auto mb-12">
        <div className="w-full p-8 rounded shadow bg-lumepic-black sm:p-12 -mt-80">
          <p className="mb-0 text-3xl font-extrabold leading-7 text-center text-white">
            {t('Contact us')}
          </p>
          <ContactUsForm contactUsStore={contactUsStore} />
          <div className="flex items-center justify-center w-full">
            <Button btnType="PrimaryAction" extraStyle="w-full sm:w-1/2 mt-6" onClick={handleForm}>
              {t('Send')}
            </Button>
          </div>
        </div>
      </div>
      {!authStore.isAuthenticated() && (
        <div className="hidden">
          <GoogleLogin
            size="large"
            width="150px"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      )}
    </div>
  )
}

export default withErrorHandler(observer(ContactUs), axios)
