import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import { isNil } from 'lodash'
import BackButton from 'components/UI/Button/BackButton'
import { useQuery } from 'hooks/useQuery'
import Spinner from 'components/UI/Spinner'
import EventInformation from './components/EventInformation'
import EventDetailsStore from './EventDetailsStore'
import axios from 'services/axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import {
  formatPhotographCountText,
  getFeatureFlagValue,
  getFirstNumberOfPicturesForDiscount,
  getQtyDiscountPercentageEarned,
  isPackagePurchaseEnabledAfterQtyDiscountCalc,
  SetPageTitle,
} from '../../../shared/utility'
import Tracker from '../../../shared/tracking'
import * as paths from '../../../routing/Paths'
import EventDetailSearchBar from './components/EventDetailSearchBar'
import FaceRecognitionModal from 'components/AlbumDetails/components/FaceRecognitionModal'
import { Skeleton } from '@mui/material'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import Album from 'shared/models/Album'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import DetailsPackageBar from 'components/AlbumDetails/components/DetailsPackageBar'
import { Currency } from 'shared/util/Currency'
import { PackageType } from '../../../services/Interfaces/Purchase/PurchaseRequest.interface'
import { TagKeys } from '../../../services/Interfaces/Tag/Tag.interface'
import { toast } from 'react-toastify'
import { FeatureFlags } from 'config/constants/featureFlags'
import EventDetailsContent from './components/EventDetailsContent'
import useHideWhatsappButton from 'hooks/useHideWhatsappButton'
import EventCustomBanner from './components/EventCustomBanner'
import TagFilterModal from 'components/AlbumDetails/components/TagFilterModal'
import EventDetailsFirstRender from './components/FirstRenderFiltersView/EventDetailsFirstRender'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faFilter, faXmark } from '@fortawesome/free-solid-svg-icons'

type EventDetailsParams = {
  landingPath: string
  photographId: string
}

const EventDetails = () => {
  useHideWhatsappButton()
  const { t } = useTranslation()
  const query = useQuery()
  const queryTags = query.getAll('tags')
  const queryTagValue = query.get('tagValue')
  const history = useHistory()
  const { authStore, cartStore, featureFlagsStore } = useContext(StoresContext)!
  const { landingPath } = useParams<EventDetailsParams>()
  const [store] = useState(
    () => new EventDetailsStore(landingPath, authStore, queryTags, queryTagValue || undefined)
  )

  const {
    event,
    photographs,
    isLoadingEventDetails,
    isLoadingEventAlbums,
    isLoadingEventPhotographs,
  } = store

  const initialRender = useRef(true)

  const [notFound, setNotFound] = useState(false)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false)
  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }
  const [selectedInterval, setSelectedInterval] = useState<DropdownOption>(defaultInterval)
  const [showBuyPackageButton, setShowBuyPackageButton] = useState(false)
  const [buyNowPrice, setBuyNowPrice] = useState<number | null>(null)

  const isFilteringByTag = store.tagIds.length === 1

  const packagePhotographs = useMemo(() => store.photographs, [store.photographs])
  const albumIdsInPackage = useMemo(
    () => new Set(packagePhotographs.map((photo) => photo.albumId)),
    [packagePhotographs]
  )
  const albumsInPackage = useMemo(
    () => store.albums.filter((album) => albumIdsInPackage.has(album.id)),
    [albumIdsInPackage, store.albums]
  )
  const ownerIdsInPackage = useMemo(
    () => new Set(albumsInPackage.map((album) => album.owner.id)),
    [albumsInPackage]
  )
  const totalPhotographsPrice = packagePhotographs.reduce((total, photo) => total + photo.price, 0)

  const totalPhotographsPriceAfterDiscounts = useMemo(
    () =>
      !isNil(event.quantityDiscount)
        ? (1 - getQtyDiscountPercentageEarned(event.quantityDiscount, packagePhotographs) / 100) *
          totalPhotographsPrice
        : 0,
    [totalPhotographsPrice, event.quantityDiscount]
  )

  const packagePurchaseEnabledAfterQtyDiscountCalc = useMemo(
    () =>
      isPackagePurchaseEnabledAfterQtyDiscountCalc(
        totalPhotographsPrice,
        totalPhotographsPriceAfterDiscounts,
        event.defaultPackagePrice ?? undefined,
        event.quantityDiscount ?? undefined
      ),
    [totalPhotographsPriceAfterDiscounts, event.defaultPackagePrice]
  )

  const minNumberOfPhotosForQtyDiscount = useMemo(() => {
    if (!isNil(event.quantityDiscount)) {
      return getFirstNumberOfPicturesForDiscount(event.quantityDiscount)
    }
    return null
  }, [event.quantityDiscount])

  const photographBlocksByTimeInterval = PhotographUtils.buildPhotographsBlocksByInterval(
    photographs,
    selectedInterval
  )

  if (!isNil(event?.name)) {
    SetPageTitle(event.name)
  }

  if (notFound) {
    return <Redirect to="/" />
  }
  const goBack = () => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(paths.EVENTS)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        store.fetchEventDetails().then(() => {
          store.fetchEventAlbums().then(() => {
            if (!isNil(queryTagValue) || !store.event.filtersView) {
              setSearchInProgress(true)
              initialRender.current = false
              store.fetchEventPhotographs(true, queryTagValue ?? '')
            }
          })
        })
      } catch (error: any) {
        if (error.message === 'Albums not found') {
          setNotFound(true)
        }
      }
    }
    if (initialRender.current) {
      fetchData()
    }
  }, [landingPath])

  useEffect(() => {
    if (!store.isLoadingEventPhotographs) {
      const index = photographs.findIndex((photograph) => photograph.id === photographToDisplayId)
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (photographs.length - 1 === index && !searchInProgress) {
        store.fetchNextPage()
      }
    }
  }, [photographToDisplayId])

  //PACKAGE PURCHASE

  const handlePackagePurchaseEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.EVENTS_PACKAGE_PURCHASE,
    false
  )

  useEffect(() => {
    if (
      (isFilteringByTag || store.isFilteringByFaceRecognition) &&
      !store.isLoadingEventPhotographs &&
      !isNil(minNumberOfPhotosForQtyDiscount) &&
      packagePhotographs.length >= minNumberOfPhotosForQtyDiscount
    ) {
      if (ownerIdsInPackage.size <= 40 && packagePurchaseEnabledAfterQtyDiscountCalc) {
        const tag = store.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => acc.filter((tag) => tags.some((t) => t.id === tag.id)))
          .find((tag) => tag)

        const isTextTagOrNil = !tag || tag.key === TagKeys.Text

        if (isFilteringByTag && isTextTagOrNil && packagePhotographs.length >= 20) {
          setShowBuyPackageButton(false)
        } else if (!isNil(event.defaultPackagePrice) && handlePackagePurchaseEnabled) {
          setBuyNowPrice(event.defaultPackagePrice)
          setShowBuyPackageButton(true)
        } else if (totalPhotographsPriceAfterDiscounts > 0) {
          setBuyNowPrice(totalPhotographsPriceAfterDiscounts)
          setShowBuyPackageButton(true)
        }
      } else {
        setShowBuyPackageButton(false)
      }
    }
  }, [isFilteringByTag, store.isFilteringByFaceRecognition, store.isLoadingEventPhotographs])

  const handleBuyPackage = (price: number) => {
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      const photographs = store.photographs.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      const appliesPackagePrice = price === event.defaultPackagePrice

      if (appliesPackagePrice) {
        let tagId: string | undefined
        if (isFilteringByTag) {
          tagId = store.tagIds[0]
        }
        if (store.isFilteringByFaceRecognition) {
          const faceTag = store.photographs
            .map((photograph) => photograph.tags)
            .reduce((acc, tags) => {
              return acc.filter((tag) => tags.some((t) => t.id === tag.id))
            })
            .find((tag) => tag.key === TagKeys.Face)
          if (isNil(faceTag)) {
            toast.error(t('Face tag not found'))
          }
          tagId = faceTag!.id
        }
        Tracker.addPackagePaymentInformation(photographs, event)
        cartStore.createPackagePurchase(photographs, PackageType.Tag, tagId).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      } else {
        cartStore.createPackagePurchase(photographs).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      }
    }
  }

  const handleAddPackage = (price: number) => {
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      let tagId: string | undefined
      if (isFilteringByTag) {
        tagId = store.tagIds[0]
      }

      const photographIds = store.photographs.map((photograph) => photograph.id)

      const filteredPhotographs = store.photographs.filter((photograph) =>
        photographIds.includes(photograph.id)
      )

      const filteredPhotographsIds = filteredPhotographs.flatMap((photograph) => photograph.id)

      const itemsInCartIds = filteredPhotographsIds.filter((photographId) =>
        cartStore.alreadyInCart(photographId)
      )

      const notPackageItemsInCartIds = itemsInCartIds.filter(
        (itemInCartId) => !cartStore.photographFromPackageInCart(itemInCartId)
      )

      if (notPackageItemsInCartIds.length > 0) {
        cartStore.removePhotographs(notPackageItemsInCartIds)
      }

      const packageAlreadyInCart = filteredPhotographsIds.every((photographId) =>
        cartStore.alreadyInCart(photographId)
      )

      const appliesPackagePrice = price === event.defaultPackagePrice

      if (!packageAlreadyInCart) {
        if (store.isFilteringByFaceRecognition) {
          const faceTag = store.photographs
            .map((photograph) => photograph.tags)
            .reduce((acc, tags) => {
              return acc.filter((tag) =>
                tags.some((t) => t.id === tag.id || t.taggedUserId == tag.taggedUserId)
              )
            })
            .find((tag) => tag.key === TagKeys.Face)
          if (isNil(faceTag)) {
            toast.error(t('Face tag not found'))
          }
          tagId = faceTag!.id
        }
        Tracker.addPackagePaymentInformation(photographs, event)
        if (appliesPackagePrice) {
          cartStore.addPackage(
            store.photographs,
            event.currency as Currency,
            price,
            PackageType.Tag,
            tagId
          )
        } else {
          for (const photograph of store.photographs) {
            const album = store.albums.find((album) => album.id === photograph.albumId)
            if (!isNil(album)) {
              cartStore.addPhotograph(photograph, album)
            }
          }
        }
      }
    }
  }

  const updateURLWithFilters = () => {
    const searchParams = new URLSearchParams()
    if (!isNil(store.tagValue) && store.tagValue.value.length > 0) {
      searchParams.append('tagValue', store.tagValue.value)
    }
    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    history.replace(updatedURL)
  }

  const filterByTagValue = () => {
    initialRender.current = false
    store.clearPhotographCount()
    store.fetchEventPhotographs(true, store.tagValue.value)
    setShowFilterByTagModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
  }

  const filterByFaceRecognition = () => {
    initialRender.current = false
    store.setTagValue('')
    store.clearPhotographCount()
    store.setIsFilteringByFaceRecognition(true)
    store.fetchEventPhotographs(true, '')
    updateURLWithFilters()
    setSearchInProgress(true)
  }

  const handleSearchByActivity = () => {
    store.fetchEventPhotographs(true, '')
    setSearchInProgress(true)
  }

  const handleClearFilters = () => {
    initialRender.current = false
    store.setTagValue('')
    setShowBuyPackageButton(false)
    store.setIsFilteringByFaceRecognition(false)
    store.setActivity(undefined)
    store.fetchEventPhotographs(true, '')
    store.clearPhotographCount()
    updateURLWithFilters()
    setSearchInProgress(false)
  }

  const findAlbumForPhotograph = (photographId: string): Album | undefined => {
    const photographIndex = store.photographs.findIndex((photo) => photo.id === photographId)
    if (photographIndex === -1) {
      return undefined
    }

    const albumId = store.photographs[photographIndex].albumId

    const album = store.albums.find((album) => album.id === albumId)
    return album
  }

  const loadingSpinner = (
    <Spinner divStyles="flex items-center justify-center mt-5 items-center" size={40} />
  )
  if (cartStore.isLoading) {
    return <Spinner size={40} />
  }

  return (
    <div className="mt-3 mb-24 overflow-hidden">
      <div className="container mx-auto">
        <div className="mb-2 ml-2">
          <BackButton onClick={goBack} />
        </div>
        <div className="rounded-md">
          <EventInformation
            isLoading={isLoadingEventDetails}
            event={event}
            albumsCount={store.albumsCount}
            photographCount={store.photographCount}
            filtersView={initialRender.current && store.event.filtersView}
          />
        </div>
        {initialRender.current && store.event.filtersView ? (
          <EventDetailsFirstRender
            handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
            handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
            filterByFaceRecognition={filterByFaceRecognition}
            filterByTagValue={filterByTagValue}
            handleClearFilters={handleClearFilters}
            store={store}
          />
        ) : (
          <>
            {!isLoadingEventDetails ? (
              <>
                <div className="border-b-4 border-primary_dark bg-white flex items-center gap-1.5 p-1.5 rounded-t-md mt-4">
                  <FontAwesomeIcon
                    icon={faFilter}
                    className="text-lumepic-grey lg:text-lg lg:ml-1"
                  />
                  <header className="mt-px lg:text-lg">{t('Filters')}</header>
                </div>
                <div className="bg-white w-full">
                  {searchInProgress &&
                    (store.tagValue.value?.length > 0 || store.isFilteringByFaceRecognition) &&
                    !store.isLoadingEventPhotographs &&
                    store.photographCount > 0 && (
                      <span className="text-lumepic-black md:text-lg align-center md:font-semibold ml-2 pt-2">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-lumepic-success_dark_green mr-1 mt-2 lg:mt-3"
                        />
                        {t('You’ve appeared in') +
                          ' ' +
                          formatPhotographCountText(store.photographCount, t)}
                        !
                      </span>
                    )}
                </div>
                <div className="flex flex-col gap-3 mb-2 px-1.5 lg:px-2.5 pb-2.5 pt-1.5 bg-white">
                  <EventDetailSearchBar
                    eventDetailsStore={store}
                    handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
                    handleSearchByActivity={handleSearchByActivity}
                    handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
                    searchInProgress={searchInProgress}
                  />
                  <div className="flex w-full justify-start">
                    {((store.tagValue.value?.length > 0 && searchInProgress) ||
                      store.isFilteringByFaceRecognition ||
                      store.activity) && (
                      <button
                        onClick={() => {
                          handleClearFilters()
                        }}
                        className="flex gap-2 items-center justify-center px-2 py-1 rounded-md bg-lumepic-light_black hover:lg:scale-105 transition duration-200"
                      >
                        <FontAwesomeIcon icon={faXmark} className="text-white" />
                        <span className="text-sm text-white">{t('Delete filters')}</span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-2 p-2 bg-white rounded-md">
                <Skeleton variant="rounded" width={50} height={20} />
                <div className="flex items-center gap-2">
                  <Skeleton variant="rounded" width={200} height={50} />
                  <div className="w-20 h-8 rounded-md bg-primary opacity-60" />
                </div>
              </div>
            )}

            <EventDetailsContent
              store={store}
              photographBlocksByTimeInterval={photographBlocksByTimeInterval}
              findAlbumForPhotograph={findAlbumForPhotograph}
              searchInProgress={searchInProgress}
            />
            {(isLoadingEventAlbums || isLoadingEventPhotographs) && store.page === 1 && (
              <SkeletonLoaderPhotographCard />
            )}
            {(isLoadingEventAlbums || isLoadingEventPhotographs) &&
              store.page > 1 &&
              loadingSpinner}
          </>
        )}
      </div>

      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={store}
        onSuccess={() => filterByFaceRecognition()}
      />

      <TagFilterModal
        opened={showFilterByTagModal}
        onCancel={() => setShowFilterByTagModal(false)}
        store={store}
        onSuccess={() => filterByTagValue()}
        handleClearFilters={() => handleClearFilters()}
      />
      {showBuyPackageButton && !isNil(buyNowPrice) && (
        <DetailsPackageBar
          handleBuyPackage={() => handleBuyPackage(buyNowPrice)}
          handleAddPackage={() => handleAddPackage(buyNowPrice)}
          photographBlocksByInterval={photographBlocksByTimeInterval}
          price={buyNowPrice}
          event={event}
          isFilteringByFaceRecognition={store.isFilteringByFaceRecognition}
        />
      )}
      {!isNil(event.customBannerSrc) && (
        <EventCustomBanner
          bannerUrl={event.customBannerSrc}
          eventId={event.id}
          eventName={event.name}
        />
      )}
    </div>
  )
}
export default withErrorHandler(observer(EventDetails), axios)
