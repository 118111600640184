import { faFolder, faImages, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinearProgress } from '@mui/material'
import Button from 'components/UI/Button'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import PackageLabel from 'components/UI/PackageLabel/PackageLabel'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CartLine } from 'shared/models/Cart'
import { Photograph } from 'shared/models/Photograph'

type PackageLineProps = {
  lines: CartLine[]
}

const PackageLine = ({ lines }: PackageLineProps) => {
  const { cartStore } = useContext(storesContext)!
  const history = useHistory()
  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)
  const [clearTimeoutTool, setClearTimeoutTool] = useState(0)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedPackagePhotos, setSelectedPackagePhotos] = useState<Photograph[]>([])
  const [showPackageImagesModal, setShowPackageImagesModal] = useState(false)

  const photographs = lines.map((line) => line.photograph)
  const album = cartStore.albums.find((album) => album.id === photographs[0].albumId)
  const currency = album?.event.currency?.toUpperCase() || album?.currency?.toUpperCase() || 'UYU'

  useEffect(() => {
    const interval = 20
    const steps = 115 / (4000 / interval)
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.max(0, prevProgress - steps))
    }, interval)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const goToAlbum = () => {
    history.push(`/feed/albums/${album?.id}`)
  }

  const goToEvent = () => {
    history.push(`/events/${album?.event.landingPath}`)
  }

  const viewPackagePhotos = () => {
    setShowPackageImagesModal(true)
    setSelectedPackagePhotos(photographs)
  }

  const removePackageFromCart = () => {
    const photographIds = photographs.map((photo) => photo.id)
    cartStore.removePhotographs(photographIds)
    setDeleteConfirmation(false)
  }

  let deletePictureTimeout
  const deleteItem = () => {
    setProgress(100)
    setDeleteConfirmation(true)
    deletePictureTimeout = setTimeout(removePackageFromCart, 4000)
    setClearTimeoutTool(deletePictureTimeout)
  }

  const undoDelete = () => {
    if (clearTimeoutTool !== null) {
      clearTimeout(clearTimeoutTool)
      setClearTimeoutTool(0)
    }
    setDeleteConfirmation(false)
  }

  return (
    <>
      {!deleteConfirmation && (
        <div className="w-full flex py-2.5 border-b relative bg-white border-b-lg md:rounded-b-lg">
          <div className="flex items-center md:w-1/2 cursor-zoom-in">
            <FontAwesomeIcon icon={faFolder} className="hidden md:text-2xl md:mr-2" />
            <div className="grid grid-cols-2 gap-1 h-full px-2 md:px-0 md:pl-1">
              {lines.map((line) => {
                return (
                  <img
                    key={line.photograph.id}
                    src={line.photograph.url}
                    alt={line.photograph.id}
                    onClick={viewPackagePhotos}
                    className="object-cover object-center w-full max-h-48 cursor-zoom-in md:rounded-md"
                  />
                )
              })}
            </div>
          </div>

          <div className="w-full flex flex-col justify-between ml-2 md:ml-6 md:mb-3">
            <div className="flex flex-col items-end font-semibold pt-2 pr-2 md:pl-2">
              <div className="w-full flex-col md:flex md:items-end">
                <div className="flex flex-col items-end">
                  <span className="font-light text-sm justify-end text-lumepic-grey line-through">
                    {currency} {photographs[0].price * photographs.length}
                  </span>
                  {currency} {lines[0].packagePrice}
                </div>
                <div className="md:w-1/2">
                  <PackageLabel />
                </div>
              </div>
            </div>

            {/* LINE INFO & ACTIONS */}
            <div className="flex flex-col items-start justify-end gap-2 mt-4">
              <div
                onClick={album?.event.landingPath ? goToEvent : goToAlbum}
                className="flex items-center gap-2 cursor-pointer"
              >
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faImages} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">
                  {album?.event.landingPath ? t('Go to event') : t('Go to album')}
                </span>
              </div>
              <div
                onClick={() => deleteItem()}
                className="ml-0.5 flex gap-2 items-center cursor-pointer"
              >
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">{t('Delete')}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteConfirmation && (
        <div className="w-full my-3 bg-bg_details">
          <LinearProgress variant="determinate" color="primary" value={progress} />
          <div className="flex flex-col items-center justify-center gap-2 p-5 min-h-20 max-h-48">
            <p className="mb-0 font-medium">{t('Picture deleted')}</p>
            <Button btnType="TertiaryAction" onClick={() => undoDelete()}>
              {t('Undo action')}
            </Button>
          </div>
        </div>
      )}
      <Modal
        onCancel={() => setShowPackageImagesModal(false)}
        opened={showPackageImagesModal}
        modalSize={ModalSize.MEDIUM}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        displayCloseIcon={false}
      >
        <div
          className={`flex flex-col p-3 ${
            selectedPackagePhotos.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-3'
          } gap-4 md:p-4 overflow-y-scroll`}
          style={{ backdropFilter: 'blur(8px)' }}
        >
          {selectedPackagePhotos.map((photo) => (
            <div key={photo.id} className="flex justify-center items-center">
              <img src={photo.url} alt={photo.id} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default observer(PackageLine)
