import { makeAutoObservable, runInAction } from 'mobx'
import EventStore from '../../../stores/EventStore'
import ActivityStore from '../../../stores/ActivityStore'
import LocationStore from '../../../stores/LocationStore'
import { Location } from '../../../shared/models/Location'
import { Activity } from '../../../shared/models/Activity'
import { Event } from '../../../shared/models/Event'
import { isNil } from 'lodash'

class SearchBarStore {
  public dateRange: [Date | null, Date | null]
  public countryCode: string
  public location?: Location
  public activity?: Activity
  public event?: Event
  public isLoading: boolean
  public error: any
  constructor(
    private readonly eventStore: EventStore,
    private readonly activityStore: ActivityStore,
    private readonly locationStore: LocationStore,
    initialState?: {
      dateRange?: [Date | null, Date | null]
      countryCode?: string
      locationId?: string
      activityId?: string
      eventId?: string
    }
  ) {
    this.reset()
    this.countryCode = locationStore.userLocation.country.code
    if (!isNil(initialState?.countryCode) && this.countryCode !== initialState?.countryCode) {
      this.countryCode = initialState!.countryCode!
      this.locationStore.fetchLocations(initialState?.countryCode).then(() => {
        this.location = this.locationStore.getLocation(initialState?.locationId)
      })
    }
    if (!isNil(initialState?.locationId && this.location?.id !== initialState?.locationId)) {
      this.location = this.locationStore.getLocation(initialState?.locationId)
    }
    if (!isNil(initialState?.activityId) && this.activity?.id !== initialState?.activityId) {
      this.activity = this.activityStore.getActivity(initialState?.activityId)
    }
    if (!isNil(initialState?.eventId) && this.event?.id !== initialState?.eventId) {
      this.event = this.eventStore.getEvent(initialState?.eventId)
    }
    if (!isNil(initialState?.dateRange) && this.dateRange !== initialState?.dateRange) {
      this.dateRange = initialState!.dateRange!
    }
    makeAutoObservable(this)
  }

  reset() {
    this.isLoading = false
    this.error = null
    this.dateRange = [null, null]
    this.location = undefined
    this.activity = undefined
    this.event = undefined
  }

  setDateRange(range: [Date, Date]) {
    runInAction(() => {
      this.dateRange = range
    })
  }

  setLocation(location?: Location) {
    runInAction(() => {
      this.location = location
    })
  }

  setEvent(event?: Event) {
    runInAction(() => {
      this.event = event
    })
  }

  setCountryCode(countryCode: string) {
    runInAction(() => {
      this.countryCode = countryCode
    })
  }

  setActivity(activity?: Activity) {
    runInAction(() => {
      this.activity = activity
    })
  }
}

export default SearchBarStore
