import { useEffect } from 'react'
import { isNil } from 'lodash'

const useHideWhatsappButton = () => {
  useEffect(() => {
    // Hide the WhatsApp button when the component is mounted
    const whatsappButtonElement = document.getElementById('WhatsappButton')
    if (!isNil(whatsappButtonElement)) {
      whatsappButtonElement.style.visibility = 'hidden'
    }

    // Show the WhatsApp button when the component is unmounted
    return () => {
      if (!isNil(whatsappButtonElement)) {
        whatsappButtonElement.style.visibility = 'visible'
      }
    }
  }, [])
}

export default useHideWhatsappButton
