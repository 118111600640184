import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

type InputInformationProps = {
  informationText: string
}
const InputInformation = ({ informationText }: InputInformationProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex sm:items-center gap-1 mt-1.5 mb-1.5">
      <FontAwesomeIcon
        icon={faCircleInfo}
        className="text-sm text-lumepic-light_black ml-0.5 opacity-80"
      />
      <span className="text-lumepic-black text-xs ml-0.5 sm:ml-1">{t(informationText)}</span>
    </div>
  )
}

export default InputInformation
