export const GET_EVENTS = '/events'

const prefix = '/events'
export const ADMIN_EVENTS = `${prefix}/admin`
export const getEventsByCountryCode = (countryCode: string) => {
  return `${prefix}/countryCode/${countryCode}`
}
export const getEventPhotographs = (eventId: string) => {
  return `${prefix}/${eventId}/photographs`
}
export const deleteEventById = (eventId: string) => {
  return `${prefix}/${eventId}`
}
export const getEventDetails = (eventId: string) => {
  return `${prefix}/${eventId}`
}
export const getEventActivities = (eventId: string) => {
  return `${prefix}/${eventId}/activities`
}
export const updateEventById = (eventId: string) => {
  return `${prefix}/${eventId}`
}
