import React, { useContext, useMemo, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'
import DetailsPhotographCard from '../../AlbumDetails/components/DetailsPhotographCard'
import { Photograph } from 'shared/models/Photograph'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import Tracker from 'shared/tracking'
import AlbumDetailsStore from '../../AlbumDetails/AlbumDetailsStore'
import storesContext from 'providers/storesContext'
import PackagePurchaseInfoModal from 'components/UI/PackagePurchaseInfoModal/PackagePurchaseInfoModal'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import { PackageType } from 'services/Interfaces/Purchase/PurchaseRequest.interface'
import { FeatureFlags } from 'config/constants/featureFlags'
import { observer } from 'mobx-react'
import Masonry from '@mui/lab/Masonry'
import GallerySinglePhoto from '../DetailsPhotoGallery/GallerySinglePhoto'
import { getFeatureFlagValue } from 'shared/utility'

type IntervalAccordionProps = {
  index: string
  unknownPhotographKey: string
  setPhotograph: (photographId: string) => void
  downloadFreePhotograph: (photographId: string) => void
  photographBlockInterval: Photograph[] | undefined
  isInBurstMode: boolean
  albumDetailsStore?: AlbumDetailsStore
  eventDetailsStore?: EventDetailsStore
  allPhotographsFromSameDay: boolean
}

const IntervalAccordion = ({
  index,
  unknownPhotographKey,
  setPhotograph,
  downloadFreePhotograph,
  photographBlockInterval,
  isInBurstMode,
  albumDetailsStore,
  eventDetailsStore,
  allPhotographsFromSameDay,
}: IntervalAccordionProps) => {
  const { t } = useTranslation()
  const { cartStore, featureFlagsStore } = useContext(storesContext)!

  const [isExpanded, setIsExpanded] = useState(true)

  const burstPurchaseFeatureFlag = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.BUY_BURSTS_PACKAGE,
    false
  )

  const [openBurstPurchaseInfoModal, setOpenBurstPurchaseInfoModal] = useState(false)

  const handleOpenBurstPurchaseInfoModal = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (isExpanded) {
      event.stopPropagation()
    }
    setOpenBurstPurchaseInfoModal(true)
  }
  const handleCloseBurstPurchaseInfoModal = () => {
    setOpenBurstPurchaseInfoModal(false)
  }

  const handleBuyBurstClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    if (displayPurchaseBurstButton) {
      const photographs = photographBlockInterval!.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      Tracker.addPackagePaymentInformation(photographs, albumDetailsStore?.album)
      if (
        !isNil(photographBlockInterval) &&
        !isNil(albumDetailsStore?.album.currency) &&
        !isNil(albumDetailsStore)
      ) {
        cartStore.createPackagePurchase(photographs, PackageType.Burst).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      }
    }
  }

  const handleAddBurstClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    if (displayPurchaseBurstButton && !isNil(albumDetailsStore)) {
      const photographs = photographBlockInterval!.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      const photographIds = photographs.map((photograph) => photograph.id)

      const itemsInCartIds = photographIds.filter((photographId) =>
        cartStore.alreadyInCart(photographId)
      )

      if (itemsInCartIds.length > 0) {
        cartStore.removePhotographs(itemsInCartIds)
      }
      Tracker.addPackagePaymentInformation(photographs, albumDetailsStore?.album)
      if (
        !isNil(photographBlockInterval) &&
        !isNil(albumDetailsStore?.album.currency) &&
        !isNil(albumDetailsStore)
      ) {
        cartStore.addPackage(
          photographBlockInterval!,
          albumDetailsStore.album.currency!,
          albumDetailsStore.album.defaultPackagePrice,
          PackageType.Burst
        )
      }
    }
  }

  const displayPurchaseBurstButton: boolean =
    !isNil(eventDetailsStore?.event.defaultPackagePrice) ||
    (!isNil(albumDetailsStore?.album.defaultPackagePrice) &&
      !isNil(photographBlockInterval) &&
      photographBlockInterval.map((p) => p.price).reduce((v, a) => v + a, 0) >
        (eventDetailsStore?.event.defaultPackagePrice ||
          albumDetailsStore?.album.defaultPackagePrice ||
          0))

  const album = useMemo(() => {
    if (albumDetailsStore && albumDetailsStore.album) {
      return albumDetailsStore.album
    }
    if (eventDetailsStore && eventDetailsStore.albums) {
      const albumIds = new Set(photographBlockInterval?.map((photograph) => photograph.albumId))
      const album = eventDetailsStore.albums.find((album) => albumIds.has(album.id))
      if (album) {
        return album
      }
    }
    return undefined
  }, [albumDetailsStore, eventDetailsStore, photographBlockInterval])

  if (!album) {
    return null
  }

  const currency = !isNil(eventDetailsStore)
    ? eventDetailsStore.event.currency
    : !isNil(albumDetailsStore)
    ? albumDetailsStore.album.currency
    : undefined
  const defaultPackagePrice =
    !isNil(eventDetailsStore) && !isNil(eventDetailsStore.event.defaultPackagePrice)
      ? eventDetailsStore?.event.defaultPackagePrice
      : !isNil(albumDetailsStore) && !isNil(albumDetailsStore.album.defaultPackagePrice)
      ? albumDetailsStore.album.defaultPackagePrice
      : undefined

  const baseToggleClasses = `w-full flex justify-between cursor-pointer mb-2 items-center p-2 ${
    isExpanded ? 'rounded-t-md' : 'rounded-md'
  }`

  const sameDayPhotosToggleClasses = `${baseToggleClasses} bg-white border-b-4 border-primary`

  const differentDayToggleClasses = `${baseToggleClasses} bg-lumepic-light_grey hover:bg-lumepic-semi_grey`

  return (
    <>
      <Accordion defaultActiveKey={index} className="my-3 px-2">
        <Card className="my-2 border-0">
          <Card.Header className={`p-0 border-0  ${!isExpanded && 'pb-1'}`}>
            {index !== unknownPhotographKey && (
              <CustomToggleFull
                eventKey={index}
                opened={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
                extraClassNames={`${
                  allPhotographsFromSameDay ? sameDayPhotosToggleClasses : differentDayToggleClasses
                }`}
                plusToggle
              >
                <div className="w-full flex gap-2.5 items-center">
                  <h5 className="mb-0 text-lg font-medium">
                    {index} {!isInBurstMode && 'hs'}
                  </h5>

                  {burstPurchaseFeatureFlag && isInBurstMode && displayPurchaseBurstButton && (
                    <div className="relative">
                      <span className="absolute right-0 z-20 flex w-2 h-2">
                        <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-lumepic-dark_grey"></span>
                        <span className="relative inline-flex w-2 h-2 rounded-full bg-lumepic-grey opacity-70"></span>
                      </span>
                      <span
                        onClick={(event) => handleOpenBurstPurchaseInfoModal(event)}
                        className="cursor-pointer border-b-2 bg-primary rounded-md text-sm pl-2 pr-3 py-0.5 animate-bounce border-lumepic-dark_grey text-black font-regular"
                      >
                        {`${
                          t('Buy all') + (' (' + photographBlockInterval?.length + ') ') + t('for')
                        } ${currency?.toUpperCase()} ${defaultPackagePrice}`}
                      </span>
                    </div>
                  )}
                </div>
              </CustomToggleFull>
            )}
          </Card.Header>
          {isExpanded && (
            <Card.Body className="p-0">
              <Accordion.Collapse eventKey={index}>
                <div className="w-full min-h-primaryMobile">
                  {!isNil(photographBlockInterval) &&
                  (eventDetailsStore?.event.isFree || albumDetailsStore?.album.isFree) ? (
                    <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={0.5} className="w-full">
                      {photographBlockInterval.map((photograph) => (
                        <GallerySinglePhoto
                          key={photograph.id}
                          photograph={photograph}
                          setPhotograph={setPhotograph}
                          downloadFreePhotograph={downloadFreePhotograph}
                        />
                      ))}
                    </Masonry>
                  ) : (
                    <div className="w-full grid grid-cols-6 gap-2 mt-2 px-1.5">
                      {photographBlockInterval?.map((photograph) => {
                        const album = !isNil(eventDetailsStore)
                          ? eventDetailsStore.albums.find(
                              (album) => album.id === photograph.albumId
                            )
                          : albumDetailsStore?.album
                        return (
                          <DetailsPhotographCard
                            photograph={photograph}
                            photographs={photographBlockInterval}
                            album={album!}
                            eventDetailsStore={eventDetailsStore}
                            albumDetailsStore={albumDetailsStore}
                            setPhotograph={setPhotograph}
                            key={photograph.id}
                            downloadFreePhotograph={downloadFreePhotograph}
                          />
                        )
                      })}
                    </div>
                  )}
                </div>
              </Accordion.Collapse>
            </Card.Body>
          )}
        </Card>
      </Accordion>

      <PackagePurchaseInfoModal
        open={openBurstPurchaseInfoModal}
        onClose={handleCloseBurstPurchaseInfoModal}
        handleBuyClick={handleBuyBurstClick}
        handleAddBurstClick={handleAddBurstClick}
        photographs={photographBlockInterval}
        isBurstPurchase
        isFaceRecognition={false}
        isLoading={cartStore.isLoading}
        album={albumDetailsStore?.album}
        eventName={eventDetailsStore?.event.name}
      />
    </>
  )
}

export default observer(IntervalAccordion)
