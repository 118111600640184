import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import EventDetailsStore from '../EventDetailsStore'
import { formatUtcToLocaleDate } from 'shared/utility'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'
import { PhotographUtils, UnknownPhotographKey } from 'shared/util/photographs.utils'
import IntervalAccordion from 'components/UI/IntervalAccordion/IntervalAccordion'
import { DropdownOption } from 'components/UI/Dropdown/CountryDropdown'
import { observer } from 'mobx-react'

type AccordionsByDayProps = {
  store: EventDetailsStore
  allPhotographsFromSameDay: boolean
  selectedInterval: DropdownOption
  setIntervalAccordionPhotographModalPhotograph: (photographId: string) => void
  handleDownloadFreePhotograph: (photographId: string) => void
}

const AccordionsByDay = ({
  store,
  allPhotographsFromSameDay,
  selectedInterval,
  setIntervalAccordionPhotographModalPhotograph,
  handleDownloadFreePhotograph,
}: AccordionsByDayProps) => {
  const { photographs } = store

  const [isDayExpanded, setIsDayExpanded] = useState(
    new Map(
      photographs.map((photograph) => [
        new Date(photograph.takenDate).toISOString().slice(0, 10),
        true,
      ])
    )
  )

  return (
    <>
      {Array.from(
        new Set(
          photographs.map((photograph) => new Date(photograph.takenDate).toISOString().slice(0, 10))
        )
      )
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((dayKey) => {
          const dayPhotographs = photographs.filter(
            (photograph) => new Date(photograph.takenDate).toISOString().slice(0, 10) === dayKey
          )
          const dayHeader = formatUtcToLocaleDate(new Date(dayKey))

          return (
            <Accordion key={dayKey}>
              <Card className="my-2 bg-white rounded-lg">
                <Card.Header className="p-0 border-0">
                  <CustomToggleFull
                    eventKey={dayKey}
                    opened={isDayExpanded.get[dayKey]}
                    onClick={() =>
                      setIsDayExpanded(
                        new Map(isDayExpanded).set(dayKey, !isDayExpanded.get(dayKey))
                      )
                    }
                    extraClassNames={`w-full flex justify-between cursor-pointer items-center bg-white border-b-4 border-primary p-2 hover:bg-lumepic-semi_medium_grey ${
                      isDayExpanded.get(dayKey) ? 'rounded-t-md' : 'rounded-md'
                    }`}
                    plusToggle
                  >
                    <h5 className="mb-0 text-lg font-medium">{dayHeader}</h5>
                  </CustomToggleFull>
                </Card.Header>
                {isDayExpanded.get(dayKey) && (
                  <Card.Body className="p-0">
                    {Array.from(
                      new Set(
                        dayPhotographs.map((photograph) =>
                          PhotographUtils.getTimeKey(photograph.takenDate, selectedInterval[0])
                        )
                      )
                    )
                      .sort((a, b) => PhotographUtils.sortIntervals(a, b))
                      .map((timeKey) => {
                        const timePhotographs = dayPhotographs.filter(
                          (photograph) =>
                            PhotographUtils.getTimeKey(
                              photograph.takenDate,
                              selectedInterval[0]
                            ) === timeKey
                        )

                        return (
                          <IntervalAccordion
                            key={timeKey}
                            index={timeKey}
                            unknownPhotographKey={UnknownPhotographKey}
                            setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                            downloadFreePhotograph={handleDownloadFreePhotograph}
                            photographBlockInterval={timePhotographs}
                            isInBurstMode={false}
                            eventDetailsStore={store}
                            allPhotographsFromSameDay={allPhotographsFromSameDay}
                          />
                        )
                      })}
                  </Card.Body>
                )}
              </Card>
            </Accordion>
          )
        })}
    </>
  )
}

export default observer(AccordionsByDay)
