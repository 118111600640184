import React from 'react'
import { useTranslation } from 'react-i18next'

const AlbumDetailsNoResult = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-teal-100 border-teal-500 mx-auto mt-6 rounded-b text-teal-900 px-4 py-3 shadow-md w-1/2 flex justify-center">
      <span className="font-extrabold">{t('No photographs were found')}</span>
    </div>
  )
}

export default AlbumDetailsNoResult
