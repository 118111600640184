export enum PackageType {
  Burst = 'BURST',
  Tag = 'TAG',
}

export interface PurchasePhotograph {
  id: string
  packageType?: PackageType
  packageId?: string
}

export default interface CreatePurchaseInput {
  discountCodes: string[]
  photographs: PurchasePhotograph[]
  printedPhotographs: boolean
}
