import React from 'react'
import whatsappLogo from '../../../assets/icons/v2/whatsappGold.svg'
import ConfigService, { ConfigKeys } from '../../../config'

const whatsappContactPhoneNumber = ConfigService.getValue(ConfigKeys.WHATSAPP_CONTACT_US_NUMBER)
const WHATSAPP_NUMBER_URL = `https://wa.me/${whatsappContactPhoneNumber}/`

const WhatsappHelpButton = () => {
  return (
    <div
      className="fixed bottom-5 right-5 h-12 w-12 bg-white rounded-full flex items-center justify-center z-50 transition-transform duration-100 ease-in-out hover:scale-110 shadow-md"
      id="WhatsappButton"
    >
      <a href={WHATSAPP_NUMBER_URL} target="_blank" rel="noopener noreferrer">
        <img
          src={whatsappLogo}
          alt="Whatsapp Contact"
          className="text-white text-[50px]"
          loading="lazy"
        />
      </a>
    </div>
  )
}

export default WhatsappHelpButton
