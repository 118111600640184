import { Location } from './Location'
import ActivityInterface from '../../services/Interfaces/Activity/Activity.interface'
import { isNil } from 'lodash'

export class Activity {
  public static init(data?: ActivityInterface): Activity {
    const activity = new Activity()
    if (isNil(data)) {
      return activity
    }
    activity.id = data.id
    activity.name = data.name
    activity.locations = !isNil(data.locations)
      ? data.locations.map((location) => Location.init(location))
      : []

    return activity
  }

  id: string

  name: string

  locations: Location[]

  createdAt: Date

  updatedAt: Date

  deletedAt: Date

  getLabel() {
    return {
      value: this.id,
      label: `${this.name}`,
    }
  }

  public static clone(data: Activity): Activity {
    const clone = new Activity()
    clone.id = data.id
    clone.name = data.name
    clone.locations = !isNil(data.locations)
      ? data.locations.map((location) => Location.clone(location))
      : []
    clone.createdAt = data.createdAt
    clone.updatedAt = data.updatedAt
    clone.deletedAt = data.deletedAt

    return clone
  }
}
