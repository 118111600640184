import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from '../../../../stores/AuthStore'
import UserService from 'services/UserService'
import { User } from 'shared/models/User'
import { ProgressInfo } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadImageToAlbum/UploadImageToAlbumStore'
import { isNil } from 'lodash'
import FileService from 'services/FileService'
import UpdateUserInterface from 'services/Interfaces/User/UpdateUser.interface'
import LocationService from 'services/LocationService'
import i18n from 'i18next'
import LocationStore from '../../../../stores/LocationStore'

class ProfileStore {
  public profilePictureUrl: string | null
  public progressInfo: ProgressInfo | undefined
  public imageSubmitted: boolean
  public uploadingImage: boolean
  public image: File | undefined
  public isLoading: boolean
  public error: any
  public serverError: string
  private userService: UserService
  private fileService: FileService
  private locationService: LocationService
  constructor(
    private readonly authStore: AuthStore,
    private readonly locationStore: LocationStore
  ) {
    this.reset()
    makeAutoObservable(this)
    this.userService = new UserService()
    this.fileService = new FileService()
    this.locationService = new LocationService()
  }
  phoneRegExp = RegExp(/^[0-9]{9,13}$/)
  lowercaseAndNumbersRegex = /^[a-z0-9._-]+$/

  reset() {
    this.profilePictureUrl = null
    this.image = undefined
    this.imageSubmitted = false
    this.progressInfo = undefined
    this.uploadingImage = false
    this.error = false
    this.isLoading = false
  }

  setDefaultProfilePicture(user: UpdateUserInterface) {
    this.profilePictureUrl = user.profilePictureUrl || null
  }

  async updateUser(updateUserPayload: UpdateUserInterface): Promise<User | undefined> {
    runInAction(() => {
      this.isLoading = true
    })
    if (!isNil(this.profilePictureUrl)) {
      updateUserPayload.profilePictureUrl = this.profilePictureUrl
    }
    try {
      const user = await this.userService.updateUser(
        this.authStore.getToken(),
        this.authStore.getLoggedUser().id,
        updateUserPayload
      )

      runInAction(() => {
        this.isLoading = false
      })
      return user
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
      return
    }
  }

  uploadImageFail() {
    const _progressInfo = this.progressInfo
    _progressInfo!.error = true
    _progressInfo!.percentage = 100
    this.changeProgressInfo(_progressInfo!)
    this.error = null
    this.uploadingImage = false
  }

  async uploadImage(isRecognitionImage?: boolean): Promise<void> {
    runInAction(() => {
      this.isLoading = true
    })
    this.uploadImageToUserStart()
    const promises: Promise<string>[] = []
    try {
      const response = !isRecognitionImage
        ? await this.userService.createProfilePicture(
            this.image!.type,
            this.authStore.getLoggedUser().id,
            this.authStore.getToken()
          )
        : this.authStore.isAuthenticated()
        ? await this.userService.createPermanentRecognitionPicture(
            this.image!.type,
            this.authStore.getLoggedUser().id,
            this.authStore.getToken()
          )
        : await this.userService.createTemporaryRecognitionPicture(
            this.image!.type,
            this.authStore.randomUuid
          )

      if (!isNil(response)) {
        runInAction(() => {
          if (!isRecognitionImage) {
            this.profilePictureUrl = response.photographUrl
          } else {
            this.authStore.changeRecognitionImage(response.photographUrl)
          }
        })
        try {
          promises.push(
            this.fileService.uploadFileToUrl(
              this.image!,
              response.uploadUrl,
              response.uploadKey,
              (event: any) => {
                const _progressInfo = this.progressInfo
                _progressInfo!.percentage = Math.round((100 * event.loaded) / event.total)
                _progressInfo!.requestSent = true
                this.changeProgressInfo(_progressInfo!)
              },
              () => {
                this.uploadImageFail()
              }
            )
          )
        } catch (e: any) {
          runInAction(() => {
            this.uploadImageFail()
            const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})
            if (!displayedError) {
              this.serverError = i18n.t(
                'Something went wrong, please check the provided data and try again'
              )
            }
          })
        }
      }
      await Promise.all(promises)
      this.isLoading = false
    } catch (e: any) {
      runInAction(() => {
        const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})
        if (!displayedError) {
          this.serverError = i18n.t(
            'Something went wrong, please check the provided data and try again'
          )
        }
      })
    }
  }
  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    const displayedError = false
    keys.forEach((key) => {
      switch (key) {
        default:
          break
      }
    })
    return displayedError
  }

  changeImageSubmitted(val: boolean) {
    this.imageSubmitted = val
  }
  changeImage(val: File) {
    this.image = val
  }
  changeProgressInfo(val: ProgressInfo) {
    this.progressInfo = val
  }
  uploadImageToUserStart() {
    this.error = null
    this.uploadingImage = true
  }
}
export default ProfileStore
