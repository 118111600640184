import { IconButton, InputAdornment, TextField } from '@mui/material'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { useFormik } from 'formik'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { privateAlbumValidationSchema } from './PrivateAlbumValidationSchema'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'

type PrivateAlbumPasswordModalFormProps = {
  displayPasswordModal: boolean
  closePasswordModal: () => void
  store: AlbumDetailsStore
  initialRender: React.MutableRefObject<boolean>
  albumId: string
  photographId?: string
}

const PrivateAlbumPasswordModalForm = ({
  displayPasswordModal,
  closePasswordModal,
  initialRender,
  photographId,
  store,
  albumId,
}: PrivateAlbumPasswordModalFormProps) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const initialValues = {
    password: null,
  }

  const onSubmit = () => {
    if (initialRender.current && !isNil(photographId)) {
      store.fetchPhotograph(albumId, photographId, formik.values.password ?? undefined)
      initialRender.current = false
    } else {
      store.fetchPhotographs(true, formik.values.password ?? undefined)
      initialRender.current = false
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: privateAlbumValidationSchema,
    onSubmit: onSubmit,
  })

  return (
    <Modal
      opened={displayPasswordModal}
      onCancel={closePasswordModal}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <section className="py-3 px-4 bg-white">
        <p className="mb-4 font-medium text-xl ml-0.5 text-lumepic-light_black">
          {t('Enter password')}
        </p>
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            id="password"
            name="password"
            label={t('Password')}
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLock} className="text-lumepic-grey" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault()
                    }}
                    edge="end"
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} className="text-base" />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} className="text-base" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            btnType="PrimaryAction"
            onClick={formik.handleSubmit}
            isLoading={store.isLoadingAlbumPhotographs}
          >
            <span className="px-4 text-lg">{t('Login')}</span>
          </Button>
        </form>
      </section>
    </Modal>
  )
}

export default PrivateAlbumPasswordModalForm
