import React, { useContext } from 'react'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import backgroundImage from '../../../../assets/images/goldenBg1.svg'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import ProfileStore from './ProfileStore'
import storesContext from 'providers/storesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import UpdateProfileForm from './UpdateProfileForm'
import Spinner from 'components/UI/Spinner'
import { User } from 'shared/models/User'
import UpdateUserInterface from 'services/Interfaces/User/UpdateUser.interface'

type UpdateProfileModalProps = {
  onCancel: () => void
  opened: boolean
  requestPhotographerRole: () => void
  isRequestingPhotographerRole?: boolean
  profileStore: ProfileStore
  user: User
}

const UpdateProfileModal = ({
  onCancel,
  opened,
  isRequestingPhotographerRole,
  profileStore,
  user,
  requestPhotographerRole,
}: UpdateProfileModalProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!

  if (authStore.isLoadingRequestPhotographerRole || authStore.isLoading) {
    return (
      <Spinner divStyles="flex justify-center h-primaryMobile md:h-primaryDesktop items-center rounded-lg" />
    )
  }

  const updateUserAndRequestRole = (data: UpdateUserInterface) => {
    profileStore.updateUser(data).then(() => {
      if (isRequestingPhotographerRole) {
        requestPhotographerRole()
      }
      onCancel()
      authStore.getUser()
    })
  }

  return (
    <Modal
      onCancel={onCancel}
      opened={opened}
      modalSize={ModalSize.MEDIUM}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      displayCloseIcon={false}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={() => onCancel()}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {isRequestingPhotographerRole ? t('Are you a photographer?') : t('Edit Profile')}
                </span>
              </h3>
              {isRequestingPhotographerRole && (
                <span className="text-xs">
                  {t('Fill in the following fields and start selling with Lumepic!')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full bg-bg_details rounded-b-md pt-10 pb-0.5">
          <UpdateProfileForm
            onSuccess={updateUserAndRequestRole}
            store={profileStore}
            isRequestingPhotographerRole={isRequestingPhotographerRole ? true : false}
            user={user}
          />
        </div>
      </div>
    </Modal>
  )
}

export default observer(UpdateProfileModal)
