import { User } from './User'
import { Activity } from './Activity'
import { Location } from './Location'
import { Event } from './Event'
import { QuantityDiscount } from './QuantityDiscount'
import { isNil } from 'lodash'
import AlbumInterface from '../../services/Interfaces/Album/Album.interface'
import { Tag } from './Tag'
import { TagKeys } from '../../services/Interfaces/Tag/Tag.interface'
import { RequestedActivity } from './RequestedActivity'
import { Currency } from '../util/Currency'

export default class Album {
  constructor() {
    this.location = new Location()
    this.activity = new Activity()
    this.event = new Event()
    this.newActivityRequested = new RequestedActivity()
    this.owner = new User()
    this.tags = []
  }
  static init(data?: AlbumInterface): Album {
    const newAlbum = new Album()
    if (isNil(data)) {
      return newAlbum
    }
    newAlbum.id = data.id
    newAlbum.description = data.description
    newAlbum.takenDate = data.takenDate // Taken Date is stored in UTC and shown in UTC
    newAlbum.defaultImagePrice = Number(data.defaultImagePrice)
    if (!isNil(data.defaultPackagePrice)) {
      newAlbum.defaultPackagePrice = Number(data.defaultPackagePrice)
    }
    newAlbum.currency = data.currency as Currency
    newAlbum.coverPhotographSrc = data.coverPhotographSrc
    newAlbum.photographCount = data.photographCount
    newAlbum.owner = User.init(data.owner)
    newAlbum.ownerId = data.ownerId
    newAlbum.location = Location.init(data.location)
    newAlbum.activity = data.activity ? Activity.init(data.activity) : new Activity()
    newAlbum.event = Event.init(data.event)
    newAlbum.newActivityRequested = RequestedActivity.init(data.newActivityRequested)
    newAlbum.tags = !isNil(data.tags) ? data.tags.map(Tag.init) : []
    newAlbum.isSuspended = data.isSuspended
    newAlbum.isFree = data.isFree
    newAlbum.createdAt = data.createdAt
    newAlbum.updatedAt = data.updatedAt
    newAlbum.deletedAt = data.deletedAt
    newAlbum.starsAverage = data.starsAverage
    newAlbum.quantityDiscount = QuantityDiscount.init(data.quantityDiscount)
    newAlbum.quantityDiscountId = data.quantityDiscountId
    newAlbum.faceRecognitionProcessed = data.faceRecognitionProcessed
    newAlbum.isPrivate = data.isPrivate
    newAlbum.password = data.password

    return newAlbum
  }

  static clone(album?: Album): Album {
    const clone = new Album()
    if (isNil(album)) {
      return clone
    }
    clone.id = album.id
    clone.description = album.description
    clone.takenDate = album.takenDate
    clone.defaultImagePrice = Number(album.defaultImagePrice)
    clone.defaultPackagePrice = Number(album.defaultPackagePrice)
    clone.currency = album.currency
    clone.coverPhotographSrc = album.coverPhotographSrc
    clone.photographCount = album.photographCount
    clone.owner = album.owner
    clone.ownerId = album.ownerId
    clone.location = Location.clone(album.location)
    clone.activity = Activity.clone(album.activity)
    clone.event = Event.clone(album.event)
    clone.newActivityRequested = RequestedActivity.clone(album.newActivityRequested)
    clone.isSuspended = album.isSuspended
    clone.isFree = album.isFree
    clone.createdAt = album.createdAt
    clone.updatedAt = album.updatedAt
    clone.deletedAt = album.deletedAt
    clone.starsAverage = album.starsAverage
    clone.userLikedAlbum = album.userLikedAlbum
    clone.quantityDiscount = QuantityDiscount.init(album.quantityDiscount)
    clone.quantityDiscountId = album.quantityDiscountId
    clone.faceRecognitionProcessed = album.faceRecognitionProcessed
    clone.isPrivate = album.isPrivate
    clone.password = album.password

    return clone
  }

  id: string

  description: string

  takenDate: Date

  defaultImagePrice: number

  defaultPackagePrice: number

  currency: Currency

  coverPhotographSrc: string

  photographCount: number

  owner: User

  ownerId: string

  location: Location

  activity: Activity

  event: Event

  newActivityRequested: RequestedActivity

  isSuspended: boolean

  isFree: boolean

  tags: Tag[]

  createdAt: Date

  updatedAt: Date

  deletedAt: Date

  starsAverage: number

  userLikedAlbum: boolean

  quantityDiscount?: QuantityDiscount | null

  quantityDiscountId?: string | null

  faceRecognitionProcessed: boolean

  isPrivate?: boolean
  password?: string

  createTagMap() {
    const tagMap = new Map<TagKeys, Tag[]>()

    for (const tag of this.tags) {
      const { key } = tag
      const tagList = tagMap.get(key) || []
      tagList.push(tag)
      tagMap.set(key, tagList)
    }

    return tagMap
  }

  getTagById(id: string): Tag | null {
    return this.tags.find((tag) => tag.id === id) || null
  }
}
