import { makeAutoObservable, runInAction } from 'mobx'
import LocationService, { DefaultCountry, DefaultLocation } from '../services/LocationService'
import { Location } from '../shared/models/Location'
import { Countries } from '../shared/util/countries'
import { Country } from '../shared/models/Country'
import AuthStore from './AuthStore'

class LocationStore {
  private error: any
  public isLoadingLocations: boolean
  public isLoadingUserLocation: boolean
  public isLoadingRequestLocation: boolean
  public locations: Map<string, Location[]>
  public requestedLocation: Location
  public userLocation: { country: Country; latitude: number; longitude: number }

  constructor(
    private readonly locationService: LocationService,
    private readonly authStore: AuthStore
  ) {
    this.error = null
    this.isLoadingLocations = false
    this.locations = new Map()
    this.userLocation = {
      country: DefaultCountry,
      latitude: DefaultLocation.latitude,
      longitude: DefaultLocation.longitude,
    }
    makeAutoObservable(this)
    this.fetchUserLocation().then(() => this.fetchLocations(this.userLocation.country.code, true))
  }

  // TODO: CountryCode mandatory?
  async fetchLocations(countryCode?: string, forceFetch?: boolean): Promise<Location[]> {
    const country = Countries.find((c) => c.code === countryCode)
    if (!country) {
      return []
    }
    if (!forceFetch && this.locations.has(country.code)) {
      return this.getLocationsForCountry(country.code)
    }
    runInAction(() => {
      this.isLoadingLocations = true
    })
    try {
      const response = await this.locationService.fetchLocations(country.code)
      runInAction(() => {
        this.locations.set(country.code, response)
        this.isLoadingLocations = false
      })
      return this.getLocationsForCountry(country.code)
    } catch (e: any) {
      this.error = e?.response
      this.isLoadingLocations = false
      return []
    }
  }

  getLocationsForCountry(countryCode: string): Location[] {
    return this.locations.get(countryCode) || []
  }

  getLocation(locationId?: string): Location | undefined {
    if (!locationId) {
      return undefined
    }
    const locations = Array.from(this.locations.values()).flat()
    return locations.find((l) => l.id === locationId)
  }

  async requestNewLocation(
    spotName: string,
    latitude: number,
    longitude: number
  ): Promise<Location | undefined> {
    runInAction(() => {
      this.isLoadingRequestLocation = true
    })

    try {
      const response = await this.locationService.requestNewLocation(
        {
          spotName,
          latitude,
          longitude,
        },
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoadingRequestLocation = false
      })
      this.requestedLocation = response
      const country = Countries.find((c) => c.code === response.countryCode)
      if (country) {
        const countryLocations = this.getLocationsForCountry(country.code)
        countryLocations.push(response)
        countryLocations.sort((a, b) => {
          const nameA = a.spotName.toUpperCase()
          const nameB = b.spotName.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        this.locations.set(country.code, countryLocations)
      }
      return response
    } catch (e: any) {
      runInAction(() => {
        // TODO: handle error
        this.error = 'Error creating location'

        if (e?.message) {
          this.error = e.message
        }
        this.isLoadingRequestLocation = false
      })
      return
    }
  }

  async fetchUserLocation(): Promise<void> {
    runInAction(() => {
      this.isLoadingUserLocation = true
    })
    try {
      this.userLocation = await this.locationService.getUserLocationData()
      runInAction(() => {
        this.isLoadingUserLocation = false
      })
    } catch (e: any) {
      this.error = e?.response
      this.isLoadingUserLocation = false
    }
  }

  changeUserLocationCountry(country: Country) {
    this.userLocation.country = country
  }
}

export default LocationStore
