import { makeAutoObservable, runInAction } from 'mobx'
import FeedService from '../../services/FeedService'
import Album from '../../shared/models/Album'
import AuthStore from '../../stores/AuthStore'
import { Photograph } from '../../shared/models/Photograph'
import { Tag } from 'shared/models/Tag'
import { TagKeys } from 'services/Interfaces/Tag/Tag.interface'
import { isNil } from 'lodash'

const DefaultPhotographsPerPage = 18
const DefaultFilteredPhotographsPerAlbum = 50

export default class AlbumDetailsStore {
  public album: Album
  public photographs: Photograph[]
  public albumTags: Tag[] | undefined
  public tagIds: string[]
  public isFilteringByFaceRecognition: boolean
  public photographCount: number
  public isLoadingAlbumDetails: boolean
  public isLoadingAlbumTags: boolean
  public isLoadingAlbumPhotographs: boolean
  public isLoadingFreePhotograph: boolean
  public albumNotFound: boolean
  public page: number
  public photographsPerPage: number
  public filterErrors: any
  public isAuthenticated: boolean
  public password: string | undefined
  private readonly feedService: FeedService
  private readonly authStore: AuthStore

  constructor(private readonly albumId: string, authStore: AuthStore, initialTagIds?: string[]) {
    this.resetStore()
    this.tagIds = initialTagIds || []
    makeAutoObservable(this)
    this.authStore = authStore
    this.feedService = new FeedService()
  }

  resetStore() {
    this.albumNotFound = false
    this.isFilteringByFaceRecognition = false
    this.album = new Album()
    this.photographs = []
    this.albumTags = undefined
    this.tagIds = []
    this.photographCount = 0
    this.isLoadingAlbumDetails = false
    this.isLoadingAlbumTags = false
    this.isLoadingAlbumPhotographs = false
    this.page = 1
    this.photographsPerPage = DefaultPhotographsPerPage
    this.filterErrors = null
    this.isAuthenticated = false
    this.password = undefined
  }

  //FETCH FUNCTIONS
  async fetchAlbumDetails(): Promise<void> {
    this.startLoadingAlbumDetails()
    try {
      const response = await this.feedService.fetchAlbumDetails(this.albumId)
      runInAction(() => {
        this.album = response
        this.stopLoadingAlbumDetails()
      })
    } catch (e: any) {
      this.albumNotFound = e.message === 'Album not found'
      this.stopLoadingAlbumDetails()
    }
  }

  async fetchAlbumTags(): Promise<void> {
    runInAction(() => {
      this.isLoadingAlbumTags = true
    })
    try {
      const response = await this.feedService.fetchAlbumTags(this.albumId)
      runInAction(() => {
        this.albumTags = response
        this.isLoadingAlbumTags = false
      })
    } catch (e: any) {
      this.albumNotFound = e.message === 'Album not found'
      this.isLoadingAlbumTags = false
    }
  }

  async fetchPhotograph(albumId: string, photographId: string, password?: string): Promise<void> {
    runInAction(() => {
      this.startLoadingAlbumPhotographs()
      this.photographs = []
    })
    try {
      const photograph = await this.feedService.fetchPhotograph(
        albumId,
        photographId,
        password ?? this.password ?? undefined
      )
      runInAction(() => {
        this.changeIsAuthenticated(true)
        this.stopLoadingAlbumPhotographs()
        this.photographs = [photograph]
        if (!isNil(password) && isNil(this.password)) {
          this.password = password
        }
      })
    } catch (e) {
      this.stopLoadingAlbumPhotographs()
    }
  }

  async fetchPhotographs(isSearching = false, password?: string): Promise<void> {
    this.startLoadingAlbumPhotographs()
    if (isSearching) {
      this.page = 1
      this.photographs = []
    }
    const limit =
      (this.isFilteringByFaceRecognition || this.tagIds.length > 0) && isSearching
        ? DefaultFilteredPhotographsPerAlbum
        : this.photographsPerPage
    const offset = this.isFilteringByFaceRecognition
      ? (this.page - 1) * DefaultFilteredPhotographsPerAlbum
      : (this.page - 1) * this.photographsPerPage
    const tagKeys = this.albumTags?.flatMap((tag) => tag.key) ?? undefined
    try {
      const response = await this.feedService.fetchAlbumPhotographs(
        this.albumId,
        limit,
        offset,
        this.tagIds,
        tagKeys?.[0],
        this.isFilteringByFaceRecognition ? this.authStore.recognitionImageUrl : undefined,
        this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined,
        password ?? this.password ?? undefined
      )
      runInAction(() => {
        if (isSearching) {
          this.photographs = response.items
        } else {
          this.photographs.push(...response.items)
        }
        if (!isNil(password) && isNil(this.password)) {
          this.password = password
        }
        this.photographCount = response.count
        this.changeIsAuthenticated(true)
        this.stopLoadingAlbumPhotographs()
      })
    } catch (e) {
      this.stopLoadingAlbumPhotographs()
    }
  }

  getPhotographPage(photographId: string): number {
    const photographIndex = this.photographs.findIndex(
      (photograph) => photograph.id === photographId
    )
    return Math.floor(photographIndex / this.photographsPerPage) + 1
  }

  // MODIFICATION FUNCTIONS
  changeIsAuthenticated(val: boolean) {
    this.isAuthenticated = val
  }

  startLoadingAlbumDetails() {
    this.isLoadingAlbumDetails = true
  }

  stopLoadingAlbumDetails() {
    this.isLoadingAlbumDetails = false
  }

  startLoadingAlbumPhotographs() {
    this.isLoadingAlbumPhotographs = true
  }

  stopLoadingAlbumPhotographs() {
    this.isLoadingAlbumPhotographs = false
  }

  startLoadingFreePhotographs() {
    this.isLoadingFreePhotograph = true
  }

  stopLoadingFreePhotographs() {
    this.isLoadingFreePhotograph = false
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    this.fetchPhotographs()
  }

  setTagIds(values: string[]) {
    this.tagIds = values
  }

  createTagMap() {
    const tagMap = new Map<TagKeys, Tag[]>()

    if (!isNil(this.albumTags)) {
      for (const tag of this.albumTags) {
        const { key } = tag
        const tagList = tagMap.get(key) || []
        tagList.push(tag)
        tagMap.set(key, tagList)
      }
    }
    return tagMap
  }
  setIsFilteringByFaceRecognition(value: boolean) {
    this.isFilteringByFaceRecognition = value
  }

  async downloadFreePhotograph(photographId: string) {
    this.startLoadingAlbumPhotographs()
    try {
      const photograph = await this.feedService.downloadFreePhotograph(photographId)
      runInAction(() => {
        this.stopLoadingFreePhotographs()
      })
      return photograph
    } catch (e) {
      this.stopLoadingAlbumDetails()
      //TODO: toasT?
    }
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }
  hasMorePages() {
    const pageCount =
      this.isFilteringByFaceRecognition === true
        ? Math.ceil(this.photographCount / DefaultFilteredPhotographsPerAlbum)
        : Math.ceil(this.photographCount / this.photographsPerPage)
    return this.page < pageCount
  }
}
