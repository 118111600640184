import Dropdown from 'components/UI/Dropdown'
import MyAccountDashboardStore from 'containers/MyAccount/components/MyAccountDashboard/stores/MyAccountDashboardStore'
import React from 'react'
import { useTranslation } from 'react-i18next'

type DaysDropdownProps = {
  store: MyAccountDashboardStore
}

const DaysDropdown = ({ store }: DaysDropdownProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex">
      <Dropdown
        options={store.options}
        onChange={(days: { value: number; label: string }) => {
          store.setDays(days)
        }}
        label={t(store.days.label)}
        largeWidth={true}
      />
    </div>
  )
}

export default DaysDropdown
