import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from './AuthStore'
import PurchaseService from '../services/PurchaseService'
import Cart, { CartLine } from '../shared/models/Cart'
import Album from '../shared/models/Album'
import { Photograph } from '../shared/models/Photograph'
import { isNil } from 'lodash'
import { CodeDiscount } from '../shared/models/CodeDiscount'
import { Purchase } from '../shared/models/Purchase'
import DiscountService from '../services/DiscountService'
import { toast } from 'react-toastify'
import PurchaseRequestInterface, {
  PackageType,
  PurchasePhotograph,
} from 'services/Interfaces/Purchase/PurchaseRequest.interface'
import CartService from '../services/CartService'
import { Currency } from 'shared/util/Currency'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import i18n from 'i18next'

const LIMIT = 100
const SKIP = 0

export default class CartStore {
  private cart: Cart
  public albums: Album[] = []
  public isLoading: boolean
  public isLoadingDiscount: boolean
  private error: string | null
  private discountError: boolean
  public discountErrorMessage: string | null
  public displayMiniCart: boolean
  public printedPhotographs: boolean
  constructor(
    private readonly authStore: AuthStore,
    private readonly purchaseService: PurchaseService,
    private readonly discountService: DiscountService,
    private readonly cartService: CartService,
    private readonly feedService: FeedService
  ) {
    this.resetStore()
    makeAutoObservable(this)
    this.fetchCart()
    this.fetchCartAlbums()
  }

  public setDisplayMiniCart(display: boolean) {
    this.displayMiniCart = display
  }

  private resetStore() {
    this.startLoading()
    this.cart = new Cart()
    this.albums = []
    this.isLoading = false
    this.error = null
    this.displayMiniCart = false
    this.printedPhotographs = false
    this.discountError = false
    this.discountErrorMessage = null
    this.stopLoading()
  }

  public async fetchCartAlbums() {
    const photographs = this.cart.getPhotographs()
    const albumIds = photographs.map((photograph) => photograph.albumId)

    const uniqueAlbumIds = [...new Set(albumIds)]

    if (uniqueAlbumIds.length > 0) {
      try {
        const response = await this.feedService.fetchFeedAlbums({
          limit: LIMIT,
          offset: SKIP,
          order: {
            field: FeedOrderFields.TAKEN_DATE,
            sort: FeedOrderSorts.DESC,
          },
          albumIds: uniqueAlbumIds,
        })
        runInAction(() => {
          this.albums = response.albums
        })
        this.stopLoading()
      } catch (error: any) {
        this.stopLoading()
        this.error = error.message
      }
    } else {
      return
    }
  }

  public addPhotograph(photograph: Photograph, album: Album, persistCart = true) {
    this.startLoading()
    if (!isNil(this.cart.currency) && this.cart.currency !== album.currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.cart = this.cartService.addPhotograph(this.cart, photograph, album)
      this.cart.currency = album.currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public addPackage(
    photographs: Photograph[],
    currency: Currency,
    packagePrice: number,
    packageType: PackageType,
    tagId?: string,
    persistCart = true
  ) {
    this.startLoading()
    if (!isNil(this.cart.currency) && this.cart.currency !== currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.startLoading()
      this.cart = this.cartService.addPackage(
        this.cart,
        currency,
        packagePrice,
        photographs,
        packageType,
        tagId
      )
      this.cart.currency = currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public removePhotograph(photographId: string) {
    this.startLoading()
    this.cart = this.cartService.removePhotograph(this.cart, photographId)
    if (this.isEmpty() && !isNil(this.cart.currency)) {
      this.resetCurrency()
    }
    this.persistCart(true)
    this.stopLoading()
  }

  public removePhotographs(photographIds: string[]) {
    this.startLoading()
    this.cart = this.cartService.removePhotographs(this.cart, photographIds)
    if (this.isEmpty() && !isNil(this.cart.currency)) {
      this.resetCurrency()
    }
    this.persistCart(true)
    this.stopLoading()
  }

  private async persistCart(updateFirebaseCart?: boolean) {
    const userId = this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : null
    await this.cartService.persistCart(this.cart, userId, updateFirebaseCart)
  }

  public async fetchCart() {
    const userId = this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : null
    this.cart = await this.cartService.syncCart(this.cart, userId)
    this.persistCart()
  }

  public alreadyInCart(photographId: string): boolean {
    return this.cart.alreadyInCart(photographId)
  }

  public photographFromPackageInCart(photographId: string): boolean {
    return this.cart.photographFromPackageInCart(photographId)
  }

  //DISCOUNTS
  public async applyPurchaseCodeDiscount(code: string, photographIds: string[]): Promise<void> {
    if (isNil(code) || code.length === 0) {
      toast.error('Invalid discount code')
    } else if (!this.discountAlreadyInCart(code)) {
      this.startLoadingDiscount()
      try {
        const [discount, applicablePhotographIds] = await this.discountService.findForPurchase(
          code,
          photographIds,
          this.authStore.getToken()
        )
        if (!isNil(discount) && !isNil(discount.code)) {
          runInAction(() => {
            this.stopLoadingDiscount()
            this.addDiscountToCart(discount, applicablePhotographIds)
          })
        } else {
          this.stopLoadingDiscount()
        }
      } catch (e: any) {
        this.stopLoadingDiscount()
      }
    } else {
      toast.error(i18n.t('Discount already applied'))
    }
  }

  private addDiscountToCart(discount: CodeDiscount, photographIds: string[]) {
    for (const photographId of photographIds) {
      const cartLine = this.cart.lines.find((line) => line.photograph.id === photographId)

      if (!isNil(cartLine)) {
        const discountAlreadyApplied = !isNil(
          this.cart.codeDiscounts.find((discount) => discount.code === discount.code)
        )
        if (!discountAlreadyApplied) {
          this.cart.codeDiscounts.push(discount)
        }
      }
    }
    this.persistCart()
  }

  private discountAlreadyInCart(discountCode: string) {
    const foundDiscount = this.cart.codeDiscounts.find((discount) => discount.code === discountCode)
    return !isNil(foundDiscount)
  }

  //PURCHASES
  public createPurchase = async (cart: Cart): Promise<Purchase | undefined> => {
    this.startLoading()
    const discountCodes = cart.codeDiscounts.flatMap((discount) => {
      return discount.code
    })

    const photographs = cart.lines.flatMap((line) => {
      return {
        id: line.photograph.id,
        packageType: line.packageType,
        packageId: line.tagId,
      }
    })

    try {
      const purchaseData: PurchaseRequestInterface = {
        discountCodes,
        photographs,
        printedPhotographs: this.printedPhotographs,
      }
      const purchase = await this.purchaseService.purchase(purchaseData, this.authStore.getToken())

      runInAction(() => {
        this.stopLoading()
        this.error = null
      })

      return purchase
    } catch (error: any) {
      this.stopLoading()
      this.error = error.message
    }
  }

  public createPackagePurchase = async (
    photographs: PurchasePhotograph[],
    packageType?: PackageType,
    tagId?: string
  ): Promise<Purchase | undefined> => {
    this.startLoading()
    try {
      const purchasePhotographs = photographs.map((photograph) => {
        return {
          id: photograph.id,
          packageType,
          packageId: tagId,
        }
      })

      const purchaseData: PurchaseRequestInterface = {
        discountCodes: [],
        photographs: purchasePhotographs,
        printedPhotographs: this.printedPhotographs,
      }
      const purchase = await this.purchaseService.purchase(purchaseData, this.authStore.getToken())

      runInAction(() => {
        this.error = null
      })

      return purchase
    } catch (error: any) {
      this.stopLoading()
      this.error = error.message
    }
  }

  private startLoading = () => {
    this.isLoading = true
  }

  private stopLoading = () => {
    this.isLoading = false
  }

  private startLoadingDiscount = () => {
    this.isLoadingDiscount = true
  }

  private stopLoadingDiscount = () => {
    this.isLoadingDiscount = false
  }

  public handleChangePrintedPhotographs = () => {
    this.printedPhotographs = !this.printedPhotographs
  }

  public getCart(): Cart {
    return this.cart
  }

  /**
   * @returns the cart lines sorted by album
   */
  public getLines(): Array<CartLine> {
    return this.cart.lines
  }

  public getItemCount(): number {
    const cartLines = Array.from(this.cart.lines.values())
    return cartLines.length
  }

  public isEmpty(): boolean {
    const cartLines = this.getLines()
    return cartLines.length === 0
  }

  get totalPriceAfterQuantityDiscounts(): number {
    return this.cart.getPriceAfterDiscounts()
  }

  public totalPriceAfterDiscountsByGroupedLines(lines: CartLine[]): number {
    return this.cart.getPriceAfterDiscountsByGroupLine(lines)
  }

  get totalFormatted(): string {
    // TODO: How to handle different currencies in a cart
    return `${this.totalPriceAfterQuantityDiscounts}`
  }

  resetCurrency() {
    runInAction(() => {
      this.cart.currency = null
    })
  }

  async deletePurchasedCart() {
    this.startLoading()
    await this.fetchCart()
    this.cart.reset()
    this.persistCart()
    this.stopLoading()
  }
}
