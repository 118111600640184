import axios from './axios'
import * as paths from '../services/Paths'
import { getCountryCodeFromLatLng, parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { Location } from '../shared/models/Location'
import { Country } from 'shared/models/Country'
import { Countries } from '../shared/util/countries'
import RequestLocationInterface from './Interfaces/RequestLocation/RequestLocation.interface'
import { isNil } from 'lodash'

const IP_API_URL = 'https://ipapi.co/json/'

export const DefaultCountry = {
  code: 'UY',
  name: 'Uruguay',
  prefix: '+598',
}

export const DefaultLocation = {
  latitude: -34.9011,
  longitude: -56.1645,
}

class LocationService {
  async fetchLocations(countryCode: string): Promise<Location[]> {
    return axios
      .get(paths.getLocationsByCountryCode(countryCode))
      .then((response: AxiosResponse) => response.data.map((loc) => Location.init(loc)))
      .catch(parseAxiosErrorResponse)
  }

  fetchCountries(): Promise<Country[]> {
    return axios
      .get(paths.GET_COUNTRIES)
      .then((response: AxiosResponse) => response.data.map((country) => Country.init(country)))
      .catch(parseAxiosErrorResponse)
  }

  requestNewLocation(data: RequestLocationInterface, token: string): Promise<Location> {
    return axios
      .post(paths.REQUEST_LOCATION, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => Location.init(response.data))
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  fetchAdminLocations(token: string, page: number) {
    return axios
      .get(`${paths.ADMIN_LOCATIONS}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          spots: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  saveLocation(token: string, locationData: Location) {
    return axios
      .post(paths.GET_LOCATIONS, locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editLocation(token: string, locationData: Location) {
    return axios
      .put(paths.updateLocationById(locationData.id), locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteLocation(locationId: string, token: string) {
    return axios
      .delete(paths.deleteLocationById(locationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  async getUserLocationData(): Promise<{ country: Country; latitude: number; longitude: number }> {
    let fetchedFromNavigator = false
    try {
      const response = await getCountryCodeFromLatLng()
      if (!isNil(response)) {
        fetchedFromNavigator = true
        return {
          country: Country.init(
            Countries.find((country) => country.code === response.countryCode) || DefaultCountry
          ),
          latitude: response.lat,
          longitude: response.lng,
        }
      }
    } catch (e) {
      console.error('Error trying to fetch user location from navigator', e)
    }
    if (!fetchedFromNavigator) {
      try {
        const response = await fetch(IP_API_URL)
        const data = await response.json()
        return {
          country: Country.init(
            Countries.find((country) => country.code === data.country_code) || DefaultCountry
          ),
          latitude: data.latitude,
          longitude: data.longitude,
        }
      } catch (error) {
        console.error('Error trying to fetch user country', error)
      }
    }
    return {
      country: Country.init(DefaultCountry),
      latitude: DefaultLocation.latitude,
      longitude: DefaultLocation.longitude,
    }
  }
}

export default LocationService
