import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as paths from '../../../../routing/Paths'
import {
  faArrowUpRightFromSquare,
  faCalendar,
  faInfo,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import photographerIcon from '../../../../assets/icons/v2/photographer.svg'
import QuantityDiscount from '../QuantityDiscount'
import Album from 'shared/models/Album'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { formatUtcToLocaleDate, getFeatureFlagValue } from 'shared/utility'
import Button from 'components/UI/Button'
import { isNil } from 'lodash'
import { FeatureFlags } from '../../../../config/constants/featureFlags'
import storesContext from 'providers/storesContext'

type AlbumInformationProps = {
  album: Album
}
const AlbumInformation = ({ album }: AlbumInformationProps) => {
  const { featureFlagsStore } = useContext(storesContext)!

  const photographerProfileEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_PROFILE,
    true
  )

  const { t } = useTranslation()

  const openPhotographerProfile = (alias: string) => {
    const photographerProfileUrl = paths.photographerProfileByAlias(alias)
    window.open(photographerProfileUrl, '_blank')
  }

  return (
    <div className="flex flex-col md:flex-row md:justify-between">
      <div className="flex flex-col items-start w-full gap-2 px-1 py-2 mb-3 bg-white rounded-md md:mb-0 md:w-1/2">
        <p className="flex items-center px-1 py-1 m-0 text-base text-lumepic-black">
          <FontAwesomeIcon className="mr-2 text-xl text-lumepic-black" icon={faLocationDot} />
          {album.location.spotName}
        </p>
        <p className="flex items-center px-1 py-1 m-0">
          <FontAwesomeIcon className="mr-2 text-xl text-lumepic-grey" icon={faCalendar} />
          <span className="text-base text-lumepic-grey">
            {formatUtcToLocaleDate(album.takenDate)}
          </span>
        </p>

        <p className="flex items-center px-0 py-1 m-0">
          <img loading="lazy" alt="Photographer" src={photographerIcon} className="mt-1 w-7 h-7" />
          <span className="text-base text-lumepic-grey">{album.owner.alias}</span>

          {photographerProfileEnabled && !isNil(album.owner.alias) && (
            <>
              <Button
                btnType={'TertiaryAction'}
                onClick={() => openPhotographerProfile(album.owner.alias)}
                extraStyle="ml-3 mb-0 mt-1 md:mt-2 "
              >
                {t('View profile')}
              </Button>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="ml-1 text-lumepic-black"
              />
            </>
          )}
        </p>
        <div className="flex items-start px-1 py-1 m-0">
          <div className="flex items-center justify-center w-5 h-5 mr-1 rounded-full bg-lumepic-grey">
            <FontAwesomeIcon className="text-xs text-white" icon={faInfo} />
          </div>
          <span className="text-base text-lumepic-grey text-ellipsis">{album.description}</span>
        </div>
      </div>
      <div className="flex flex-col items-end w-full px-1 py-2 bg-white rounded-md md:w-1/2">
        {!album.isFree && album.quantityDiscount && <QuantityDiscount album={album} />}
      </div>
    </div>
  )
}

export default observer(AlbumInformation)
