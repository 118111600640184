import React from 'react'
import ConfigService, { ConfigKeys } from '../../../config'

const baseUrl = ConfigService.getValue(ConfigKeys.WEB_URL)
const data = [
  {
    id: '1',
    section: 0,
    title: '¿Quién es Lumepic?',
    content:
      'Lumepic somos todos los que hacemos uso de ella. ¡Detrás de Lumepic hay un equipo apasionado por el deporte y la fotografía y que busca mejorar la relación fotógrafo-deportista para beneficio de todos!',
  },
  {
    id: '2',
    section: 0,
    title: '¿Qué es Lumepic?',
    content:
      'Es una comunidad creada por y para deportistas y fotógrafos. Es el punto de encuentro donde podemos los deportistas encontrar nuestras mejores fotos e inmortalizar momentos increíbles y los fotógrafos convertir nuestro hobby en profesión.',
  },
  {
    id: '3',
    section: 0,
    title: '¿Quién puede hacer uso de Lumepic?',
    content:
      '¡Todo el mundo puede hacer uso de Lumepic! Invitamos a todos los fotógrafos deportivos a probarla y a todos los deportistas a encontrarse. Además los organizadores de eventos deportivos puedan contar con Lumepic para que cubran la fotografía y que todos los participantes puedan encontrarse fácilmente.',
  },
  {
    id: '4',
    section: 0,
    title: '¿Cómo funciona Lumepic?',
    content: (
      <span>
        Es simple: <br />
        1- Los fotógrafos toman las fotos. <br />
        2- Suben el álbum en alta calidad a la plataforma que automáticamente las publica en baja
        calidad y con marca de agua.
        <br />
        3- Los deportistas simplemente buscan sus fotos y tienen la posibilidad de comprarlas y
        descargarlas en alta calidad y sin marca de agua.
      </span>
    ),
  },
  {
    id: '5',
    section: 1,
    title: `¿Cualquiera puede registrarse como fotógrafo?`,
    content: `¡Si! Invitamos a todos los fotógrafos a registrarse y empezar a trabajar con Lumepic. No tenemos ningún requisito para el registro más que contar con un correo electrónico.`,
  },
  {
    id: '6',
    section: 1,
    title: `¿Qué tipo de fotografía puede venderse en Lumepic?`,
    content: `Actualmente trabajamos con todo lo que es Fotografía Deportiva.`,
  },
  {
    id: '7',
    section: 1,
    title: `¿Cómo hago para registrarme como fotógrafo?`,
    content: (
      <span>
        Debes ingresar en el siguiente{' '}
        <a href={`${baseUrl}/auth?isRegister=true`} style={{ color: '#D4C581' }}>
          enlace
        </a>{' '}
        y completar tu datos. Luego desde “Mi cuenta” puedes terminar el proceso para registrarte
        como fotógrafo.
      </span>
    ),
  },
  {
    id: '8',
    section: 1,
    title: `¿Cómo vendo mis fotos en Lumepic?`,
    content: `Una vez que te registraste como fotógrafo, y completaste la vinculación con el procesador de pagos, Stripe, puedes ir a “Mis álbumes” y cargar tu primer álbum. Es importante que compartas el link al álbum en redes sociales y a personas vinculadas al evento deportivo para lograr la mayor difusión. Las ventas se realizarán automáticamente y recibirás el dinero en tu cuenta bancaria vinculada con Stripe.`,
  },
  {
    id: '9',
    section: 1,
    title: `¿Cuándo podré acceder al dinero de mis ventas?`,
    content: `El procesador de pagos, Stripe, realizará transferencias semanales a tu cuenta bancaria vinculada.`,
  },
  {
    id: '10',
    section: 1,
    title: `¿Cómo se ordenarán mis fotos?`,
    content: `Tus fotos se ordenarán por fecha y horario. Recuerda ajustar el horario de tu cámara para facilitar la búsqueda. Recuerda que cuanto más fácil sea encontrarse, más probable será que los deportistas realicen la compra, por ende, más ingresos.

    `,
  },
  {
    id: '11',
    section: 1,
    title: `¿Cómo se buscarán los deportistas?`,
    content: `Los deportistas pueden buscar los álbumes por evento, deporte, fecha [y fotógrafo]. Una vez dentro del álbum, podrán encontrarse por horario, por número de competidor y por otros números o palabras encontrados automáticamente por nuestro sistema en las fotos.`,
  },
  {
    id: '12',
    section: 1,
    title: `¿Subir mis fotos implica que todo el mundo puede verlas sin pagar por ellas?`,
    content: `¡No! ¡En realidad sí, pero no! Lumepic es una comunidad, por ende muchas personas podrán ver tus fotos (en baja calidad y con marca de agua), y por esa misma razón, es muy probable que llegue a manos de los deportistas que aparecen en ellas, haciendo viable la posibilidad de que quienes están interesados en comprarlas puedan hacerlo. Al final, a través de Lumepic crecerá mucho tu ingreso por venta de fotos.`,
  },
  {
    id: '13',
    section: 1,
    title: `¿Mis fotos deben cumplir con estándares de calidad? ¿En qué calidad debo subir las fotos?`,
    content: `Los formatos aceptados por la plataforma hoy en día son JPG, JPEG o PNG. No hay un peso ni calidad mínima establecida, pero recomendamos a los fotógrafos subir imágenes de al menos 1 MB de peso y no mayores a 20 MB. Lumepic apela al buen uso de la plataforma y se reserva el derecho de dar de baja cualquier contenido que no cumpla con dicho requisito.`,
  },
  {
    id: '14',
    section: 1,
    title: `Todo muy lindo, pero… ¿Qué cobra Lumepic por el servicio?`,
    content: (
      <span>
        <p>
          Lumepic cuenta de un gran equipo para poder cumplir con el servicio. Por ello cobra a los
          fotógrafos un 20% de lo recaudado por la venta de las fotos, de esa manera se asegura de
          poder funcionar y darse a conocer. ¡Cuanto más grande sea la comunidad, más nos
          beneficiamos todos!{' '}
        </p>
        <p>
          Adicionalmente, contamos con el Programa de Fidelidad que permite a los fotógrafos
          disminuir las comisiones cobradas por Lumepic, puedes ver más sobre ello en la pregunta
          <i>¿Cómo funciona el Programa de Fidelidad?</i>.
        </p>
      </span>
    ),
  },
  {
    id: '15',
    section: 1,
    title: `¿Cómo funciona el sistema de descuentos?`,
    content: `Los fotógrafos pueden elegir otorgar descuentos por cantidad. Los descuentos aplican sobre cada álbum independientemente. Al ingresar al álbum podrás ver el sistema de descuentos que aplica para ese álbum específico. El sistema automáticamente realiza los descuentos por cantidad de fotos y cuanto más fotos compres, menor será lo que pagas por foto.`,
  },
  {
    id: '16',
    section: 1,
    title: `¿Cómo funciona el Programa de Fidelidad?`,
    content: (
      <span>
        <ul>
          <li className="list-disc ml-6">
            ¿Qué es?
            <ul>
              <li className="list-[circle] ml-6">
                Es una promoción para incentivar a que los fotógrafos promuevan el uso de Spotted
                para vender sus fotos pagando menos comisión de Spotted.
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            ¿Para qué sirve?
            <ul>
              <li className="list-[circle] ml-6">
                Para que los fotógrafos lo compartan con sus clientes y que éstos lo utilicen al
                hacer una compra en sus álbumes.
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            ¿Qué resultado tiene su uso?
            <ul>
              <li className="list-[circle] ml-6">
                Cuando un atleta realice una compra en un álbum del fotógrafo con su código
                promocional sucederán dos cosas:
                <ol>
                  <li className="list-[square] ml-6">
                    i. Spotted NO COBRARÁ COMISIÓN por todas las compras que el atleta realice en
                    sus álbumes por UN AÑO (pero sí se cobrará el cargo del procesador de pagos).
                  </li>
                  <li className="list-[square] ml-6">
                    ii. Se le otorgará un descuento al atleta sobre el valor total de esa compra
                    (válido únicamente si es la primer compra del atleta).
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li className="list-disc ml-6">
            ¿Cómo empezar?
            <ul>
              <li className="list-[circle] ml-6">
                El código de fidelidad lo debe generar cada fotógrafo en “Mi cuenta” haciendo click
                en “Crear código de descuento personal” y luego eligiendo:
                <ol>
                  <li className="list-[square] ml-6">
                    i. El nombre del código: texto que deberán ingresar los atletas al realizar la
                    compra
                  </li>
                  <li className="list-[square] ml-6">
                    ii. El % de descuento a aplicar. Dicho % se descontará sobre el valor total de
                    la compra, incluso aunque esa compra ya tenga aplicados otros descuentos.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
        </ul>
      </span>
    ),
  },
  {
    id: '17',
    section: 2,
    title: `¿Cómo funciona Lumepic?`,
    content: `Lumepic es una plataforma en la que los fotógrafos suben las fotos registradas de cierto evento para que los deportistas fotografiados puedan acceder a verlas y comprarlas fácilmente para poder descargarlas en alta calidad y sin marca de agua. ¡Comprando a través de Lumepic apoyas el trabajo de los fotógrafos!`,
  },
  {
    id: '18',
    section: 2,
    title: `¿Cómo puedo comprar mis fotos?`,
    content: `Puedes buscar por álbum o evento primero, buscando por fecha o lugar y también por nombre del evento. Una vez encuentras el evento, puedes buscarte por horario o por número de competidor. Cuando encuentras tus fotos, puedes agregarlas fácilmente al carrito y luego pagar con cualquier tarjeta de crédito o débito.`,
  },
  {
    id: '19',
    section: 2,
    title: `Hice una compra pero nose cómo acceder a mis fotos…`,
    content: `Puedes acceder a las fotos ingresando con tu usuario a Lumepic y yendo a "Mis compras".`,
  },
  {
    id: '20',
    section: 2,
    title: `¿Cómo funciona el sistema de descuentos?`,
    content: `Los fotógrafos pueden elegir otorgar descuentos por cantidad. Los descuentos aplican sobre cada álbum independientemente. Al ingresar al álbum podrás ver el sistema de descuentos que aplica para ese álbum específico. El sistema automáticamente realiza los descuentos por cantidad de fotos y cuanto más fotos compres, menor será lo que pagas por foto.`,
  },
  {
    id: '21',
    section: 2,
    title: `Ya compré mis fotos, ¿cómo hago para descargarlas?`,
    content: `Puedes acceder a tus fotos ingresando a Lumepic con tu usuario y contraseña, luego accediendo a la sección "Compras" desde el menú. Ahí podrás ver el listado de tus compras y las fotos que compraste. Presionando el botón “descargar” comenzará a descargarse. Puede llevar desde segundos hasta minutos porque las fotos son de muy alta calidad.`,
  },
  {
    id: '22',
    section: 2,
    title: `Mis tarjetas salen rechazadas, ¿qué puede estar pasando?`,
    content: `¿Quizás no tengas fondos suficientes o tu banco está rechazando el pago por alguna razón? Te recomendamos consultarlo con el banco. También puedes intentar con alguna otra tarjeta. En caso de que el problema persista puedes escribirnos a info@lumepic.com.`,
  },
  // TODO: add section "ORGANIZADORES DE EVENTOS"
]

export default data
