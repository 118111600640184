import React, { useState } from 'react'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import FacebookShareButton from 'components/UI/ShareButtons/FacebookShareButton'
import TwitterShareButton from 'components/UI/ShareButtons/TwitterShareButton'
import WhatsappShareButton from 'components/UI/ShareButtons/WhatsappShareButton'
import ConfigService from 'config'
import { useTranslation } from 'react-i18next'
import { WhatsappIcon } from 'react-share'
import { Tooltip } from '@mui/material'
import shareChainIcon from '../../../assets/icons/v2/shareChain.svg'

type ProfileShareModalProps = {
  alias: string
  handleClose: () => void
  open: boolean
}

const ProfileShareModal = (props: ProfileShareModalProps) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  const shareUrl = ConfigService.buildPhotographerProfileUrl(props.alias)
  const shareTitle = t('¡Mira este Perfil en Lumepic!')

  const tooltipText = isCopied ? t('Copied to clipboard') : t('Copy to clipboard')

  const handleCopyUrlClick = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(shareUrl)
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  return (
    <Modal
      onCancel={props.handleClose}
      opened={props.open}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <div className="flex flex-col w-full items-center justify-center my-3 gap-4 pb-8">
        <div className="text-2xl font-semibold text-center">{t('Share')}</div>

        <div className="flex flex-col">
          <div className="flex justify-center gap-4 mt-2.5 items-center">
            <Tooltip title={tooltipText} arrow>
              <div
                onClick={() => handleCopyUrlClick()}
                className="w-10 h-10 flex justify-center items-center rounded-full bg-lumepic-medium_grey cursor-pointer hover:opacity-90 transition duration-200"
              >
                <img src={shareChainIcon} alt="CopyUrl" className="p-1.5" />
              </div>
            </Tooltip>
            <FacebookShareButton url={shareUrl} iconSize={40} />
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <WhatsappIcon size={40} round={true} enableBackground="true" />
            </WhatsappShareButton>
            <TwitterShareButton url={shareUrl} iconSize={40} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProfileShareModal
