import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'
import Textarea from '../../../components/UI/Forms/Textarea'
import ContactUsStore from '../ContactUsStore'
import Checkbox from 'components/UI/Checkbox'

type ContactUsFormProps = {
  contactUsStore: ContactUsStore
}

const ContactUsForm = ({ contactUsStore }: ContactUsFormProps) => {
  const { t } = useTranslation()
  const inputEl = useRef(null)
  const handleChangeEmail = (val: string) => contactUsStore.changeEmail(val)
  const handleChangePhone = (val: string) => contactUsStore.changePhone(val)
  const handleChangeMessage = (val: string) => contactUsStore.changeMessage(val)
  const handleChangeFirstName = (val: string) => contactUsStore.changeFirstName(val)
  const handleChangeLastName = (val: string) => contactUsStore.changeLastName(val)
  const handleChangeIsPhotographer = () => contactUsStore.changeIsPhotographer()
  const handleChangeIsAthlete = () => contactUsStore.changeIsAthlete()

  const { email, phone, message, firstName, lastName, isAthlete, isPhotographer } = contactUsStore

  return (
    <>
      <div className="md:flex items-center mt-5">
        <div className="w-full md:w-1/2 flex flex-col">
          <span className="text-white font-medium ml-0.5 mb-1">{t('Name').toString() + '*'}</span>
          <InputWrapper
            onChange={handleChangeFirstName}
            inputStore={firstName}
            placeholder={t('Name').toString()}
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col mt-1.5 md:mt-0 md:ml-6">
          <span className="text-white font-medium ml-0.5 mb-1">
            {t('Surname').toString() + '*'}
          </span>
          <InputWrapper
            onChange={handleChangeLastName}
            inputStore={lastName}
            placeholder={t('Surname').toString()}
          />
        </div>
      </div>

      <div className="md:flex items-center mt-2 md:mt-8">
        <div className="w-full md:w-1/2 flex flex-col">
          <span className="text-white font-medium ml-0.5 mb-1">{t('Email').toString() + '*'}</span>
          <InputWrapper
            onChange={handleChangeEmail}
            inputStore={email}
            placeholder={t('Email').toString()}
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col mt-1.5 md:mt-0 md:ml-6">
          <span className="text-white font-medium ml-0.5 mb-1">{t('Phone').toString()}</span>
          <InputWrapper
            onChange={handleChangePhone}
            inputStore={phone}
            placeholder={t('Phone').toString()}
          />
        </div>
      </div>

      <div>
        <div className="w-full flex flex-col mt-8">
          <span className="text-white font-medium ml-0.5 mb-1">
            {t('Message').toString() + '*'}
          </span>
          <InputWrapper
            onChange={handleChangeMessage}
            inputStore={message}
            inputRef={inputEl}
            as={Textarea}
            placeholder={t('Write your message here...').toString()}
          />
        </div>
      </div>
      <div className="flex flex-col text-white items-start mt-4 mb-2">
        <Checkbox
          id="isPhotographer"
          label={t("I'm a Photographer")}
          value={isPhotographer}
          onChange={handleChangeIsPhotographer}
          fontColor="white"
        />
        <Checkbox
          id="isAthlete"
          label={t("I'm an Athlete")}
          value={isAthlete}
          onChange={handleChangeIsAthlete}
          fontColor="white"
        />
      </div>
    </>
  )
}

export default observer(ContactUsForm)
