import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/UI/Button'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Purchase } from 'shared/models/Purchase'
import PurchaseItemDetails from './PurchaseItemDetails'
import { isNil } from 'lodash'
import PurchaseStore from '../PurchaseStore'

type PurchaseItemProps = {
  purchase: Purchase
  purchaseDesktopDetailsOpened: Purchase | null
  setPurchaseDesktopDetailsOpened: React.Dispatch<React.SetStateAction<Purchase | null>>
  index: number
  store: PurchaseStore
}

const PurchaseItem = ({
  purchase,
  purchaseDesktopDetailsOpened,
  setPurchaseDesktopDetailsOpened,
  index,
  store,
}: PurchaseItemProps) => {
  const { t } = useTranslation()
  const [purchaseDetailsOpened, setPurchaseDetailsOpened] = useState(false)

  const parseAlbumTakenDate = (takenDate: Date) => {
    const date = moment.utc(takenDate)
    return date.format('DD/MM/YYYY')
  }

  const handleOpenDesktopDetails = () => {
    const isMdScreen = window.innerWidth > 768
    if (isMdScreen) {
      setPurchaseDesktopDetailsOpened(purchase)
      window.scrollTo(0, 0)
    }
  }

  const discounts =
    !isNil(purchase.totalPrice) && purchase.status === 'approved' && purchase.discountTotal

  const subTotal = purchase.value

  const total = subTotal - (discounts || 0)

  return (
    <>
      <div className="relative cursor-pointer bg-white" onClick={handleOpenDesktopDetails}>
        {purchaseDetailsOpened && (
          <div className="absolute top-1/3 left-0 transform -translate-x-1/2 md:hidden">
            <div className="w-3 h-12 bg-primary rounded-md" />
          </div>
        )}
        {purchase === purchaseDesktopDetailsOpened && (
          <div className="hidden absolute top1/4 left-0 transform -translate-x-1/2 md:flex md:top-1/4">
            <div className="w-3 h-16 bg-primary rounded-md" />
          </div>
        )}
        <div className="flex justify-between w-full py-4 md:w-auto md:text-base">
          <div className="flex flex-col gap-2 px-3 md:w-48">
            <span
              className={`text-xl font-semibold ${
                purchaseDetailsOpened || purchase === purchaseDesktopDetailsOpened
                  ? 'text-primary'
                  : 'text-lumepic-black'
              }`}
            >
              #{index}
            </span>
            <span className="text-base">
              {purchase.currency.toUpperCase()} ${total.toFixed(2)}
            </span>
            {!isNil(purchase.photographs) && (
              <span className="text-sm text-lumepic-dark_grey font-regular">
                {purchase.photographs.length} {''}
                {purchase.photographs.length === 1 ? t('Photograph') : t('Photographs')}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-1 w-auto mt-2 px-3 md:text-sm">
            <span className="flex">
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: '#03a64a', transform: 'rotate(-9.84deg)' }}
                size="lg"
              />
              <span className="text-success text-base">{t('Approved')}</span>
            </span>
            <span className="text-lumepic-dark_grey font-regular text-sm flex justify-end">
              {parseAlbumTakenDate(purchase.createdAt)}
            </span>
            <span className="pt-2 flex justify-end md:hidden">
              <Button
                btnType={'TertiaryAction'}
                onClick={() => setPurchaseDetailsOpened(!purchaseDetailsOpened)}
                extraStyle="text-sm"
              >
                {!purchaseDetailsOpened ? t('View details') : t('Close details')}
              </Button>
            </span>
          </div>
        </div>
        <div className="mx-3 md:hidden">
          {!purchaseDetailsOpened && <div className="w-full flex justify-center border-t" />}
        </div>
        <div className="hidden md:flex md:mx-3">
          {index !== 1 && <div className="w-full flex justify-center border-t" />}
        </div>
      </div>
      <div className="px-3 md:hidden">
        <PurchaseItemDetails
          purchase={purchase}
          index={index}
          isDetailsOpened={purchaseDetailsOpened}
          purchaseDesktopDetailsOpened={purchaseDesktopDetailsOpened}
          store={store}
        />
      </div>
    </>
  )
}

export default observer(PurchaseItem)
