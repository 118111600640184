import axios from './axiosUsersCarts'
import { AxiosResponse } from 'axios'
import ConfigService, { ConfigKeys } from 'config'
import { isNil } from 'lodash'
import Album from 'shared/models/Album'
import Cart, { CartLine, PackageType } from 'shared/models/Cart'
import { Photograph } from 'shared/models/Photograph'
import { Currency } from 'shared/util/Currency'

const firebaseBaseUrl = ConfigService.getValue(ConfigKeys.USER_CARTS_DATABASE_URL)
const LocalStorageCartsKey = 'localCart'
const LocalStorageUnknownCartKey = `${LocalStorageCartsKey}["UNKNOWN"]`

class CartService {
  private async fetchUserCart(userId: string): Promise<Cart> {
    return axios.get(`${firebaseBaseUrl}${userId}.json`).then((response: AxiosResponse) => {
      return Cart.init(response.data)
    })
  }

  private async createOrUpdateUserCart(userId: string, cart: Cart): Promise<void> {
    return axios.put(`${firebaseBaseUrl}${userId}.json`, cart.toString())
  }

  private async deleteUserCart(userId: string): Promise<void> {
    return axios.delete(`${firebaseBaseUrl}${userId}.json`)
  }

  public addPhotograph(cart: Cart, photograph: Photograph, album: Album): Cart {
    const newCart = Cart.clone(cart)
    if (!cart.alreadyInCart(photograph.id)) {
      const newLine = new CartLine()
      newLine.album = album
      newLine.photograph = photograph
      newLine.currency = album.currency
      newCart.lines.push(newLine)
    }
    return newCart
  }

  public addPackage(
    cart: Cart,
    currency: Currency,
    packagePrice: number,
    photographs: Photograph[],
    packageType: PackageType,
    tagId?: string
  ): Cart {
    const newLines = photographs.map((photograph) => {
      const newLine = new CartLine()
      newLine.currency = currency
      newLine.packagePrice = packagePrice
      newLine.packageType = packageType
      newLine.photograph = photograph
      newLine.tagId = tagId
      return newLine
    })
    const newCart = Cart.clone(cart)
    newCart.lines = newCart.lines.concat(newLines)
    return newCart
  }

  public async addLines(cart: Cart, lines: CartLine[]): Promise<Cart> {
    const newCart = Cart.clone(cart)
    if (isNil(cart.currency) && lines.length > 0) {
      newCart.currency = lines[0].album?.currency ? lines[0].album.currency : lines[0].currency
    }
    for (const line of lines) {
      if (line.isPackage) {
        const newLine = new CartLine()
        newLine.packageType = line.packageType
        newLine.tagId = line.tagId
        newLine.currency = line.currency
        newLine.packagePrice = line.packagePrice
        newLine.packageType = line.packageType
        newLine.tagId = line.tagId
        newLine.photograph = line.photograph
        newCart.lines.push(newLine)
      } else {
        if (newCart.currency === line.album?.currency) {
          if (!cart.alreadyInCart(line.photograph.id)) {
            const newLine = new CartLine()
            newLine.album = line.album
            newLine.photograph = line.photograph
            newLine.currency = line.album?.currency ? line.album.currency : line.currency
            newCart.lines.push(newLine)
          }
        } else {
          console.error(`CartService.addLines: Cannot add line with different currency.`)
        }
      }
    }
    return newCart
  }

  public removePhotograph(cart: Cart, photographId: string): Cart {
    const cartCopy = Cart.clone(cart)

    const lineIndex = cartCopy.lines.findIndex((line) => line.photograph.id === photographId)
    if (lineIndex !== -1) {
      cartCopy.lines.splice(lineIndex, 1)
    }

    return cartCopy
  }

  public removePhotographs(cart: Cart, photographIds: string[]): Cart {
    const cartCopy = Cart.clone(cart)

    photographIds.forEach((photographId) => {
      const lineIndex = cartCopy.lines.findIndex((line) => line.photograph.id === photographId)
      if (lineIndex !== -1) {
        cartCopy.lines.splice(lineIndex, 1)
      }
    })

    return cartCopy
  }

  public async persistCart(
    cart: Cart,
    userId: string | null,
    updateFirebaseCart?: boolean
  ): Promise<void> {
    if (!isNil(userId)) {
      const fetchedCart = await this.fetchUserCart(userId)
      if (isNil(fetchedCart)) {
        await this.createOrUpdateUserCart(userId, cart) // Create firebase Cart
      } else {
        if (cart.lines.length === 0) {
          await this.deleteUserCart(userId) // Empty local Cart -> Delete firebase Cart
        } else if (updateFirebaseCart) {
          await this.createOrUpdateUserCart(userId, cart) // Update firebase cart (add/delete) photographs.
        }
      }
    }

    const key = LocalStorageUnknownCartKey
    const cartString = cart.toString()
    localStorage.setItem(key, cartString)
    key !== LocalStorageUnknownCartKey && localStorage.removeItem(LocalStorageUnknownCartKey)
  }

  public async syncCart(cart: Cart, userId: string | null): Promise<Cart> {
    let newCart = Cart.clone(cart)
    if (!isNil(userId)) {
      const fetchedCart = await this.fetchUserCart(userId)
      if (!isNil(fetchedCart)) {
        newCart = await this.addLines(cart, fetchedCart.lines)
      }
    }

    const localUnknownCartString = localStorage.getItem(LocalStorageUnknownCartKey)
    if (!isNil(localUnknownCartString)) {
      const localCart = JSON.parse(localUnknownCartString)
      if (!isNil(localCart)) {
        const unknownCart = Cart.init(localCart.lines)
        newCart = await this.addLines(newCart, Array.from(unknownCart.lines.values()))
      }
    }
    return newCart
  }
}

export default CartService
