import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const getEventAlbumValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object({
    description: yup.string().required(t('Description is required') as string),
    currency: yup.string().required(t('Currency is required') as string),
    defaultImagePrice: yup.number().min(1, t('Default image price is required') as string),
    defaultPackagePrice: yup
      .number()
      .optional()
      .min(1, t('Must be greater than 1') as string),
    takenDate: yup.date().required(t('Date is required') as string),
    activityId: yup
      .string()
      .uuid()
      .required(t('Activity is required') as string),
    locationId: yup
      .string()
      .uuid()
      .required(t('Location is required') as string),
    eventId: yup
      .string()
      .uuid()
      .required(t('Event is required') as string),
  })
}

export default getEventAlbumValidationSchema
