import React from 'react'
import { observer } from 'mobx-react'
import c from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark, faClock } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'

type ProgressInfo = {
  fileName: string
  percentage: number
  error: boolean
}

type ImageUploadProgressProps = {
  progressInfo: ProgressInfo
  index: number
  image: File
}

const ImageUploadProgress = ({ progressInfo, index }: ImageUploadProgressProps) => {
  const { t } = useTranslation()
  return (
    <div key={index} className={c('w-full h-fit sm:px-0 sm:py-15 flex flex-row items-center')}>
      {progressInfo.percentage > 0 && progressInfo.percentage < 100 && (
        <>
          <Spinner noStyles size={15} />
          <p className="text-black my-1 ml-1">
            {t('Uploading...')} {progressInfo.percentage}%
          </p>
        </>
      )}
      {progressInfo.error && (
        <>
          <FontAwesomeIcon className="text-warning font-medium" icon={faCircleXmark} />
          <p className="text-warning my-1 ml-1 font-medium">Error</p>
        </>
      )}
      {progressInfo.percentage === 0 && !progressInfo.error && (
        <>
          <FontAwesomeIcon className="text-lumepic-light_black" icon={faClock} />
          <p className="text-lumepic-grey my-1 ml-1">{t('Waiting to upload')}</p>
        </>
      )}
      {progressInfo.percentage === 100 && !progressInfo.error && (
        <>
          <FontAwesomeIcon icon={faCircleCheck} className="text-success font-medium" />
          <p className="text-success my-1 ml-1 font-medium">{t('Uploaded successfully')}</p>
        </>
      )}
    </div>
  )
}

export default observer(ImageUploadProgress)
