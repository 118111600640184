import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

export type MultiSelectOption = {
  label: string
  value: string
  key?: string
}

type MultiSelectProps = {
  label: string
  options: MultiSelectOption[]
  onChange: (values: MultiSelectOption[]) => void
  value: MultiSelectOption[]
}

type CustomFilterOption = (option: MultiSelectOption, rawInput: string) => boolean

const MultiSelect = (props: MultiSelectProps) => {
  const animatedComponents = makeAnimated()
  const handleChange = (values) => {
    props.onChange(values)
  }
  const filterOptions: CustomFilterOption = (option, rawInput) => {
    const input = rawInput.toLowerCase()
    if (props.value.some((value) => value.value === option.value)) {
      return true
    }

    return option.label.toLowerCase().includes(input)
  }

  const colourStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: '1px 0px',
      borderColor: state.isFocused ? '#333333' : '#636363',
      outline: state.isFocused ? 'none' : state.isHovered ? 'none' : 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #333333' : '0 0 0 1px #636363',
      backgroundColor: 'white',
      '&:hover': {
        borderColor: '#636363',
      },
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#E5DCB3' : 'white',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isSelected && '#D4C581',
        },
        cursor: 'pointer',
      }
    },
    multiValueRemove: (styles) => ({
      ...styles,
      color: 'black',
      ':hover': {
        backgroundColor: '#333333',
        color: 'white',
      },
    }),
  }

  return (
    <Select
      closeMenuOnSelect={true}
      styles={colourStyles}
      components={animatedComponents}
      isMulti={true}
      placeholder={<p className="align-middle m-0 text-lumepic-grey text-base">{props.label}</p>}
      value={props.value}
      onChange={handleChange}
      options={props.options}
      filterOption={filterOptions}
    />
  )
}

export default MultiSelect
