import React from 'react'
import { useTranslation } from 'react-i18next'
import selfieIcon from '../../../../../assets/icons/v2/selfieIcon.svg'
import bibIcon from '../../../../../assets/icons/v2/bibIcon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react'
import EventDetailsStore from '../../EventDetailsStore'

type EventDetailsFirstRenderMobileProps = {
  handleOpenUploadSelfieModal: () => void
  handleOpenFilterByTagModal: () => void
  handleClearFilters: () => void
  store: EventDetailsStore
}

const EventDetailsFirstRenderMobile = ({
  handleOpenUploadSelfieModal,
  handleOpenFilterByTagModal,
  handleClearFilters,
  store,
}: EventDetailsFirstRenderMobileProps) => {
  const { t } = useTranslation()
  const { event } = store
  return (
    <div className="bg-white flex flex-col w-full gap-3 px-2 sm:px-3 pt-2 pb-4">
      <p className="font-light text-sm">
        {t('Select a search method and find your photos at the best price.')}
      </p>
      <div className="flex gap-3 w-full">
        {event.faceRecognitionFilter && (
          <div
            className="w-full rounded-md border border-lumepic-dark_grey bg-bg_details flex flex-col items-center justify-center px-2 py-4"
            onClick={() => handleOpenUploadSelfieModal()}
          >
            <div className="rounded-full bg-primary_dark h-30 flex items-center justify-center p-4 border border-lumepic-light_black mb-2">
              <img src={selfieIcon} alt="selfie" />
            </div>
            <p className="font-medium mb-0 mt-0.5 text-center">
              <FontAwesomeIcon icon={faStar} className="text-primary_dark mr-0.5" />
              {t('Face recognition')}
            </p>
          </div>
        )}
        {event.textFilter && (
          <div
            onClick={() => handleOpenFilterByTagModal()}
            className="w-full rounded-md border border-lumepic-dark_grey bg-bg_details flex flex-col items-center justify-center px-2 py-4"
          >
            <div className="rounded-full bg-primary_dark h-30 flex items-center justify-center p-4 border border-lumepic-light_black mb-2">
              <img src={bibIcon} alt="bib" />
            </div>
            <p className="font-medium mb-0 mt-0.5 text-center">{t('Text recognition')}</p>
          </div>
        )}
      </div>
      <div
        className="w-full rounded-md border border-lumepic-dark_grey bg-bg_details flex justify-between items-center px-3 py-3"
        onClick={handleClearFilters}
      >
        <div className="flex flex-col">
          <p className="font-medium text-sm mb-0 mt-0.5">{t('Continue without filters')}</p>
          <span className="text-xs text-lumepic-dark_grey">
            {t('This option does not have package discounts')}
          </span>
        </div>
        <FontAwesomeIcon icon={faArrowRight} className="mr-1" />
      </div>
    </div>
  )
}

export default observer(EventDetailsFirstRenderMobile)
