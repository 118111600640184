import * as paths from '../../Paths'
import Auth from '../../../containers/Auth/Auth'
import Cart from '../../../containers/Cart'
import Feed from '../../../containers/Feed/Feed'
import AlbumDetails from '../../../components/AlbumDetails'
import TermsAndConditions from '../../../containers/TermsAndConditions/TermsAndConditions'
import FAQ from '../../../containers/FAQ/FAQ'
import Logout from '../../../containers/Auth/Logout/Logout'
import Home from '../../../containers/Home/Home'
import ForgotPassword from '../../../containers/Auth/UpdatePassword/ForgotPassword'
import ResetPassword from '../../../containers/Auth/UpdatePassword/ResetPassword'
import VerifyUser from '../../../containers/Auth/VerifyUser/VerifyUser'
import ContactUs from '../../../containers/ContactUs/ContactUs'
import PrivacyPolicies from 'containers/PrivacyPolicies/PrivacyPolicies'
import EventDetails from 'containers/Events/EventDetails/EventDetails'
import Events from 'containers/Events/Events'
export default [
  {
    path: paths.HOME,
    name: 'home',
    exact: true,
    redirectPath: null,
    component: Home,
  },
  {
    path: paths.AUTH,
    name: 'auth',
    exact: true,
    redirectPath: null,
    component: Auth,
  },
  {
    path: paths.LOGOUT,
    name: 'logout',
    exact: false,
    redirectPath: null,
    component: Logout,
  },
  {
    path: paths.FORGOTPASSWORD,
    name: 'forgotPassword',
    exact: false,
    redirectPath: null,
    component: ForgotPassword,
  },
  {
    path: paths.RESETPASSWORD,
    name: 'resetPassword',
    exact: false,
    redirectPath: null,
    component: ResetPassword,
  },
  {
    path: paths.VERIFYUSER,
    name: 'verifyUser',
    exact: false,
    redirectPath: null,
    component: VerifyUser,
  },
  {
    path: paths.CART,
    name: 'cart',
    exact: true,
    redirectPath: null,
    component: Cart,
  },
  {
    path: paths.FEED_ALBUMS,
    name: 'feedAlbums',
    exact: true,
    redirectPath: null,
    component: Feed,
  },
  {
    path: paths.EVENTS,
    name: 'events',
    exact: true,
    redirectPath: null,
    component: Events,
  },
  {
    path: paths.FEED_ALBUM_DETAIL,
    name: 'feedAlbumDetail',
    exact: true,
    redirectPath: null,
    component: AlbumDetails,
  },
  {
    path: paths.TERMS_AND_CONDITIONS,
    name: 'termsAndConditions',
    exact: false,
    redirectPath: null,
    component: TermsAndConditions,
  },
  {
    path: paths.PRIVACY_POLICIES,
    name: 'privacyPolicies',
    exact: false,
    redirectPath: null,
    component: PrivacyPolicies,
  },
  {
    path: paths.FAQ,
    name: 'faq',
    exact: false,
    redirectPath: null,
    component: FAQ,
  },
  {
    path: paths.CONTACT_US,
    name: 'contactUs',
    exact: false,
    redirectPath: null,
    component: ContactUs,
  },
  {
    path: paths.FEED_ALBUM_PHOTOGRAPH_DETAIL,
    name: 'feedAlbumPhotographDetail',
    exact: true,
    redirectPath: null,
    component: AlbumDetails,
  },
  {
    path: paths.FEED_EVENT_DETAILS,
    name: 'eventDetails',
    exact: true,
    redirectPath: null,
    component: EventDetails,
  },
  //   This route was added directly to router because it matches everything.
  // {
  //   path: paths.PHOTOGRAPHER_PROFILE,
  //   name: 'photographerProfile',
  //   exact: true,
  //   redirectPath: null,
  //   component: PhotographerProfile,
  // },
]
