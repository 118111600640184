import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { Event } from 'shared/models/Event'
import QuantityDiscount from 'components/AlbumDetails/components/QuantityDiscount'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBolt,
  faCalendar,
  faInfoCircle,
  faLocationDot,
  faTag,
} from '@fortawesome/free-solid-svg-icons'
import { formatUtcToLocaleDateWithoutDay } from 'shared/utility'

type EventInformationProps = {
  event: Event
  albumsCount: number
  photographCount: number
  isLoading: boolean
  filtersView?: boolean
}
const EventInformation = ({
  event,
  albumsCount,
  photographCount,
  isLoading,
  filtersView = false,
}: EventInformationProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="flex flex-col gap-2 p-3 bg-white rounded-md">
        <Skeleton variant="rounded" height={25} width={300} />
        <Skeleton variant="rounded" height={25} />
      </div>
    )
  }

  return (
    <div>
      <div className="border-b-4 border-primary_dark bg-white flex items-center gap-1.5 p-1.5 rounded-t-md">
        <FontAwesomeIcon icon={faInfoCircle} className="text-lumepic-grey lg:text-lg lg:ml-1" />
        <header className="mt-px lg:text-lg">{t('Event information')}</header>
      </div>
      <div className="w-full flex flex-col lg:flex-row lg:justify-between items-center bg-white">
        <div className="flex gap-2 md:gap-4 rounded-md px-2 py-2.5 w-full">
          <img
            src={event.coverPhotographSrc}
            alt="Event Cover"
            className="object-cover object-center rounded-md"
            style={{ height: '150px', maxWidth: '150px' }}
          />
          <div className="flex flex-col gap-2">
            <span className="tracking-tight font-semibold text-base">
              {event.name?.toUpperCase()}
            </span>
            <div className="flex flex-col gap-1.5">
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faCalendar} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {formatUtcToLocaleDateWithoutDay(event.date)}
                </span>
              </div>
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faLocationDot} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {event.location?.spotName}
                </span>
              </div>
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faBolt} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {event.activity?.name}
                </span>
              </div>
              {albumsCount > 0 && photographCount > 0 && (
                <div className="flex gap-1 items-start md:items-center">
                  <div
                    className="w-4 h-4 flex items-center justify-center bg-lumepic-dark_grey text-xs text-white"
                    style={{ borderRadius: '50%' }}
                  >
                    {albumsCount}
                  </div>
                  <span className="tracking-tight text-sm text-lumepic-grey">
                    {albumsCount === 1 ? t('album') : t('albums')} {''}
                    {t('and')} {photographCount}{' '}
                    {photographCount === 1 ? t('photograph') : t('photographs')}.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {!filtersView && (
          <div
            className={`flex flex-col w-full py-1 lg:w-2/5 ${
              (event.isFree || !event.quantityDiscount) && 'hidden'
            }`}
          >
            <div className="h-2 bg-bg_details w-full lg:hidden" />
            <div className="border-b-4 border-primary_dark bg-white flex items-center gap-1.5 p-1.5 mb-2 lg:hidden rounded-t-md">
              <FontAwesomeIcon icon={faTag} className="text-lumepic-grey lg:text-lg lg:ml-1" />
              <header className="mt-px lg:text-lg">{t('Discounts')}</header>
            </div>
            {!event.isFree && event.quantityDiscount && <QuantityDiscount event={event} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(EventInformation)
