import React, { useContext, useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import Login from './Login'
import Register from './Register/Register'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import storesContext from '../../providers/storesContext'
import { useQuery } from '../../hooks/useQuery'
import * as paths from '../../routing/Paths'
import { CredentialResponse } from '@react-oauth/google'
import LoginRegisterStore from './LoginRegisterStore'
import { toast } from 'react-toastify'
import logoWhite from '../../assets/icons/v2/logo-white.svg'
import loginPhotographerImage from '../../assets/icons/v2/login-photographer.svg'
import LocationState from 'services/Interfaces/LocationState/LocationState.interface'
import { isNil } from 'lodash'

const SIGN_IN_MODE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
}

const Auth = () => {
  const [signInMode, setSignInMode] = useState(SIGN_IN_MODE.LOGIN)
  const { t } = useTranslation()
  const { authStore, cartStore, locationStore } = useContext(storesContext)!
  const authenticated = authStore.isAuthenticated()

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )
  const query = useQuery()

  const removeRegisterQueryParam = () => {
    const isRegistering = query.get('isRegister') === 'true'
    if (isRegistering) {
      setSignInMode(SIGN_IN_MODE.REGISTER)
      window.history.replaceState({}, '', window.location.pathname)
    } else {
      setSignInMode(SIGN_IN_MODE.LOGIN)
    }
  }

  useEffect(() => {
    removeRegisterQueryParam()
  }, [])

  let authRedirect
  if (authenticated) {
    const location = useLocation<LocationState>()
    const locationState = location.state
    const redirectTo = !isNil(locationState?.from)
      ? locationState.from
      : !cartStore.isEmpty()
      ? paths.CART
      : authStore.redirectPath
    authRedirect = <Redirect to={redirectTo} />
  }

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  let view
  switch (signInMode) {
    case SIGN_IN_MODE.LOGIN:
      view = (
        <Login
          setSignInMode={setSignInMode}
          googleLoginSuccess={googleLoginSuccess}
          googleLoginError={googleLoginError}
        />
      )
      break
    case SIGN_IN_MODE.REGISTER:
      view = (
        <Register
          setSignInMode={setSignInMode}
          googleLoginSuccess={googleLoginSuccess}
          googleLoginError={googleLoginError}
        />
      )
      break
  }

  return (
    <div className="min-h-primaryMobile md:min-h-primaryDesktop bg-bg_details md:pt-3 pb-20">
      <div className="flex md:h-80vh md:px-3">
        <div className="w-5/12 hidden md:flex flex-col justify-between rounded-l-lg bg-primary">
          <img src={logoWhite} className="mt-3 ml-3" alt="Logo" width="70px" height="70px" />
          <div className="flex justify-center p-10 lg:p-16">
            <img src={loginPhotographerImage} alt="photographer" className="w-full xl:w-3/4" />
          </div>
          <p className="mb-3 ml-3.5 text-xl font-semibold text-white">
            {signInMode === SIGN_IN_MODE.LOGIN
              ? t('Hey! Welcome back') + '.'
              : t('Welcome to Lumepic!')}
          </p>
        </div>
        <div className="w-full md:w-7/12 flex flex-col items-center justify-start min-h-primaryMobile bg-white rounded-lg md:justify-center md:rounded-l-none md:min-h-full">
          {authRedirect}
          {view}
        </div>
      </div>
    </div>
  )
}

export default observer(Auth)
