import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import 'moment/locale/es'
import Modal from '../../../components/UI/Modal'
import { isNil } from 'lodash'
import Album from '../../../shared/models/Album'
import { ModalButtonsDisplay, ModalSize } from '../../../components/UI/Modal/Modal'
import { Event } from 'shared/models/Event'
import EventAlbumForm from './Steps/EventAlbumForm/EventAlbumForm'
import AlbumForm from './Steps/AlbumForm/AlbumForm'
import AssignEvent from './Steps/AssignEvent/AssignEvent'
import UploadImageToAlbum from './Steps/UploadImageToAlbum'
import AddDiscountModal from './Steps/AddDiscountModal/AddDiscountModal'
import PublishAlbumModal from './Steps/PublishAlbum'
import StoresContext from '../../../providers/storesContext'
import CreateOrUpdateAlbumStore from './CreateOrUpdateAlbumStore'
import ServerError from '../../../components/UI/ServerError'
import { useTranslation } from 'react-i18next'
import Spinner from '../../../components/UI/Spinner'
import UploadCoverPhotograph from './Steps/UploadCoverPhotograph/UploadCoverPhotograph'
import { User } from 'shared/models/User'

export enum Steps {
  SelectAlbumType = 'SelectAlbumType',
  CreateOrUpdateAlbum = 'CreateOrUpdateAlbum',
  AddDiscountsToAlbum = 'AddDiscountsToAlbum',
  UploadCoverPhotograph = 'UploadCoverPhotograph',
  AddPhotographsToAlbum = 'AddPhotographsToAlbum',
  PublishAlbum = 'PublishAlbum',
}

const FullSteps = [
  Steps.SelectAlbumType,
  Steps.CreateOrUpdateAlbum,
  Steps.AddDiscountsToAlbum,
  Steps.UploadCoverPhotograph,
  Steps.AddPhotographsToAlbum,
  Steps.PublishAlbum,
]

type CreateOrUpdateAlbumProps = {
  handleFinish: () => void
  handleClose: () => void
  open: boolean
  albumToEdit?: Album
  definedSteps?: Steps[]
  publishAlbum: (album: Album) => void
  loggedUser: User
  redirectToStripeOnboarding: () => void
}

const CreateOrUpdateAlbumSteps = ({
  handleFinish,
  handleClose,
  open,
  albumToEdit,
  definedSteps,
  publishAlbum,
  loggedUser,
  redirectToStripeOnboarding,
}: CreateOrUpdateAlbumProps) => {
  const { t } = useTranslation()
  const { eventStore } = useContext(StoresContext)!
  const { authStore } = useContext(StoresContext)!

  const steps = definedSteps ? definedSteps : FullSteps
  const [currentStep, setCurrentStep] = useState<Steps>(steps[0])

  const [store] = useState(() => new CreateOrUpdateAlbumStore(authStore, albumToEdit))

  const nextStep = () => {
    const isLastStep = steps.indexOf(currentStep) === steps.length - 1
    if (isLastStep) {
      handleFinish()
    } else {
      setCurrentStep(steps[steps.indexOf(currentStep) + 1])
    }
  }

  let currentStepView: JSX.Element | null = null

  const handleFinishAssignEvent = (event?: Event) => {
    store.setAlbumEvent(event?.id)
    nextStep()
  }

  const handleCreateOrUpdateAlbum = (album: Album) => {
    store.setAlbum(album)
    nextStep()
  }

  const handleFinishUploadCoverPhotograph = () => {
    nextStep()
  }

  const handleFinishUploadImages = () => {
    if (store.coverUploaded && store.imagesUploaded) {
      publishAlbum(store.album)
    }
    nextStep()
  }

  const handleFinishAddDiscounts = () => {
    nextStep()
  }

  const onCloseModal = () => {
    if (
      store.isEditing ||
      !(currentStep === Steps.SelectAlbumType || currentStep === Steps.CreateOrUpdateAlbum)
    ) {
      handleFinish()
    } else {
      handleClose()
    }
    eventStore.reset()
  }

  const event = store.isEditing ? store.album?.event : store.albumEvent

  // Each Step handles it's own API requests and calls handleFinish when it's done
  switch (currentStep) {
    case Steps.SelectAlbumType:
      currentStepView = (
        <AssignEvent
          handleFinish={handleFinishAssignEvent}
          loggedUser={loggedUser}
          redirectToStripeOnboarding={redirectToStripeOnboarding}
        />
      )
      break
    case Steps.CreateOrUpdateAlbum:
      currentStepView = isNil(event?.id) ? (
        <AlbumForm
          store={store}
          albumToEdit={albumToEdit}
          handleFinish={handleCreateOrUpdateAlbum}
        />
      ) : !isNil(event) ? (
        <EventAlbumForm
          store={store}
          assignedEvent={event}
          albumToEdit={albumToEdit}
          handleFinish={handleCreateOrUpdateAlbum}
        />
      ) : (
        <></>
      )
      break
    case Steps.AddDiscountsToAlbum:
      // If the album has a quantity discount, skip this step
      if (!isNil(store.albumEvent?.quantityDiscountId) || store.albumEvent?.isFree) {
        nextStep()
        break
      }
      currentStepView = (
        <AddDiscountModal
          handleFinish={handleFinishAddDiscounts}
          closeModal={handleClose}
          album={store.album}
          isCreating={!store.isEditing}
        />
      )
      break
    case Steps.UploadCoverPhotograph:
      currentStepView = (
        <UploadCoverPhotograph
          handleFinish={handleFinishUploadCoverPhotograph}
          album={store.album}
          isCreating={!store.isEditing}
          albumEvent={store.albumEvent}
          setCoverUploaded={store.setCoverUploaded}
        />
      )
      break
    case Steps.AddPhotographsToAlbum:
      currentStepView = (
        <UploadImageToAlbum
          handleFinish={handleFinishUploadImages}
          closeModal={handleClose}
          open={true}
          album={store.album}
          isCreating={!store.isEditing}
          albumEvent={store.albumEvent}
          setImagesUploaded={store.setImagesUploaded}
        />
      )
      break
    case Steps.PublishAlbum:
      currentStepView = <PublishAlbumModal album={store.album} />
      break
    default:
      handleClose()
      break
  }

  return (
    <Modal
      onCancel={onCloseModal}
      opened={open}
      modalSize={
        currentStep === Steps.UploadCoverPhotograph || currentStep === Steps.PublishAlbum
          ? ModalSize.MEDIUM_SMALL
          : ModalSize.MEDIUM
      }
      autoClose={false}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <div className="grid w-full grid-rows-1 gap-2 px-6 pt-2 pb-4">
        {store.error && <ServerError message={t(store.error).toString()} />}
        {store.isLoading || store.isLoadingEventDetails ? (
          <Spinner divStyles="flex justify-center items-center h-primaryMobile md:h-primaryDesktop" />
        ) : (
          currentStepView
        )}
      </div>
    </Modal>
  )
}

export default observer(CreateOrUpdateAlbumSteps)
