export const PURCHASE = '/purchases'
export const MULTI_ALBUM_PURCHASE = '/multi-album-purchases'
export const PURCHASED_PHOTOGRAPHS = '/purchases/:id/photographs'
export const fetchPurchase = (purchaseId: string) => {
  return `/purchases/${purchaseId}`
}
export const fetchPurchaseByStripeSessionId = (sessionId: string) => {
  return `/purchases/stripe/${sessionId}`
}
export const purchasedPhotographById = (purchaseId: string, photographId: string) => {
  return `/purchases/${purchaseId}/photographs/${photographId}`
}
export const purchasedPhotographsDownloadUrls = (purchaseId: string) => {
  return `/purchases/${purchaseId}/photographs/bulk-download-urls`
}
export const deletePendingPurchase = (purchaseId: string) => {
  return `${PURCHASE}/${purchaseId}/cancel`
}

//ADMIN ROUTES
const prefix = '/purchases'
export const ADMIN_PURCHASES = `${prefix}/admin`

export const approvePurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}/approve`
}
export const rejectPurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}/reject`
}

export const deletePurchaseById = (purchaseId: string) => {
  return `${prefix}/${purchaseId}`
}
