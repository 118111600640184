import { makeAutoObservable, runInAction } from 'mobx'
import { EventService, EventsOrderFields, EventsOrderSorts } from '../../services/EventService'
import { Event } from '../../shared/models/Event'
import SearchBarStore from '../../components/UI/SearchBar/SearchBarStore'
import AuthStore from 'stores/AuthStore'
import Accreditation from 'shared/models/Accreditation'
import { AccreditationService } from 'services/AccreditationService'

const DefaultEventsPerPage = 20

/**
 * EventsStore
 * @class EventsStore
 * @description Class that contains the state and logic for the Events page.
 * Do not confuse with global state class EventStore!! This class should ONLY contain state and logic for the Events page.
 */
export default class EventsStore {
  public events: Event[]
  public count: number
  public accreditations: Accreditation[]
  public isLoading: boolean
  public isLoadingAccreditations: boolean
  public error: any
  public page: number
  public eventsPerPage: number
  private readonly eventService: EventService
  private readonly accreditationService: AccreditationService

  constructor(
    private readonly authStore: AuthStore,
    private readonly searchBarStore: SearchBarStore
  ) {
    this.eventService = new EventService()
    this.accreditationService = new AccreditationService()
    makeAutoObservable(this)
    this.resetStore()
  }

  resetStore() {
    this.events = []
    this.accreditations = []
    this.count = 0
    this.isLoading = false
    this.error = false
    this.page = 0
    this.eventsPerPage = DefaultEventsPerPage
  }

  async fetchEvents(isSearching?: boolean, isFetchingFutureEvents?: boolean) {
    runInAction(() => {
      this.isLoading = true
    })

    if (isSearching) {
      this.page = 0
      this.events = []
    }

    const today = new Date()

    let [dateFrom, dateTo] = this.searchBarStore.dateRange
    if (isFetchingFutureEvents) {
      dateFrom = today
      dateTo = null
    } else {
      dateTo = today
    }
    const isAuthenticated = this.authStore.isAuthenticated()
    try {
      const response = await this.eventService.fetchEvents({
        countryCode: this.searchBarStore.countryCode,
        dateFrom,
        dateTo,
        locationId: this.searchBarStore.location?.id,
        activityId: this.searchBarStore.activity?.id,
        userId: isAuthenticated ? this.authStore.getLoggedUser().id : undefined,
        limit: this.eventsPerPage,
        offset: this.page * this.eventsPerPage,
        order: {
          field: EventsOrderFields.DATE,
          sort: isFetchingFutureEvents ? EventsOrderSorts.ASC : EventsOrderSorts.DESC,
        },
      })

      runInAction(() => {
        const { events, count } = response
        if (isSearching) {
          this.events = events
        } else {
          this.events.push(...events)
        }
        this.count = count
        this.isLoading = false
      })

      return response
    } catch (e: any) {
      this.isLoading = false
      this.error = e?.response
    }
  }

  async fetchPhotographerAccreditations() {
    runInAction(() => {
      this.isLoading = true
      this.isLoadingAccreditations = true
    })
    try {
      const response = await this.accreditationService.fetchPhotographerAccreditations(
        this.authStore.getToken()
      )
      runInAction(() => {
        this.accreditations = response
        this.isLoading = false
        this.isLoadingAccreditations = false
      })
      return response
    } catch (e: any) {
      this.isLoading = false
      this.isLoadingAccreditations = false
      this.error = e?.response
    }
  }

  async requestEventAccreditation(
    eventId: string,
    requesterId: string
  ): Promise<Accreditation | undefined> {
    runInAction(() => {
      this.isLoadingAccreditations = true
    })

    try {
      const response = await this.accreditationService.createAccreditation(
        eventId,
        requesterId,
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoadingAccreditations = false
        this.accreditations = [...this.accreditations, response]
      })
      return response
    } catch (e: any) {
      this.isLoadingAccreditations = false
      this.error = e?.response
    }
  }

  setPage(page: number, isFetchingFutureEvents?: boolean) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    runInAction(() => {
      this.page = page
      if (isFetchingFutureEvents) {
        this.fetchEvents(false, true)
      } else {
        this.fetchEvents()
      }
    })
  }

  fetchNextPage(isFetchingFutureEvents?: boolean) {
    this.setPage(this.page + 1, isFetchingFutureEvents)
  }

  hasMorePages() {
    const pageCount = Math.floor(this.count / this.eventsPerPage)
    return this.page < pageCount
  }
}
