import React, { useContext, useState } from 'react'
import classes from './Layout.module.scss'
import Toolbar from '../../components/Navigation/Toolbar'
import SideDrawer from '../../components/Navigation/SideDrawer'
import { ToastContainer } from 'react-toastify'
import Footer from '../../components/Footer'
import { observer } from 'mobx-react'
import storesContext from '../../providers/storesContext'
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk'
import WhatsappHelpButton from 'components/UI/HelpButton'

const Layout = (props: any) => {
  const [sideDrawerIsVisible, setSideDrawerVisible] = useState(false)
  const ldClient = useLDClient()
  const { authStore } = useContext(storesContext)!

  const isAuthenticated = authStore.isAuthenticated()

  if (isAuthenticated) {
    const loggedUser = authStore.getLoggedUser()
    const newContext: LDContext = {
      kind: 'multi',
      user: {
        key: loggedUser.id,
        name: `${loggedUser.firstName} ${loggedUser.lastName}`,
        email: loggedUser.email,
        country: loggedUser.countryCode,
        custom: {
          isPhotographer: loggedUser.isPhotographer,
          isAthlete: loggedUser.isAthlete,
          isEnabledToSell: loggedUser.isEnabledToSell,
          createdAt: loggedUser.createdAt,
          alias: loggedUser.alias,
        },
        // ip: // TODO: IP?
      },
      // TODO: Device Info ?
      // device: {
      //   key: 'device-key-123abc',
      //   type: 'iPhone',
      //   deviceId: 12345,
      // },
    }
    ldClient?.identify(newContext)
  }

  const sideDrawerClosedHandler = () => {
    setSideDrawerVisible(false)
  }

  const sideDrawerToggleHandler = () => {
    setSideDrawerVisible(!sideDrawerIsVisible)
  }

  return (
    <div>
      <Toolbar drawerToggleClicked={sideDrawerToggleHandler} />
      <SideDrawer open={sideDrawerIsVisible} onClose={sideDrawerClosedHandler} />
      <WhatsappHelpButton />
      <main className={classes.Content}>
        {props.children}
        <ToastContainer />
      </main>
      <Footer />
    </div>
  )
}

export default observer(Layout)
