import { Purchase } from '../models/Purchase'

/*
 * @docs https://www.facebook.com/business/help/402791146561655?id=1205376682832142
 */

export enum MetaEvents {
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  AddPaymentInfo = 'AddPaymentInfo',
  CompleteRegistration = 'CompleteRegistration',
  Contact = 'Contact',
  ViewContent = 'ViewContent',
  Purchase = 'Purchase',
  DownloadFreePhotograph = 'DownloadFreePhotograph',
  FilterByTag = 'FilterByTag',
  FilterByFaceRecognition = 'FilterByFaceRecognition',
}

export default class FBTracker {
  public static track(event: MetaEvents): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typeof window !== 'undefined' && typeof window.fbq === 'function' && window.fbq('track', event)
  }

  public static trackPurchase(purchase: Purchase): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typeof window !== 'undefined' &&
      typeof window['fbq'] === 'function' &&
      window['fbq']('track', MetaEvents.Purchase, { value: purchase.totalPrice, currency: 'UYU' })
  }
}
