import { makeAutoObservable, runInAction } from 'mobx'
import AlbumService from '../../../services/AlbumService'
import Album from '../../../shared/models/Album'
import { Event } from '../../../shared/models/Event'
import AuthStore from '../../../stores/AuthStore'
import CreateAlbumInterface from '../../../services/Interfaces/Album/CreateAlbum.interface'
import EditAlbumInterface from '../../../services/Interfaces/Album/EditAlbum.interface'
import { isNil } from 'lodash'
import { EventService } from '../../../services/EventService'
import { getLocalDate } from 'shared/util/dates'

class CreateOrUpdateAlbumStore {
  public error: any
  private albumService: AlbumService
  public eventService: EventService
  public isLoading: boolean
  public isLoadingEventDetails: boolean
  public album: Album
  public coverUploaded: boolean
  public imagesUploaded: boolean
  public isEditing: boolean
  public albumEvent?: Event

  constructor(private readonly authStore: AuthStore, albumToEdit?: Album, albumEvent?: Event) {
    this.albumService = new AlbumService()
    this.eventService = new EventService()
    this.reset()
    this.album = albumToEdit || new Album()
    this.isEditing = !isNil(albumToEdit)
    this.albumEvent = albumEvent
    makeAutoObservable(this)
  }

  reset() {
    this.album = new Album()
    this.isLoading = false
    this.isLoadingEventDetails = false
    this.error = null
    this.isEditing = false
    this.coverUploaded = false
    this.imagesUploaded = false
  }

  async createAlbum(createAlbumPayload: CreateAlbumInterface): Promise<Album | undefined> {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      createAlbumPayload.takenDate = getLocalDate(createAlbumPayload.takenDate)!
      const data = await this.albumService.createAlbum(
        createAlbumPayload,
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoading = false
        this.error = null
      })
      return Album.init(data)
    } catch (e: any) {
      runInAction(() => {
        // const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})
        // TODO: handle error

        // if (!displayedError) {
        this.error = 'Something went wrong, please check the provided data and try again.'
        // }

        if (e?.message) {
          this.error = e.message
        }
        this.isLoading = false
      })
      return
    }
  }

  async updateAlbum(editAlbumPayload: EditAlbumInterface): Promise<Album | undefined> {
    runInAction(() => {
      this.isLoading = true
    })

    try {
      const data = await this.albumService.editAlbum(editAlbumPayload, this.authStore.getToken())
      runInAction(() => {
        this.isLoading = false
      })
      return Album.init(data)
    } catch (e: any) {
      runInAction(() => {
        // const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

        // TODO: handle error
        // if (!displayedError) {
        this.error = 'Something went wrong, please check the provided data and try again.'
        // }

        if (e?.message) {
          this.error = e.message
        }
        this.isLoading = false
      })
      return
    }
  }

  public setCoverUploaded = () => {
    this.coverUploaded = true
  }

  public setImagesUploaded = () => {
    this.imagesUploaded = true
  }

  async setAlbum(album: Album) {
    runInAction(() => {
      this.album = album
    })
  }

  async setAlbumEvent(albumEventId?: string) {
    if (isNil(albumEventId)) return
    runInAction(() => {
      this.isLoadingEventDetails = true
    })
    try {
      const albumEvent = await this.eventService.fetchEventDetails(albumEventId)
      runInAction(() => {
        this.albumEvent = albumEvent
        this.isLoadingEventDetails = false
      })
    } catch (e: any) {
      runInAction(() => {
        this.error = e.message
        this.isLoadingEventDetails = false
      })
    }
  }
}

export default CreateOrUpdateAlbumStore
