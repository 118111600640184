import React from 'react'
import { isMobileDevice } from 'shared/utility'
import image from '../../../../../../assets/images/PrivateAlbumBlur.svg'

const PrivateAlbumBlurredImg = () => {
  return (
    <div className="w-full grid grid-cols-6 gap-2 mt-2 mb-4 px-1.5 cursor-not-allowed">
      {[...Array(isMobileDevice() ? 4 : 12)].map((_, index) => (
        <div className="grid col-span-3 sm:col-span-2 lg:col-span-1" key={index}>
          <img src={image} alt="Blurred Img" className="blur-[8px]" />
        </div>
      ))}
    </div>
  )
}

export default PrivateAlbumBlurredImg
