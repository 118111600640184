import { Skeleton, Table, TableCell, TableHead, TableRow } from '@mui/material'
import { AlbumTableColumn } from 'components/Album/AlbumTable/AlbumTable'
import React from 'react'
import { useTranslation } from 'react-i18next'

type SkeletonLoaderMyAlbums = {
  columns: AlbumTableColumn[]
}

const SkeletonLoaderMyAlbums = ({ columns }: SkeletonLoaderMyAlbums) => {
  const { t } = useTranslation()
  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: '6px' }}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <div className="bg-white rounded-b-md w-full flex flex-col gap-2 p-2.5">
        {[...Array(10)].map((_, index) => (
          <Skeleton variant="rounded" height={30} key={index} />
        ))}
      </div>
    </>
  )
}

export default SkeletonLoaderMyAlbums
