import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import StoresContext from '../../../../providers/storesContext'
import classes from './MiniCart.module.scss'
import AuxHoc from '../../../../hoc/AuxHocs/AuxHoc'
import Backdrop from '../../../../components/UI/Backdrop'
import MiniCartLine from './components/MiniCartLine'
import Button from 'components/UI/Button'
import { AUTH, CART } from '../../../../routing/Paths'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { isNil } from 'lodash'

const MiniCart = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, cartStore } = useContext(StoresContext)!
  const [isMobile, setIsMobile] = useState(false)
  const cart = cartStore.getCart()
  const cartIsEmpty = cartStore.isEmpty()

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const goTo = (e: any, path: string) => {
    e.preventDefault()
    cartStore.setDisplayMiniCart(false)
    history.push(path)
  }

  const toggleMiniCart = (e: any) => {
    e.preventDefault()
    cartStore.setDisplayMiniCart(!cartStore.displayMiniCart)
  }

  const noItemsView = <h2 className={classes.noItemsView}>{t('No Items in Cart')}</h2>
  const currencyCount: string[] = []

  let miniCartLines
  if (!cartIsEmpty) {
    miniCartLines = cart.lines.map((line, index) => {
      const currency = !isNil(line.album) ? line.album.currency : line.currency
      if (!currencyCount.includes(currency)) {
        currencyCount.push(currency)
      }
      return <MiniCartLine key={index} cart={cart} line={line} />
    })
  }

  const totalPriceAfterDiscounts = cart.getPriceAfterDiscountsByGroupLine(cart.lines)

  return (
    <>
      <div className={classes.CartNavigationContent} onClick={toggleMiniCart}>
        <div id="counter" className={classes.CartItemsCount}>
          <p>{cartStore.isLoading ? 0 : cartStore.getItemCount()}</p>
        </div>

        <Icon
          icon="fa-solid:shopping-bag"
          color={isMobile ? '#333' : '#636363'}
          width="22"
          height="24"
        />
      </div>
      {cartStore.displayMiniCart ? (
        <AuxHoc>
          <Backdrop show type="Transparent" clicked={toggleMiniCart} />
          <div className={classes.MiniCartView}>
            <div className={classes.CartContainer}>
              <div className="w-full flex flex-col justify-start items-center p-2.5 max-h-50 overflow-y-auto">
                {cartIsEmpty ? noItemsView : miniCartLines}
              </div>

              <div className={classes.CartSummary}>
                {currencyCount.length === 1 && (
                  <div className={classes.CartInfo}>
                    <p>
                      {cartStore.getItemCount()} {t('Photos')}
                    </p>
                    <p>
                      {t('Total')}:{' '}
                      <span>{`${cart.currency?.toUpperCase()} ${totalPriceAfterDiscounts.toFixed(
                        2
                      )}`}</span>
                    </p>
                  </div>
                )}
                <Button
                  btnType="PrimaryAction"
                  extraStyle="w-full mt-2"
                  onClick={(e: any) => goTo(e, CART)}
                >
                  {t('View Cart')}
                </Button>
                {!authStore.isAuthenticated() && (
                  <Button
                    btnType="SecondaryAction"
                    extraStyle="w-full mt-1"
                    onClick={(e: any) => goTo(e, AUTH)}
                  >
                    {t('Login')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </AuxHoc>
      ) : (
        <></>
      )}
    </>
  )
}

export default observer(MiniCart)
