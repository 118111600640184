import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from '../../providers/storesContext'
import CartLineEmpty from './components/CartLine/CartLineEmpty'
import CartSummary from './components/CartSummary'
import CartDiscountStore from './CartDiscountStore'
import CartLineComponent from './components/CartLine'
import { isNil } from 'lodash'
import Cart, { CartLine } from '../../shared/models/Cart'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../services/axios'
import Tracker from '../../shared/tracking'
import Spinner from 'components/UI/Spinner'
import { SetPageTitle, formatUtcToLocaleDate } from '../../shared/utility'
import { Accordion, Card } from 'react-bootstrap'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'
import { faCalendar, faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PackageLine from './components/CartLine/PackageLine'

const CartView = () => {
  const { authStore, cartStore } = useContext(StoresContext)!
  const { t } = useTranslation()

  SetPageTitle('Cart')

  cartStore.setDisplayMiniCart(false)

  const [cartDiscountStore] = useState(() => new CartDiscountStore())

  const [expandedPanels, setExpandedPanels] = useState<{ [key: string]: boolean }>({})

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)

  const cart = cartStore.getCart()

  Tracker.initiateCheckout(cart)

  useEffect(() => {
    const fetchData = async () => {
      await cartStore.fetchCartAlbums()
      setIsLoadingData(false)
    }
    fetchData()
  }, [])

  const handlePurchase = (cart: Cart) => {
    Tracker.addPaymentInformation(cart)
    cartStore.createPurchase(cart).then((purchase) => {
      if (!isNil(purchase)) {
        window.location.href = purchase.paymentUrl!
      }
    })
  }

  const handleDiscountSubmit = (discountCode: string): Promise<void> => {
    const photographIds = cartStore
      .getCart()
      .getPhotographs()
      .map((photograph) => photograph.id)
    return cartStore.applyPurchaseCodeDiscount(discountCode, photographIds)
  }

  if (cartStore.isLoading || isLoadingData) {
    return <Spinner />
  }

  const groupCartLines = (lines: CartLine[]) => {
    const groupedLines: { [key: string]: CartLine[] } = {}
    if (!isNil(lines)) {
      lines.forEach((line) => {
        const photograph = line.photograph
        let key: string
        const album = cartStore.albums.find((album) => album.id === photograph.albumId)
        if (!isNil(album)) {
          if (!isNil(album.event) && !isNil(album.event.id)) {
            key = album.event.id
          } else {
            key = album.id
          }
        } else {
          key = ''
        }

        if (!isNil(key) && !groupedLines[key]) {
          groupedLines[key] = []
        }
        !isNil(key) && groupedLines[key].push(line)
      })
    }
    return groupedLines
  }

  //TODO: recicle this by passing it to utils
  const renderCartLineGroups = () => {
    const cartLines = cartStore.getLines()
    const groupedLines = groupCartLines(cartLines)

    return Object.entries(groupedLines).map(([key, lines]) => {
      let title
      let icon
      let details

      if (lines.length > 0) {
        let album = cartStore.albums.find((album) => album.event?.id === key)

        if (!album) {
          album = cartStore.albums.find((album) => album.id === key)
        }

        if (album) {
          if (!isNil(album.event?.id)) {
            icon = (
              <FontAwesomeIcon
                className="mr-2 text-bg_section_titles"
                icon={faCalendar}
                size="2xl"
              />
            )
            title = `${album.event.name}`
            details = `${album.location.spotName} | ${album.activity.name}`
          } else {
            icon = (
              <FontAwesomeIcon className="mr-2 text-bg_section_titles" icon={faImages} size="2xl" />
            )
            title = `${t('Album from')} ${formatUtcToLocaleDate(album.takenDate!)}`
            details = `${album.location.spotName} | ${album.activity.name}`
          }
        }
      }

      const isExpanded = !expandedPanels[key]

      const handleToggleAccordion = () => {
        setExpandedPanels((prevState) => ({
          ...prevState,
          [key]: isExpanded,
        }))
      }

      const isFirstAccordion = key === Object.entries(groupedLines)[0][0]

      const linesGroupedByTagId: { [key: string]: CartLine[] } = {}

      lines.forEach((line) => {
        const { tagId } = line
        if (tagId) {
          if (!linesGroupedByTagId[tagId]) {
            linesGroupedByTagId[tagId] = [line]
          } else {
            linesGroupedByTagId[tagId].push(line)
          }
        }
      })

      return (
        <Accordion key={key} defaultActiveKey={key}>
          <Card>
            <CustomToggleFull
              eventKey={key}
              opened={isExpanded}
              onClick={handleToggleAccordion}
              angleToggle
              extraClassNames={`flex justify-between items-center py-2 px-3 bg-lumepic-light_black cursor-pointer md:pl-3 md:pr-6 ${
                !isFirstAccordion && !isExpanded && 'border-t border-white'
              }`}
              toggleIconClasses="text-white text-xl mt-1"
            >
              <div className="flex items-center px-3">
                {icon}
                <div className="py-2 px-4 flex flex-col">
                  <span className="text-white font-bold text-lg">{title}</span>
                  <div className="flex items-center">
                    <div className="w-3 h-3 rounded-full bg-primary mr-2 text-sm text-primary" />
                    <p className="text-bg_section_titles text-light">{details}</p>
                  </div>
                </div>
              </div>
            </CustomToggleFull>
            {isExpanded && (
              <Accordion.Collapse eventKey={key}>
                <Card.Body>
                  <>
                    {Object.entries(linesGroupedByTagId).map(([tagId, linesWithSameTagId]) => (
                      <PackageLine key={tagId} lines={linesWithSameTagId} />
                    ))}
                    {lines
                      .filter((line) => !line.tagId)
                      .map((line) => (
                        <CartLineComponent key={line.photograph.id} line={line} />
                      ))}
                  </>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
        </Accordion>
      )
    })
  }

  return (
    <div className="h-full min-h-screen md:max">
      <div className="w-full border-b-2 bg-bg_section_titles pt-7 pb-2 md:px-20 absolute z-10">
        <div className="mb-3 sm:mb-6">
          <h2 className="text-3xl pt-3 pl-3 sm:pl-0 font-extrabold md:text-4xl text-lumepic-light_black">
            {t('Cart')}
          </h2>
        </div>
      </div>
      <div className="md:py-5 bg-bg_details md:px-20 relative">
        <div className="flex flex-col gap-4 mb-3 lg:grid lg:grid-cols-8">
          <div className="lg:col-span-5 lg:mr-8 mt-24 md:mt-28">
            {cartStore.isEmpty() ? (
              <CartLineEmpty />
            ) : (
              <div className="md:shadow-md md:rounded-lg">
                {!isLoadingData && renderCartLineGroups()}
              </div>
            )}
          </div>
          <div className="md:col-span-3 relative z-20 md:mt-10">
            <div className="md:rounded-lg h-full">
              <CartSummary
                isLoadingDiscounts={cartStore.isLoadingDiscount}
                cart={cartStore.getCart()}
                cartDiscountStore={cartDiscountStore}
                onPurchase={handlePurchase}
                onSubmitCodeDiscount={handleDiscountSubmit}
                isLoggedIn={authStore.isAuthenticated()}
                discountError={cartStore.discountErrorMessage}
                groupCartLines={groupCartLines}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withErrorHandler(observer(CartView), axios)
