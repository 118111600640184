import IntervalAccordion from 'components/UI/IntervalAccordion/IntervalAccordion'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Photograph } from 'shared/models/Photograph'
import EventDetailsStore from '../EventDetailsStore'
import { PhotographUtils, UnknownPhotographKey } from 'shared/util/photographs.utils'
import { getDateDay } from 'shared/utility'
import { DropdownOption } from 'components/UI/Dropdown/CountryDropdown'
import { feedAlbumPhotographDetailById } from 'routing/Paths'
import Album from 'shared/models/Album'
import Tracker from 'shared/tracking'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import { isNil } from 'lodash'
import PhotographModal from 'components/PhotographDetailsModal'
import AccordionsByDay from './AccordionsByDay'
import { observer } from 'mobx-react'

type EventDetailsContentProps = {
  store: EventDetailsStore
  photographBlocksByTimeInterval: Map<string, Photograph[]>
  findAlbumForPhotograph: (photographId: string) => Album | undefined
  searchInProgress: boolean
}

const EventDetailsContent = ({
  store,
  photographBlocksByTimeInterval,
  findAlbumForPhotograph,
  searchInProgress,
}: EventDetailsContentProps) => {
  const {
    photographs,
    event,
    tagIds,
    isLoadingEventDetails,
    isLoadingEventPhotographs,
    isLoadingEventAlbums,
  } = store

  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }
  const [selectedInterval, setSelectedInterval] = useState<DropdownOption>(defaultInterval)
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)

  const photographsDays = photographs.map((photograph) => {
    const date = new Date(photograph.takenDate)
    return getDateDay(date)
  })

  const allPhotographsFromSameDay = photographsDays.every((photographDay) => photographDay)

  const handleDownloadFreePhotograph = (photographId: string) => {
    if (!event.isFree) {
      return
    }
    Tracker.downloadFreePhotograph(
      photographId,
      findAlbumForPhotograph(photographId)!.id,
      event.id,
      event.landingPath
    )
    store.downloadFreePhotograph(photographId).then((response) => {
      if (response) {
        const link = document.createElement('a')
        link.href = response.url
        link.click()
      }
    })
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const url = feedAlbumPhotographDetailById(
      findAlbumForPhotograph(photographId)!.id,
      photographId
    )
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const setPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = `/events/${event.landingPath}`
    const tagValueInUrl = tagIds.length > 0 ? `?tagValue=${tagIds}` : ''
    const urlWithTagIds = initialUrl + tagValueInUrl
    tagIds
      ? window.history.replaceState(null, '', urlWithTagIds)
      : window.history.replaceState(null, '', initialUrl)
    if (photographs.length === 1) {
      store.fetchEventPhotographs(true, '')
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(photographToDisplayId, photographs)
  }

  return (
    <div>
      {photographs.length > 0 && (
        <InfiniteScroll
          dataLength={photographs.length}
          next={() => store.fetchNextPage()}
          hasMore={store.hasMorePages()}
          loader={<></>}
          scrollThreshold={0.95}
          className="overflow-hidden"
        >
          {allPhotographsFromSameDay ? (
            Array.from(photographBlocksByTimeInterval.keys())
              .sort(PhotographUtils.sortIntervals)
              .map((key) => (
                <IntervalAccordion
                  key={key}
                  index={key}
                  unknownPhotographKey={UnknownPhotographKey}
                  setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                  downloadFreePhotograph={handleDownloadFreePhotograph}
                  photographBlockInterval={photographBlocksByTimeInterval.get(key)}
                  isInBurstMode={false}
                  eventDetailsStore={store}
                  allPhotographsFromSameDay={allPhotographsFromSameDay}
                />
              ))
          ) : (
            <AccordionsByDay
              store={store}
              allPhotographsFromSameDay={allPhotographsFromSameDay}
              selectedInterval={selectedInterval}
              setIntervalAccordionPhotographModalPhotograph={
                setIntervalAccordionPhotographModalPhotograph
              }
              handleDownloadFreePhotograph={handleDownloadFreePhotograph}
            />
          )}
        </InfiniteScroll>
      )}

      {!photographs.length &&
        !isLoadingEventDetails &&
        !isLoadingEventPhotographs &&
        !isLoadingEventAlbums && (
          <NoResultsFound emptyEvent={!searchInProgress} filteredSearchEmpty={searchInProgress} />
        )}

      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotograph}
          album={findAlbumForPhotograph(photographToDisplayId)!}
          photographs={photographs}
          loadingNextPage={isLoadingEventPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={handleDownloadFreePhotograph}
        />
      )}
    </div>
  )
}
export default observer(EventDetailsContent)
