import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faBolt,
  faCircleInfo,
  faDollar,
  faLocationDot,
  faMoneyCheckDollar,
  faPercent,
  faSackDollar,
  faTag,
} from '@fortawesome/free-solid-svg-icons'
import { Event } from 'shared/models/Event'
import { isNil } from 'lodash'
import { formatUtcToLocaleDate } from 'shared/utility'
import { QuantityDiscountDisplay } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/AddDiscountModal/AddDiscountModal'
import EventConditionsModal from './EventConditionsModal'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'

type EventInformationProps = {
  eventDetails: Event
  isNewAlbum: boolean
}

const EventInformation = ({ eventDetails, isNewAlbum }: EventInformationProps) => {
  const { t } = useTranslation()
  const [showEventConditionsModal, setShowEventConditionsModal] = useState<boolean>(false)

  return (
    <div
      className="flex flex-col gap-2 p-3 mt-4 rounded-md bg-bg_details border-lumepic-light_black"
      style={{ borderWidth: '1.7px' }}
    >
      <div className="flex items-center gap-1.5">
        <p className="text-lg font-medium text-lumepic-light_black">{t('Event conditions')}</p>
        <div className="cursor-pointer mt-0.5" onClick={() => setShowEventConditionsModal(true)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="text-base transition-colors text-lumepic-grey hover:text-lumepic-light_black"
          />
        </div>
      </div>
      {eventDetails.isFree ? (
        <p className="text-sm">{t('link_album_to_free_event')}</p>
      ) : (
        <>
          <p className="text-sm font-medium text-lumepic-light_black tracking-tight">
            {t('link_album_to_event_quality_conditions')}
          </p>
          <p className="text-sm">{t('link_album_to_event')}</p>
        </>
      )}
      {!eventDetails.isFree &&
        (eventDetails?.commission ||
          eventDetails?.defaultImagePrice ||
          eventDetails?.defaultPackagePrice ||
          eventDetails?.quantityDiscount) && (
          <>
            <p className="text-sm">{`${
              isNewAlbum ? t('link_album_to_event_2') : t('link_album_to_event_3')
            }`}</p>
            <ul>
              <div className="flex gap-2 w-full flex-col md:flex-row">
                {eventDetails?.currency && (
                  <li
                    className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
                    style={{ borderWidth: '1px' }}
                  >
                    <FontAwesomeIcon
                      icon={faDollar}
                      className="text-2xl text-lumepic-dark_grey mb-1.5"
                    />
                    <span className="text-xs text-lumepic-light_black opacity-90 text-center">{`${t(
                      'Currency'
                    )}`}</span>
                    <span className="font-extrabold">{`${eventDetails.currency.toUpperCase()}`}</span>
                  </li>
                )}
                {eventDetails?.defaultImagePrice && (
                  <li
                    className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
                    style={{ borderWidth: '1px' }}
                  >
                    <FontAwesomeIcon
                      icon={faMoneyCheckDollar}
                      className="text-2xl text-lumepic-dark_grey mb-1.5"
                    />

                    <span className="text-xs text-lumepic-light_black opacity-90 text-center">{`${t(
                      'Image price'
                    )}`}</span>
                    <span className="font-extrabold">{`${eventDetails.currency?.toUpperCase()} ${Number(
                      eventDetails.defaultImagePrice
                    ).toFixed(0)}`}</span>
                  </li>
                )}
                {eventDetails?.defaultPackagePrice && (
                  <li
                    className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
                    style={{ borderWidth: '1px' }}
                  >
                    <FontAwesomeIcon
                      icon={faSackDollar}
                      className="text-2xl text-lumepic-dark_grey mb-1.5"
                    />

                    <span className="text-xs text-center text-lumepic-light_black opacity-90">{`${t(
                      'Package price'
                    )}`}</span>
                    <span className="font-extrabold">{`${eventDetails.currency?.toUpperCase()} ${Number(
                      eventDetails.defaultPackagePrice
                    ).toFixed(0)}`}</span>
                  </li>
                )}
                {!isNil(eventDetails?.commission) && (
                  <li
                    className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
                    style={{ borderWidth: '1px' }}
                  >
                    <FontAwesomeIcon
                      icon={faPercent}
                      className="text-2xl text-lumepic-dark_grey mb-1.5"
                    />

                    <span className="text-xs text-center text-lumepic-light_black opacity-90">{`${t(
                      'Lumepic commission'
                    )}`}</span>
                    <span className="font-extrabold">{`${eventDetails?.commission}%`}</span>
                  </li>
                )}
              </div>
              {!isNil(eventDetails?.quantityDiscount) && (
                <div
                  className="w-full flex flex-col items-center gap-2 bg-white border-lumepic-medium_grey rounded-md mt-2 py-2 md:py-3"
                  style={{ borderWidth: '1px' }}
                >
                  <div className="flex flex-col md:flex-row items-center gap-2">
                    <FontAwesomeIcon icon={faTag} className="text-2xl text-lumepic-dark_grey" />
                    <span className="text-xs text-lumepic-light_black opacity-90">{`${t(
                      'Quantity discounts'
                    )}`}</span>
                  </div>
                  <div className="flex flex-col md:flex-row md:gap-8">
                    {eventDetails!.quantityDiscount.discountPercentages.map(
                      (discountRange, idx) => (
                        <div key={idx} className="flex gap-1 items-center">
                          <span className="font-medium">{`${discountRange.numberOfPictures} `}</span>
                          <span className="text-xs text-lumepic-light_black">{`${t(
                            'Photographs'
                          )}`}</span>
                          <FontAwesomeIcon icon={faArrowRight} className="text-sm" />
                          {eventDetails!.quantityDiscount?.display ===
                          QuantityDiscountDisplay.PERCENTAGE ? (
                            <span className="font-extrabold">
                              {discountRange.discountPercentage}%
                            </span>
                          ) : (
                            <span className="font-extrabold">{`${eventDetails.currency?.toUpperCase()} ${(
                              discountRange.numberOfPictures *
                              eventDetails.defaultImagePrice! *
                              (1 - discountRange.discountPercentage / 100)
                            ).toFixed(0)}`}</span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </ul>
          </>
        )}

      <p className="text-sm mt-2">{t('link_to_album_event_4')}</p>
      <ul>
        <div className="flex gap-2 w-full flex-col md:flex-row">
          <li
            className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
            style={{ borderWidth: '1px' }}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              className="text-2xl text-lumepic-dark_grey mb-1.5"
            />
            <span className="text-xs text-lumepic-light_black opacity-90 text-center">
              {t('Location')}
            </span>
            <span className="font-extrabold">{eventDetails.location?.spotName}</span>
          </li>
          <li
            className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
            style={{ borderWidth: '1px' }}
          >
            <FontAwesomeIcon icon={faCalendar} className="text-2xl text-lumepic-dark_grey mb-1.5" />
            <span className="text-xs text-lumepic-light_black opacity-90 text-center">
              {t('Date')}
            </span>
            <span className="font-extrabold">{formatUtcToLocaleDate(eventDetails!.date)}</span>
          </li>
          {!isNil(eventDetails.activity) && (
            <li
              className="p-1.5 flex flex-col items-center justify-between rounded-md bg-white border-lumepic-medium_grey w-full"
              style={{ borderWidth: '1px' }}
            >
              <FontAwesomeIcon icon={faBolt} className="text-2xl text-lumepic-dark_grey mb-1.5" />
              <span className="text-xs text-lumepic-light_black opacity-90 text-center">
                {t('Activity')}
              </span>
              <span className="font-extrabold">{eventDetails.activity?.name}</span>
            </li>
          )}
        </div>
      </ul>
      {showEventConditionsModal && (
        <EventConditionsModal
          handleClose={() => setShowEventConditionsModal(false)}
          event={eventDetails}
        />
      )}
    </div>
  )
}

export default observer(EventInformation)
