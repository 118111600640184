import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import classes from '../../MiniCart.module.scss'
import Cart, { CartLine } from 'shared/models/Cart'
import { isNil } from 'lodash'

type MiniCartLineProps = {
  cart: Cart
  line: CartLine
}

const MiniCartLine = (props: MiniCartLineProps) => {
  const parseAlbumTakenDate = (takenDate: Date) => {
    return moment.utc(takenDate).format('DD/MM/YYYY')
  }

  const discountEarned = props.cart.getQuantityDiscountPercentageEarned(
    props.cart.getPhotographCount(props.line.photograph.albumId, props.line.album?.event?.id),
    props.line.album
  )

  const linePrice = props.line.photograph.price
  const linePriceAfterDiscounts = linePrice - linePrice * (discountEarned / 100)

  return (
    <div className={classes.CartLine}>
      <div className={classes.ImageContainer}>
        <img
          loading="lazy"
          className={classes.Image}
          src={props.line.photograph.thumbnailUrl}
          alt={props.line.album?.location.spotName}
        />
      </div>
      <div className={classes.LineInfo}>
        <p>
          {props.line?.currency?.toUpperCase() || 'UYU'} {linePriceAfterDiscounts.toFixed(2)}
        </p>
        <small>
          {!isNil(props.line.album?.location.spotName)
            ? `${props.line.album?.location.spotName} - `
            : undefined}
          {parseAlbumTakenDate(props.line.album?.takenDate || props.line.photograph.takenDate)}
        </small>
      </div>
    </div>
  )
}

export default observer(MiniCartLine)
