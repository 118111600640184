import { BaseDiscount } from './BaseDiscount'
import {
  CodeDiscountApplication,
  CodeDiscountUnit,
  CodeDiscountUsability,
  NewCodeDiscountInterface,
  PurchaseCodeDiscountValidity,
} from '../../services/Interfaces/Discount/CodeDiscount.interface'
import { User } from './User'
import Album from './Album'
import { Activity } from './Activity'
import { Event } from './Event'
import moment from 'moment'

export class NewCodeDiscount extends BaseDiscount {
  code: string

  amount: number

  unit: CodeDiscountUnit

  usability: CodeDiscountUsability

  description: string

  ownerId: string

  validity: PurchaseCodeDiscountValidity

  application: CodeDiscountApplication

  albumId?: string

  buyerId?: string

  activityId?: string

  eventId?: string

  validFrom?: Date

  album?: Album

  buyer?: User

  activity?: Activity

  event?: Event

  static init(data: NewCodeDiscountInterface): NewCodeDiscount {
    const discount = new NewCodeDiscount()
    discount.id = data.id
    discount.code = data.code
    discount.isSuspended = data.isSuspended
    discount.amount = data.amount
    discount.unit = data.unit
    discount.usability = data.usability
    discount.description = data.description
    discount.ownerId = data.ownerId
    discount.validity = data.validity
    discount.application = data.application
    discount.albumId = data.albumId
    discount.buyerId = data.buyerId
    discount.activityId = data.activityId
    discount.eventId = data.eventId
    discount.expirationDate = data.expirationDate
      ? moment.utc(data.expirationDate).toDate()
      : undefined
    discount.validFrom = data.validFrom ? moment.utc(data.validFrom).toDate() : undefined
    discount.activity = Activity.init(data.activity)
    discount.album = Album.init(data.album)
    discount.buyer = User.init(data.buyer)
    discount.event = Event.init(data.event)

    return discount
  }
}
