import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import PayButton from './components/PayButton/PayButton'
import CartDiscountStore from '../../CartDiscountStore'
import Cart, { CartLine } from '../../../../shared/models/Cart'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import DiscountsList from './components/DiscountsList'
import CodeDiscountAccordion from './components/CodeDiscountAccordion'
import { formatUtcToLocaleDate, getFeatureFlagValue } from 'shared/utility'
import { faCalendar, faImages } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import Checkbox from 'components/UI/Checkbox'
import { FeatureFlags } from 'config/constants/featureFlags'

type CartSummaryProps = {
  cart: Cart
  isLoggedIn: boolean
  onPurchase: (cart: Cart) => void
  onSubmitCodeDiscount: (discountCode: string) => Promise<void>
  discountError: any
  cartDiscountStore: CartDiscountStore
  isLoadingDiscounts: boolean
  groupCartLines: (lines: CartLine[]) => {
    [key: string]: CartLine[]
  }
}

const ARG_TAXES_CHARGE = 0.6

const CartSummary = ({
  cart,
  isLoggedIn,
  onPurchase,
  onSubmitCodeDiscount,
  discountError,
  cartDiscountStore,
  isLoadingDiscounts,
  groupCartLines,
}: CartSummaryProps) => {
  const { authStore, cartStore, locationStore, featureFlagsStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )
  const { printedPhotographs } = cartStore

  const userLocationIsUY = locationStore.userLocation.country.code === 'UY'
  const printedPhotographsEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PRINTED_PHOTOGRAPHS,
    false
  )

  const currency = cart.currency?.toUpperCase() || 'UYU'
  const userLocationIsAR: boolean = locationStore.userLocation.country.code === 'AR'

  //TODO: recicle this by passing it to utils
  const renderGroupedLines = () => {
    let totalSum = 0
    let totalSumWithArgTaxes = 0
    const groupedLines = groupCartLines(cart.lines)
    const renderedLines = Object.entries(groupedLines).map(([key, lines]) => {
      if (lines.length === 0) return null
      let title
      let icon
      let details

      const album = cartStore.albums.find((album) => album.id === key)

      // If the group is an event group, find the event from the first album found in the group
      const event = cartStore.albums.find((album) => album.event?.id === key)?.event

      const isEventGroup = !isNil(event?.id)
      if (isEventGroup) {
        // Since all albums will have the same location, get the location from the first album found in the group
        const location = cartStore.albums.find((album) => album.event?.id === key)?.location
        // Since all albums will have the same activity, get the activity from the first album found in the group
        const activity = cartStore.albums.find((album) => album.event?.id === key)?.activity
        icon = (
          <FontAwesomeIcon className="mr-2 text-bg_section_titles" icon={faCalendar} size="2xl" />
        )
        title = `${event?.name}`
        details = `${location?.spotName} | ${activity?.name}`
      } else {
        icon = (
          <FontAwesomeIcon className="mr-2 text-bg_section_titles" icon={faImages} size="2xl" />
        )
        title = `${t('Album from')} ${
          !isNil(album?.takenDate) ? formatUtcToLocaleDate(album!.takenDate!) : ''
        }`
        details = `${album?.location.spotName} | ${album?.activity.name}`
      }

      const photographs = lines.flatMap((line) => line.photograph)

      const priceBeforeDiscounts = photographs.length * photographs[0].price

      const priceAfterDiscounts = cart.getPriceAfterDiscountsByGroupLine(lines)

      totalSum += priceAfterDiscounts

      totalSumWithArgTaxes += totalSum * (1 + ARG_TAXES_CHARGE)

      return (
        <div key={key} className="mb-3 border-black items-center bg-lumepic-light_grey md:m-3">
          <div className="flex items-center bg-lumepic-medium_grey px-6 md:bg-lumepic-light_black">
            {icon}
            <div className="py-2 pl-4 flex flex-col">
              <span className="text-lumepic-black font-bold text-base md:text-white">{title}</span>
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-primary mr-2 text-sm text-primary" />
                <p className="text-lumepic-light_black text-light md:text-bg_section_titles">
                  {details}
                </p>
              </div>
            </div>
          </div>

          <div className="flex py-2 md:border md:border-lumepic-medium_grey">
            <div className="w-2/3 flex flex-col gap-2">
              <div className="md:py-2">
                <div className="flex items-center gap-5 mb-3 px-3">
                  <p className="text-lumepic-light_black font-medium">{t('Photographs')}</p>
                  <p className="font-light text-sm justify-end text-lumepic-grey">
                    {photographs.length} x {`${currency} ${photographs[0].price.toFixed(2)}`}
                  </p>
                </div>

                <div className="px-3 md:pl-3 md:pr-1">
                  <DiscountsList
                    isLoadingDiscounts={isLoadingDiscounts}
                    cart={cart}
                    lines={lines}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/3 items-end justify-center border-l pr-2">
              {priceBeforeDiscounts > priceAfterDiscounts && (
                <p className="font-light text-sm justify-end text-lumepic-grey line-through">{`${currency} ${priceBeforeDiscounts.toFixed(
                  2
                )}`}</p>
              )}
              <p className="font-extrabold text-black text-base">{`${currency} ${priceAfterDiscounts.toFixed(
                2
              )}`}</p>
            </div>
          </div>
        </div>
      )
    })
    return { renderedLines, totalSum, totalSumWithArgTaxes }
  }

  const { renderedLines, totalSum, totalSumWithArgTaxes } = renderGroupedLines()

  return (
    <div className="md:col-span-2 w-full md:rounded-lg md:shadow-md bg-white md:px-2 md:py-4">
      <div className="bg-lumepic-light_black md:bg-white md:rounded-lg md:px-4 ">
        <h3 className="text-2xl pl-2 pt-1 pb-1 sm:pl-0 font-semibold md:font-bold text-white md:text-3xl md:text-lumepic-light_black mb-0">
          {t('Summary')}
        </h3>
      </div>
      {renderedLines}
      <div className="h-1 mt-6 mb-4 border-t border-lumepic-dark_grey md:border-lumepic-medium_grey md:mx-3" />
      <div className="flex flex-row items-center justify-between mb-4">
        <p className="mb-0 font-extrabold tracking-wide text-2xl px-3">{t('Total')}</p>
        <p className="mb-0 font-extrabold tracking-wide text-2xl px-3">{`${currency} ${totalSum.toFixed(
          2
        )}`}</p>
      </div>
      {userLocationIsAR && (
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between mb-4">
            <p className="mb-0 font-extrabold tracking-wide text-lg md:text-xl px-3">
              {t('Taxes')}
            </p>
            <p className="mb-0 font-extrabold tracking-wide text-lg md:text-xl px-3">{`${currency} ${(
              totalSum * ARG_TAXES_CHARGE
            ).toFixed(2)}`}</p>
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <p className="mb-0 font-extrabold tracking-wide text-lg md:text-xl px-3">
              {t('Total (including taxes)')}
            </p>
            <p className="mb-0 font-extrabold tracking-wide text-lg md:text-xl px-3">{`${currency} ${totalSumWithArgTaxes.toFixed(
              2
            )}`}</p>
          </div>
          <span className="flex flex-col m-2 px-3 bg-lumepic-light_grey py-2 border rounded-lg">
            <p className="text-xs">
              <p className="text-sm">
                {t(
                  'Lumepic will only charge the Total without taxes at this time. The following taxes may be withheld by your Bank because it is a purchase abroad'
                )}
                :
              </p>
              <li>{t('Impuesto PAIS: 30%')}</li>
              <li>{t('Percepción del impuesto a las Ganancias/Bienes Personales: 30%')}</li>
            </p>
          </span>
        </div>
      )}
      <div className="mx-3">
        <CodeDiscountAccordion
          isLoadingDiscount={cartStore.isLoadingDiscount}
          cart={cart}
          onSubmitDiscount={onSubmitCodeDiscount}
          discountError={discountError}
          cartDiscountStore={cartDiscountStore}
          isLoggedIn={isLoggedIn}
        />
        {printedPhotographsEnabled && userLocationIsUY && (
          <div className="flex flex-col items-start mb-3.5 border rounded">
            <div className="ml-2.5">
              <Checkbox
                id="burst-mode"
                label={t('I want my photos printed')}
                fontColor="lumepic-light_black"
                value={printedPhotographs}
                onChange={cartStore.handleChangePrintedPhotographs}
              />
            </div>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden w-full ${
                printedPhotographs ? 'max-h-40' : 'max-h-0'
              }`}
            >
              {printedPhotographs && (
                <p className="bg-bg_details p-2 text-[0.8rem] w-full">
                  {t(
                    'By selecting this option you will not be charged any additional fees nor will the photos be printed. We will contact you to offer you a tailored quote.'
                  )}
                </p>
              )}
            </div>
          </div>
        )}
        <PayButton
          cart={cart}
          onPurchase={onPurchase}
          isLoggedIn={isLoggedIn}
          isLoading={cartStore.isLoading}
          loginRegisterStore={loginRegisterStore}
        />
      </div>
    </div>
  )
}

export default observer(CartSummary)
