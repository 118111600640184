import React, { useState } from 'react'
import Button from 'components/UI/Button'
import PrivateAlbumBlurredImg from './PrivateAlbumBlurredImg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import PrivateAlbumPasswordModalForm from './PrivateAlbumPasswordModalForm/PrivateAlbumPasswordModalForm'

type PrivateAlbumLoginProps = {
  store: AlbumDetailsStore
  initialRender: React.MutableRefObject<boolean>
  albumId: string
  photographId?: string
}

const PrivateAlbumLogin = ({
  initialRender,
  photographId,
  store,
  albumId,
}: PrivateAlbumLoginProps) => {
  const { t } = useTranslation()
  const [displayPasswordModal, setDisplayPasswordModal] = useState<boolean>(false)

  return (
    <>
      <section className="mt-8 relative flex justify-center">
        <div
          className="absolute z-20 rounded-lg flex flex-col items-center justify-center mt-44"
          style={{ boxShadow: '0px 0px 70px 30px rgba(0,0,0,0.8)' }}
        >
          <Button btnType="PrimaryAction" onClick={() => setDisplayPasswordModal(true)}>
            <div className="flex items-center gap-2 px-2 py-1">
              <FontAwesomeIcon icon={faImages} className="text-xl" />
              <span className="text-lg font-medium">{t('See photographs')}</span>
            </div>
          </Button>
        </div>
        <PrivateAlbumBlurredImg />
      </section>
      <PrivateAlbumPasswordModalForm
        displayPasswordModal={displayPasswordModal}
        closePasswordModal={() => setDisplayPasswordModal(false)}
        initialRender={initialRender}
        photographId={photographId}
        albumId={albumId}
        store={store}
      />
    </>
  )
}

export default PrivateAlbumLogin
