import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { Event } from '../shared/models/Event'
import { isNil } from 'lodash'
import EventsInterface from './Interfaces/Event/Events.interface'
import { Activity } from 'shared/models/Activity'
import { Photograph } from 'shared/models/Photograph'
import { Paginated } from './Interfaces/Paginated'
import PhotographInterface from './Interfaces/Photograph/Photograph.interface'
import EventInterface from './Interfaces/Event/Event.interface'
import Tracker from 'shared/tracking'
import { TagKeys } from './Interfaces/Tag/Tag.interface'

type FetchEventsOptions = {
  countryCode?: string
  dateFrom?: Date | null
  dateTo?: Date | null
  locationId?: string
  activityId?: string
  userId?: string
  eventId?: string
  limit: number
  offset: number
  order: EventsOrderQueryParams
}

type FetchEventPhotographsOptions = {
  filters?: { tagIds?: string[]; tagValue?: string; recognitionImageUrl?: string }
  pagination: {
    limit: number
    skip: number
  }
  userId?: string
}

export type EventsOrderQueryParams = {
  field: EventsOrderFields
  sort: EventsOrderSorts
}

export enum EventsOrderFields {
  CREATED_AT = 'CREATED_AT',
  DATE = 'DATE',
}

export enum EventsOrderSorts {
  ASC = 'ASC',
  DESC = 'DESC',
}

type EventsResponse = {
  events: Event[]
  count: number
}

type EventsQueryParams = {
  order?: EventsOrderQueryParams[]
  pagination: {
    limit: Number
    skip: Number
  }
  filters: {
    countryCode?: string
    locationId?: string
    activityId?: string
    userId?: string
    eventId?: string
    dateFrom?: Date | null
    dateTo?: Date | null
    ownerId?: string
    disablePagination?: boolean
  }
}

export class EventService {
  fetchEvents(options: FetchEventsOptions): Promise<EventsResponse> {
    const { countryCode, limit, offset, order, locationId, activityId, userId, dateFrom, dateTo } =
      options
    const queryParams: EventsQueryParams = {
      order: [
        {
          field: order.field || EventsOrderFields.DATE,
          sort: order.sort || EventsOrderSorts.DESC,
        },
      ],
      pagination: {
        limit: Number(limit),
        skip: Number(offset),
      },
      filters: {},
    }
    if (!isNil(countryCode)) {
      queryParams.filters.countryCode = countryCode
    }
    if (!isNil(locationId)) {
      queryParams.filters.locationId = locationId
    }
    if (!isNil(activityId)) {
      queryParams.filters.activityId = activityId
    }
    if (!isNil(userId)) {
      queryParams.filters.userId = userId
    }
    if (!isNil(dateFrom)) {
      queryParams.filters.dateFrom = dateFrom
    }
    if (!isNil(dateTo)) {
      queryParams.filters.dateTo = dateTo
    }
    return axios
      .get(paths.GET_EVENTS, { params: queryParams })
      .then((res: AxiosResponse<EventsInterface>) => {
        const { count, events } = res.data
        const eventModels = events.map((e) => Event.init(e))
        return {
          count,
          events: eventModels,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventsByCountryCode(countryCode: string, userId?: string): Promise<Event[]> {
    return axios
      .get(paths.getEventsByCountryCode(countryCode), {
        params: { userId },
      })
      .then((response: AxiosResponse) => {
        const events = response.data.map((event: EventInterface) => Event.init(event))
        return events
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventDetails(eventId: string): Promise<Event> {
    return axios
      .get(paths.getEventDetails(eventId))
      .then((response: AxiosResponse) => {
        return Event.init(response.data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventPhotographs(
    eventId: string,
    limit: Number,
    skip: Number,
    tagValue?: string,
    tagKey?: TagKeys,
    recognitionImageUrl?: string,
    userId?: string
  ): Promise<Paginated<Photograph>> {
    const options: FetchEventPhotographsOptions = {
      pagination: {
        limit: Number(limit),
        skip: Number(skip),
      },
      userId: userId,
    }
    if (!isNil(tagValue) && tagValue !== '') {
      options.filters = {
        tagValue: tagValue,
      }
      Tracker.filterByTag(tagKey, userId, undefined, eventId)
    }

    if (!isNil(recognitionImageUrl)) {
      options.filters = {
        ...options.filters,
        recognitionImageUrl: recognitionImageUrl,
      }
      Tracker.filterByFaceRecognition(userId, undefined, eventId)
    }
    return axios
      .get(paths.getEventPhotographs(eventId), { params: options })
      .then((res: AxiosResponse<Paginated<PhotographInterface>>) => {
        const result = res.data
        const models = result.items.map((p) => Photograph.init(p))
        return {
          items: models,
          count: result.count,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventActivities(eventId: string): Promise<Activity[]> {
    return axios
      .get(paths.getEventActivities(eventId))
      .then((response: AxiosResponse) => {
        return response.data.map((activity) => Activity.init(activity))
      })
      .catch(parseAxiosErrorResponse)
  }

  saveEvent(token: string, eventData: Event) {
    return axios
      .post(paths.GET_EVENTS, eventData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editEvent(token: string, eventData: Event) {
    return axios
      .put(paths.updateEventById(eventData.id), eventData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteEvent(eventId: string, token: string) {
    return axios
      .delete(paths.deleteEventById(eventId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  id: string
  description: string
  date: string
  name: string
  activity: string
}
